<template>
  <BaseDrawer
    ref="baseDrawer"
    :title="`${getJurisdictionSelected.type}-wide impact estimates`"
    class="app-drawer-chart-estimates psui-relative psui-bg-gray-10 w-auto"
    :hierarchy="DRAWER_HIERARCHY.PRIMARY"
    :enable-full-screen="true"
    :drawer-width="getDrawerWidth"
    @close="$emit('close')"
  >
    <template
      v-if="!isUpdating"
      #actions
    >      
      <div class="psui-flex psui-px-10 psui-items-center psui-mb-6">
        <AppBreadcrumb
          v-if="getBreadcrumbs"
          :items="getBreadcrumbs"
          :truncate-items="true"
        />

        <div class="psui-flex psui-justify-end psui-items-center psui-ml-auto">
          <div 
            class="psui-flex items-center psui-cursor-pointer justify-end psui-text-blue-60 hover:opacity-80"
            @click="openAssumptionsDrawer"
          >
            <PsIcon
              icon="tune"
              size="18"
            />
            <span class="psui-ml-1 psui-font-bold psui-text-small">Edit Assumptions</span>
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="getStudyDataSelected && projectionAssumptions"
      class="psui-flex psui-flex-col psui-rounded-md psui-shadow-elevation-10 psui-bg-white psui-mt-1 psui-p-6"
    >
      <CardProjection
        v-if="!editAssumptions"
        ref="cardProjection"
        :study_data="getStudyDataSelected"
        :study_data_instance="fuel != null ? getStudyDataSelectedNewBuildings : getStudyDataSelected.data.projectionsByYear"
        :existing_projections_by_year="getStudyDataSelected.data.projectionsByYear"
        :assumption="projectionAssumptions"
        :column-selected-key.sync="columnSelectedKey"
        :first-selected-column-key="firstSelectedColumnKey"
        :is-projection-drawer="true"
        :is-on-fullscreen="isOnFullScreen"
        :show-title="true"
      >
        <template #headerPrepend>
          <div
            v-if="getStudyDataSelected && projectionAssumptions"
            class="psui-w-2/5 psui-px-2 psui-mb-6"
          >
            <p class="psui-text-p psui-font-bold psui-text-gray-80 psui-mb-2">
              Measures & Packages
            </p>

            <PsDropdown
              ref="measureDropdown"
              class="measure-dropdown psui-w-full"
            >
              <template #dropdownTrigger>
                <div class="psui-text-gray-60 psui-cursor-pointer psui-border psui-border-gray-30 psui-rounded-md psui-px-4 psui-py-2 hover:psui-border-blue-50 pui-transition psui-duration-300 psui-ease-in-out">
                  <div class="psui-flex psui-items-center psui-mt-px psui-mb-px opacity-100-all-childrens-on-hover">
                    <p
                      class="measure-dropdown-title psui-text-p psui-font-bold"
                      :class="{'psui-truncate' : !isOnFullScreen}"
                      :title="getStudyDataSelected.measure.title"
                    >
                      {{ getStudyDataSelected.measure.title }}
                    </p>
                    <PsIcon
                      icon="expand_more"
                      size="24"
                      display="flex"
                      class="dropdown-expand psui-text-gray-50 psui-transition psui-transform psui-ml-auto"
                    />
                  </div>
                </div>
              </template>
              <template #items>
                <ul class="psui-el-dropdown-menu-list psui-max-h-64">
                  <li
                    v-for="(option, index) in studyDataRows"
                    :key="index"
                    class="psui-el-dropdown-menu-list-item"
                    :class="{ 'is-selected psui-bg-blue-10 psui-text-blue-60 hover:psui-bg-blue-10 hover:psui-text-blue-60' : option.measure.id == measure.id }"
                    @click="onSelectMeasure(option.measure)"
                  >
                    <div class="psui-el-dropdown-menu-list-item-left-label">
                      {{ option.measure.title }}
                    </div>
                  </li>
                </ul>
              </template>
            </PsDropdown>
          </div>
        </template>
      </CardProjection>
    </div>
  </BaseDrawer>
</template>

<script>
import CardProjection from '@/components/projections/CardProjection.vue'
import { find } from '@/util/Functions'
import { mapState } from 'vuex'
import newBuildingsImpactEstimatesByYear from '@/business-logic/services/impact-algorithms/results/new-buildings/newBuildingsImpactEstimatesByYear'
import AppBreadcrumb from '@/components/general/AppBreadcrumb.vue'
import { DRAWER_HIERARCHY, STUDY_TYPES_DB_SLUGS, BUILDING_TYPE_DB_SLUGS, ASSUMPTIONS_PATHS } from '@/util/Enums.js'
import { getBuildingTypeSlugByStudyId, getStudyTypeSlugByPrototypeId } from '@/util/Functions.js'

export default {
  name: 'StudyResultsProjectionsDrawer',
  components: { CardProjection, AppBreadcrumb },
  data() {
    return {
      studyDataRows: [],
      prototype: null,
      vintage: null,
      measure: null,
      assumption: null,
      building_stock_units: 0,
      building_estimates: [],
      dataInitialized: false,
      columnSelectedKey: null,
      firstSelectedColumnKey: null,
      editAssumptions: false,
      projectionsByYear: [],
      isUpdating: false,
      projectionAssumptions: null,
      DRAWER_HIERARCHY,
      isOnFullScreen: false,
    }
  },
  computed: {
    getDrawerWidth(){
      let width = window.innerWidth
      const { visibility } = this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
      if(visibility) width -= 340
      return `${width}px`
    },
    ...mapState('assumptions', ['existingBuildingsResultsImpactAssumptions', 'newBuildingsResultsImpactAssumptions']),
    getJurisdictionSelected() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    getStudyDataRows() {
      return this.studyDataRows.map((studyDataRow) => {
        studyDataRow.assumption = this.projectionAssumptions
        studyDataRow.building_stock_units = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.buildingStockFilters)
        studyDataRow.building_estimates = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimates'](this.buildingEstimatesFilters)
        studyDataRow.generateStudyResultProjections()
        return studyDataRow
      })
    },
    buildingStockFilters() {
      const jurisdiction_id = this.getJurisdictionSelected.id
      const climate_zone_prefix = this.$route?.params?.climate_zone_raw?.split('-')?.[0] ?? null
      const type_prototype_id = this.prototype.type_prototype_id
      const type_vintage_id = this.vintage?.type_vintage_id || null
      return { jurisdiction_id, climate_zone_prefix, type_prototype_id, type_vintage_id, prototype_id: this.prototype.id }
    },
    buildingEstimatesFilters() {
      return {
        jurisdiction_id: this.lastJurisdictionVisited?.id ?? null,
        climate_zone_prefix: this.$route?.params?.climate_zone_raw?.split('-')?.[0] ?? null,
        type_prototype_id: this.prototype.type_prototype_id,
        prototype_id: this.prototype.id,
      }
    },
    getStudyDataSelected() {
      if(!this.studyDataRows || !this.measure || this.isUpdating) return false
      const studyDataRow = this.studyDataRows.findInArray({ measure_id: this.measure.id })
      studyDataRow.assumption = this.projectionAssumptions
      studyDataRow.building_stock_units = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.buildingStockFilters)
      studyDataRow.building_estimates = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimates'](this.buildingEstimatesFilters)
      studyDataRow.generateStudyResultProjections()
      return studyDataRow
    },
    getStudyDataSelectedNewBuildings() {
      return newBuildingsImpactEstimatesByYear(this.getStudyDataSelected?.building_estimates, this.getStudyDataSelected?.fuel, {
        all_electric_shares_without_policy: this.projectionAssumptions.all_electric_shares_without_policy,
        all_electric_shares_with_policy: this.projectionAssumptions.all_electric_shares_with_policy,
        effective_date: this.projectionAssumptions.start_at,
        termination_date: this.projectionAssumptions.terminationDate,
        installation_effects_years: this.projectionAssumptions.years,
        applicability_rate: this.projectionAssumptions.applicability_rate,
        annual_penetration_rate: this.projectionAssumptions.annual_penetration_rate,
        delay_installation: this.projectionAssumptions.delay_installation,
        likelihood_installations_first_third: this.projectionAssumptions.likelihood_installations_first_third,
        likelihood_installations_second_third: this.projectionAssumptions.likelihood_installations_second_third,
        likelihood_installations_final_third: this.projectionAssumptions.likelihood_installations_final_third,
        care_program_enrollment_rate: this.projectionAssumptions?.care_program_enrollment_rate || 12,
      },
      {
        currentThermsEmissionFactor: this.projectionAssumptions.current_therms_emissions_factor,
        currentKwhEmissionFactor: this.projectionAssumptions.current_kwh_emissions_factor,
        kwhSavings: this.getStudyDataSelected?.data.kwh_savings,
        thermsSavings: this.getStudyDataSelected?.data.therms_savings,
        annualBillSavings: this.getStudyDataSelected?.data.annual_bill_savings,
        initialCost: this.getStudyDataSelected?.data.initial_cost,
        gridMaxRenewablesYear: this.projectionAssumptions.grid_max_renewables_year,
        gridMaxRenewablesLevel: this.projectionAssumptions.grid_max_renewables_level,
        currentGridRenewableLevel: this.projectionAssumptions.current_grid_renewable_level,
      })
    },
    getColumnSelected() {
      return find(this.columns, { key: this.columnSelectedKey})
    },
    getBreadcrumbs() {
      const study = this.prototype.study
      const climateZonePrefix = this.studyDataRows[0].climate_zone_raw.split('-')[0]
      const mTitle = this.studyDataRows[0].fuel_id ? this.fuel.title : this.vintage.title

      return [
        {title: study.title},
        {title: this.prototype.title},
        {title: mTitle},
        {title: `Climate Zone ${climateZonePrefix}`},
        {title: this.changeProjectionsUnitsCopy}
      ]
    },
    changeProjectionsUnitsCopy() {
      const projectionsUnits = Number(this.studyDataRows[0].projections_units).toLocaleString()
      const prototypeId = this.prototype.id
      const studyTypeSlug = getStudyTypeSlugByPrototypeId(prototypeId)
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototypeId })
      const studyId = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id }).id
      const buildingTypeSlug = getBuildingTypeSlugByStudyId(studyId)
      if(studyTypeSlug == STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS && buildingTypeSlug == BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL){
        return `${projectionsUnits} ft2`
      } else {
        return `${projectionsUnits} Units`
      }
    },
  },
  watch: {
    '$route.fullPath'() {
      this.$refs.baseDrawer.closeDrawer()
    }
  },
  mounted() {
    this.$eventBus.$on('openProjectionsModal', ({ studyData, columnSelectedKey, prototype, vintage, fuel, measure, building_stock_units, building_estimates }) => {
      this.isUpdating = true
      this.columnSelectedKey = columnSelectedKey
      this.firstSelectedColumnKey = columnSelectedKey
      this.measure = measure
      this.studyDataRows = studyData
      this.prototype = prototype
      this.vintage = vintage
      this.fuel = fuel
      this.building_estimates = building_estimates
      this.building_stock_units = building_stock_units
      this.projectionAssumptions = (this.fuel != null) ? this.newBuildingsResultsImpactAssumptions : this.existingBuildingsResultsImpactAssumptions
      this.$refs.baseDrawer.openDrawer()
      this.isUpdating = false
      this.$forceUpdate()
    })
    this.$eventBus.$on('toggleEditRequirementsDrawerFullScreen', this.toggleFullScreen)
  },
  beforeDestroy() {
    this.$eventBus.$off('openProjectionsModal')
    this.$eventBus.$off('toggleEditRequirementsDrawerFullScreen')
  },
  methods: {
    toggleFullScreen() {
      const contentContainer = document.getElementById('content-container')
      const sidebarContainer = document.getElementById('sidebar-container')

      if (this.isOnFullScreen) {
        this.isOnFullScreen = false
        sidebarContainer.classList.add('z-50')
        sidebarContainer.classList.remove('z-10')

        contentContainer.classList.add('z-10')
        contentContainer.classList.remove('z-30')
      } else {
        this.isOnFullScreen = true
        sidebarContainer.classList.add('z-10')
        sidebarContainer.classList.remove('z-50')

        contentContainer.classList.add('z-30')
        contentContainer.classList.remove('z-10')
      }
    },
    onSelectMeasure(measure) {
      this.measure = measure
      this.$refs.measureDropdown.close()
    },
    openAssumptionsDrawer() {
      
      const studyType = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ id: this.prototype.study.study_group.study_type_id })
      const drawerOptions = {
        ...this.$store.getters['assumptions/getterAssumptionsDrawerOptions'],
        path: ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS,
        tab: studyType
      }
      
      this.$store.commit('assumptions/setDrawerOptions', drawerOptions)
      this.$store.commit('assumptions/openDrawerAtSelectedAssumptionsPath', ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS)
    },
  }
}
</script>

<style lang="scss" scoped>
  .app-drawer-chart-estimates {
    background-color: #F3F6F9 !important;

    // .psui-text-accentSmall {
    //   letter-spacing: 0.6px;
    // }

    // .app-drawer-content {
    //   height: 100%;
    // }
  }

  .measure-dropdown {
    &-title {
      &.psui-truncate {
        max-width: 13rem;
      }
    }
  }
  ::v-deep {
    .psui-el-dropdown-menu-list {
      max-height: 19rem;
    }
  }
</style>
