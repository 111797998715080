<template>
  <div class="card psui-flex psui-flex-col psui-shadow-elevation-5 psui-bg-white psui-rounded-lg psui-w-full xl:psui-w-1/3">
    <div class="card-header psui-px-5 2xl:psui-px-8 psui-py-6 psui-space-y-4 sm:psui-space-y-0 xl:psui-space-y-4 psui-flex psui-items-start">
      <img 
        src="images/building_estimates/building-estimates.png" 
        alt="Not sure where to start?"
        class="flex-shrink-0"
      >

      <div class="content psui-flex-grow xl:psui-flex-grow-0 psui-space-y-4">
        <h2 class="psui-text-h4 psui-text-blue-80 psui-font-bold psui-mb-4 xl:psui-mb-0">
          Prepare to adopt a reach code for the 2025 code cycle
        </h2>

        <p class="psui-text-p psui-text-gray-60">
          The new Code Cycle takes effect January 1, 2026. Studies on potential amendments to the Code for new buildings are expected mid-2025. 
        </p>
      </div>
    </div>

    <div class="card-footer psui-flex psui-flex-row psui-border-t psui-border-gray-20 psui-px-5 2xl:psui-px-8 psui-py-4 psui-mt-auto">
      <a 
        :href="supportButton.url"
        target="_blank"
      >
        <PsButton
          :label="supportButton.title"
          layout="ghost"
          size="medium"
        />
      </a>
      <a
        :href="reviewButton.url"
        target="_blank"
      >
        <PsButton
          :label="reviewButton.title"
          layout="onlytext"
          size="medium"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeMainBenefitsSupport',
  data: () => ({
    supportButton: { 
      title: 'How to get ready',
      url: 'https://intercom.help/explorer-local-energy-codes/en/articles/5789919-considering-a-new-construction-reach-code-for-your-city-or-county-in-california'
    },
    reviewButton: {
      title: 'Overview of 2025 Code',
      url: 'https://intercom.help/explorer-local-energy-codes/en/articles/10011645-what-to-expect-the-2025-code-cycle'
    }
  })
}
</script>

<style lang="scss" scoped>
  .card {
    &-header {
      flex-direction: column;

      @media (min-width: 640px) {
        flex-direction: row;

        .content {
          margin-left: 1.5rem;
        }
      }
      
      @media (min-width: 1200px) {
        flex-direction: column;
        .content {
          margin-left: 0;
        }
      }
      
      img {
        max-height: 90.45px;
      }
    }

    &-footer {
      flex-direction: column;
      align-items: flex-start;

      button,
      a {
        &:not(:last-child) {
          margin-bottom: 0.5rem;

          @media (min-width: 432px) {
            margin-bottom: 0;
            margin-right: 0.5rem;
          }
        }
      }

      @media (min-width: 432px) {
        align-items: center;
        flex-direction: row;
      }
    }
  }
</style>