<template>
  <StudyResultsContentCard
    v-if="lastJurisdictionVisited && lastClimateZoneVisited"
    :expanded.sync="expanded"
    :prototype="prototype"
    :vintage="vintage"
    :fuel="false"
    :climate_zone="lastClimateZoneVisited"
    :jurisdiction="lastJurisdictionVisited"
    @study-data-update="onStudyDataUpdate($event)"
  >
    <template #header>
      <div class="w-full flex flex-col xl:flex-row xl:items-center justify-between">
        <div class="w-auto whitespace-nowrap mr-4 flex items-center">
          <div
            class="show-helper-on-hover flex items-center"
            @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'vintage', id: vintage.id })"
            @mouseenter="onHoverVintageTitle"
            @mouseleave="onHoverVintageTitle"
          >
            <h4
              v-go-to-admin-edit="{ name: 'AdminStudyItemEdit', params: { study_item_id: vintage.id, type: 'vintage' }}"
              class="psui-text-gray-80 psui-text-h6 font-bold cursor-pointer"
              @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'vintage', id: vintage.id })"
            >
              {{ vintage.title_long ? vintage.title_long : vintage.title }}
            </h4>
            <div class="w-2 psui-flex items-center mx-1">
              <PsIcon
                v-show="showHelper"
                icon="info"
                size="16"
                class="psui-text-blue-60"
              />
            </div>
          </div>
          <transition
            name="fade"
            mode="out-in"
          >
            <router-link
              v-if="prototypeUnits && vintageUnits"
              v-tooltip="{ content: buildingStockTooltip }"
              :to="{ name: 'BuildingEstimates', params: { jurisdiction_slug } }"
              class="flex items-center ml-2 mr-auto cursor-pointer psui-bg-gray-10 rounded-lg pr-2"
            >
              <AppPieChart
                :value="vintageUnits / prototypeUnits * 100"
                :caption="vintageUnitsAsPercentageTxt"
                class="mr-2"
              />
              <p class="psui-text-xsmall psui-text-gray-50">
                {{ Number(vintageUnits).toLocaleString() }} Units
              </p>
            </router-link>
            <div
              v-else-if="buildingStockFilters && typeof vintageUnits === 'undefined'"
              class="ml-2 flex items-center"
            >
              <vue-skeleton-loader
                :width="24"
                :height="24"
                animation="fade"
                class="mr-2"
              />
              <vue-skeleton-loader
                :width="120"
                :height="18"
                animation="fade"
              />
            </div>
          </transition>
        </div>
        <div
          v-if="expanded"
          class="flex items-center py-4 xl:py-0"
        >
          <CustomCombinationPresetsDropdown
            v-model="customCombinationPresetSelected"
            :options="getCustomCombinationPresetsOptions"
            class="mr-10"
            @setCustomCombinationPreset="setCustomCombinationPreset"
          />
          <div
            class="flex psui-cursor-pointer psui-text-blue-60 hover:opacity-80"
            @click="openDrawerWithEditColumns"
          >
            <PsIcon
              icon="calendar_view_week"
              size="18"
            />
            <span class="psui-ml-1 psui-font-bold psui-text-small whitespace-nowrap">Add/Hide Columns</span>
          </div>
        </div>
      </div>
    </template>

    <template #body="{ studyData }">
      <ForecastTable
        ref="forecastTable"
        :combined-measures-count.sync="combinedMeasuresCount"
        :study="study"
        :prototype="prototype"
        :building_stock_units="vintageUnits"
        :vintage="vintage"
        :study_data="studyData"
        :custom-combination-preset-selected.sync="customCombinationPresetSelected"
        :climate_zone_raw="$route.params.climate_zone_raw"
        :expanded="expanded"
        class="mt-3"
      />
    </template>

    <template #footer>
      <AppSeeMore
        v-if="studyData && studyData.length > 0"
        class="mr-auto"
        :expanded="expanded" 
        @click.native.stop="expanded = !expanded" 
      />
      <CustomCombinationToPolicyButton
        v-if="expanded"
        :disabled="!combinedMeasuresCount" 
        @click="saveToPolicy()"
      />
    </template>
  </StudyResultsContentCard>
</template>

<script>
import CustomCombinationToPolicyButton from './general/CustomCombinationToPolicyButton.vue'
import StudyResultsContentCard from './StudyResultsContentCard.vue'
import CustomCombinationPresetsDropdown from './general/CustomCombinationPresetsDropdown.vue'
import AppSeeMore from '@/components/general/AppSeeMore.vue'
import AppPieChart from '@/components/charts/AppPieChart.vue'
import ForecastTable from './table/ForecastTable'
import { mapGetters } from 'vuex'
import { convertToPercentageIfIsANumber } from '@/util/Helpers'
import { CUSTOM_COMBINATION_FILTERS } from '../shared/enums'
import Study from '@/models/Study'
import StudyDataApiService from '@/services/api/StudyDataApiService';

export default {
  name: 'StudyResultsContentVintage',
  components: { 
    ForecastTable, 
    CustomCombinationPresetsDropdown, 
    StudyResultsContentCard, 
    AppPieChart, 
    AppSeeMore, 
    CustomCombinationToPolicyButton 
  },
  props: {
    prototype: {
      type: Object,
      required: true
    },
    vintage: {
      type: Object,
      required: true,
    },
    study: {
      type: Object,
      required: true
    },
  },  
  data() {
    return {
      customCombinationPresetSelected: CUSTOM_COMBINATION_FILTERS.NONE,
      combinedMeasuresCount: 0,
      getCustomCombinationPresetsOptions: [],
      expanded: false,
      showHelper: false,
      studyData: null
    }
  },
  computed: {
    jurisdiction_slug() {
      if (this.$route.params.jurisdiction_slug) {
        return this.$route.params.jurisdiction_slug
      } else if (this.lastJurisdictionVisited && this.lastJurisdictionVisited?.slug) {
        return this.lastJurisdictionVisited.slug
      } else {
        return null
      }
    },
    prototypeUnits() {
      if (!this.buildingStockFilters) return 
      const { jurisdiction_id, climate_zone_prefix } = this.buildingStockFilters
      return this.$store.getters['globalEntities/Prototype/getterPrototypeBuildings']({ prototype_id: this.prototype.id, filters : { jurisdiction_id, climate_zone_prefix } }) ?? false      
    },    
    vintageUnits() {
      return this.isUpdating ? false : this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.buildingStockFilters)
    },
    buildingStockFilters() {
      const jurisdiction_id = this.lastJurisdictionVisited?.id ?? null
      const climate_zone_prefix = this.$route?.params?.climate_zone_raw?.split('-')?.[0] ?? null
      const type_prototype_id = this.prototype.type_prototype_id
      const type_vintage_id = this.vintage.type_vintage_id
      const prototype_id = this.prototype.id
      return prototype_id && jurisdiction_id && climate_zone_prefix && type_prototype_id && type_vintage_id ? { jurisdiction_id, climate_zone_prefix, type_prototype_id, type_vintage_id, prototype_id } : null
    },
    getterJurisdictionClimatesZonesPrefixSelected() {
      return this.lastClimateZoneVisited?.prefix ?? ''
    },
    vintageUnitsAsPercentageTxt() {
      return convertToPercentageIfIsANumber({ value: this.vintageUnits, maxValue: this.prototypeUnits, maximumFractionDigitsIfHigherThanOne: 1 })
    },
    buildingStockTooltip() {
      if (this.lastJurisdictionVisited?.type === 'City') {
        return `${this.vintageUnitsAsPercentageTxt} of ${this.prototype.title.toLowerCase()} in ${this.lastJurisdictionVisited.titles.title_type}, climate zone ${this.getterJurisdictionClimatesZonesPrefixSelected}, were ${this.vintage.title_long.toLowerCase()}. <br /><br /> Click for more details.`        
      } else {
        return `${this.vintageUnitsAsPercentageTxt} of ${this.prototype.title.toLowerCase()} in unincorporated ${this.lastJurisdictionVisited.titles.title_type}, climate zone ${this.getterJurisdictionClimatesZonesPrefixSelected}, were ${this.vintage.title_long.toLowerCase()}. <br /><br /> Click for more details.`        
      }
    },
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited']),
    ...mapGetters('assumptions', ['isUpdating']),
  },
  watch: {
    customCombinationPresetSelected: {
      handler(cur, prev) {
        if ((prev === 'none' || prev?.value?.toLowerCase() === 'none')) {
          if (!this.expanded) {
            this.expanded = true
          }
          if ((cur === 'none' || cur?.value?.toLowerCase() === 'none')) {
            this.updateCustomCombinationPresetsOptions()
          }
        }
      },
      immediate: true,
    },
    expanded: {
      handler(cur, prev) {
        if (cur === true && prev !== true) {
          this.updateCustomCombinationPresetsOptions()
        }
      },
      immediate: true,
    }
  },
  methods: {
    updateCustomCombinationPresetsOptions() {
      const filtersAvailable = []
      filtersAvailable.push(CUSTOM_COMBINATION_FILTERS.NONE)
      const enabledPresetsOnStudy = (new Study(this.study))?.enabled_cost_ratio_presets || []
      enabledPresetsOnStudy.forEach((enabledPreset) => {
        if (CUSTOM_COMBINATION_FILTERS?.[enabledPreset]) filtersAvailable.push(CUSTOM_COMBINATION_FILTERS[enabledPreset])
      })

      if(this.combinedMeasuresCount && this.customCombinationPresetSelected == CUSTOM_COMBINATION_FILTERS.CUSTOM) {
        filtersAvailable.push(CUSTOM_COMBINATION_FILTERS.CUSTOM)
      }
      this.getCustomCombinationPresetsOptions = filtersAvailable
    },
    setCustomCombinationPreset(customCombinationPreset) {
      this.$refs.forecastTable.setCustomCombinationPreset(customCombinationPreset)
    },
    goToBuildingEstimates() {
      this.$router.push({ name: 'BuildingEstimates' })
    },
    saveToPolicy() {
      const measuresIds = [...this.$refs.forecastTable.measureCombinations].map(studyData => studyData.measure_id )
      const eventName = 'openAddToPolicyModal'
      const eventPayload = {
        measuresIds, 
        study_id: this.study.id,
        prototype_id : this.prototype.id, 
        vintage_id: this.vintage.id 
      }
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: eventName, payload: eventPayload } })
      this.$eventBus.$emit(eventName, eventPayload)
    },    
    async onStudyDataUpdate($event) {
      this.studyData = $event

      // Defines main climate zone based on displaying study data and emit it to the parent to update Utility Rate Information
      let mainClimateZoneData = [...(this.studyData || [])]
      if (!mainClimateZoneData.length) {
        mainClimateZoneData = await StudyDataApiService.getByStudy(this.study.id, this.lastClimateZoneVisited.raw)
      }

      const climateZones = mainClimateZoneData?.reduce((acc, curr) => {
        const key = curr.climate_zone_raw.toUpperCase()
        if (key) {
          acc[key] = (acc?.[key] ?? 0) + 1
        }
        return acc
      }, {})

      let mainClimateZone = null
      Object.keys(climateZones).forEach((climateZone) => {
        if (!mainClimateZone || climateZones[climateZone] > climateZones[mainClimateZone]) {
          mainClimateZone = climateZone
        }
      })
      this.$emit('onUpdateMainStudyDataClimateZone', mainClimateZone, this.study, this.prototype)
    },
    onHoverVintageTitle(){
      this.showHelper = !this.showHelper
    },
    openDrawerWithEditColumns() {
      const studyId = this.study.id
      this.$eventBus.$emit('openStudyResultsDrawerEditColumns', 'Existing Buildings', false, this.$refs.forecastTable.getStudyDataOrdered, studyId)
    },
  }    
}
</script>

<style lang="scss" scoped>
  .divider {
    background-color: #E4EEF2;
    width: 1px;
  }
  ::v-deep {
    .forecast-table--cell {
      --cell-color: #E4EEF2;
      &.bg-combination-disabled {        
        --cell-color: #F0F0F0;
        .measure-combination-icon svg {
          fill: #CDCCCC;
        }
      }
      &.bg-not-effective {
        --cell-color: #fdf3f3;
        .measure-combination-icon svg {
          fill: #E3B6B6;
        }
      }
      &.bg-measure-added {
        --cell-color: #F6F9FB;
      }
    }
    .has-combination {
      .forecast-table--cell {
        &.bg-combination-disabled {
          background-color: #F0F0F0;
        }
        &.bg-not-effective {
          background-color: #fdf3f3;
        }
        &.bg-measure-added {
          background-color: #F6F9FB;
        }
      }
    }

  }
</style>
