import Vue from 'vue'
import router from '@/router'
import Api from '@/util/Api'
import UserEventApiService from '@/services/api/UserEventApiService'
import { POLICY_ATTACH_TO_USER_RETURNS } from '@/modules/app/policy/shared/enums.js'
import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType'
import { getExistingBuildingsImpact, getNewBuildingsImpact } from '@/business-logic/services/policy-options/impacts'
import EventBus from '@/util/EventBus'
import { GA_LABELS, gTagEvent } from '@/mixins/GaEventsMixin' 

window.dispatchedPOImpactLoadJurisdictions = []
window.loadedPOImpactLoadJurisdictions = []
export default {  

  getLoggedUser(context, showNotification = true) {
    return new Promise( (resolve, reject) => {
      Api.get(`/auth/get_logged_user`, { ignoreFeedback: true })
      .then(async ({ data }) => {
        
        const user = data
        context.commit('setLoggedUser', { user })
        
        const attachDraftPoliciesToUser = await context.dispatch('policy/attachPoliciesToUser')                 
        if (attachDraftPoliciesToUser === POLICY_ATTACH_TO_USER_RETURNS.HAS_NOT_POLICIES_TO_ATTACH) {
          if (showNotification) Vue.$toast.success('Login successful') 
        } else if (attachDraftPoliciesToUser === POLICY_ATTACH_TO_USER_RETURNS.POLICIES_HAS_BEEN_ATTACHED && user.last_login_at === null) {
          Vue.$toast.success('You now have an account and your policies have been saved.')        
        } else {
          Vue.$toast.success('You are now logged into your account and your policies have been saved.')    
        }
        
        // context.dispatch('general/checkRedirectTo')
        resolve({ user })
      })
      .catch(() => {
        Vue.$toast.error('Error on get logged user')
        reject()
      })
    })
  },

  logoutUser(context, {showNotification = true, redirect = true} = {}) {
    context.commit('setLogout')
    if(showNotification) Vue.$toast.success('Logged out!')
    if(redirect) router.push('/', () => {})
  },

  setUserPreferences({ commit, dispatch }, { mutation = 'setUserPreferences', payload }) {
    commit(mutation, payload)
    if (payload?.path === 'lastJurisdictionVisited' && payload?.value?.id) {
      dispatch('checkPolicyOptionImpactsUpdate', payload.value)
    }
    dispatch('updateUserPreferencesAtDatabase')
  },
  
  setUserPreferenceData({ commit, dispatch }, { path, data }) {
    if (!path || !data) throw new Error('Try to setUserPreference without path or data')
    commit('setUserPreferenceData', { path, data })
    dispatch('updateUserPreferencesAtDatabase')
  },
  setUserLastPolicyOptionSelected({ dispatch }, { context, value }) {
    if (value.length === 1) {
      const [studyType] = value
      dispatch('setUserLastStudyTypeVisited', {
        context,
        value: studyType,
      })
    } else if (value.length === 0) {
      dispatch('setUserLastStudyTypeVisited', {
        context,
        value: null,
      })
    }

    return dispatch('setUserPreferences', {
      payload: {
        path: 'policies.policyOptions.filterByStudyType',
        context,
        value,
      },
    })
  },
  setUserLastJurisdictionVisited({ dispatch }, { context, value }) {
    UserEventApiService.log({ event: 'SHOW_JURISDICTION', table: 'jurisdictions', table_item_id: value.id })
    return dispatch('setUserPreferences', { payload: { path: 'lastJurisdictionVisited', context, value } })
  },
  
  setUserLastClimateZonesVisited({ dispatch }, { context, value }) {
    return dispatch('setUserPreferences', { payload: { path: 'lastClimateZonesVisited', context, value } })
  },
  
  setUserLastStudyTypeVisited({ dispatch }, { context, value }) {
    return dispatch('setUserPreferences', { payload: { path: 'lastStudyTypeVisited', context, value } })
  },

  setUserLastJurisdictionAndFirstClimateZoneVisited({ dispatch, commit, getters }, { context, value }) {
    const lastJurisdictionSelected = getters.lastJurisdictionVisited
    UserEventApiService.log({ event: 'SHOW_JURISDICTION', table: 'jurisdictions', table_item_id: value.id })
    dispatch('setUserPreferences', { payload: { path: 'lastClimateZonesVisited', context, value: value.climate_zones } })
    
    commit('assumptions/clearResultsImpactAssumptions')
    return dispatch('setUserPreferences', { payload: { path: 'lastJurisdictionVisited', context, value } }).then((r) => {
      if(lastJurisdictionSelected?.slug !== value?.slug) {
        dispatch('checkAndDispatchJurisdictionGaEvent', { oldJurisdiction: lastJurisdictionSelected })
      }
      return r
    })
  },

  checkAndDispatchJurisdictionGaEvent({ getters }, opts) {
    const jurisdictionSelected = getters.lastJurisdictionVisited
    if (!jurisdictionSelected?.slug) {
      return
    }

    const key = `GA:${GA_LABELS.JURISDICTION_SELECTION}-${jurisdictionSelected.slug}`
    const isInSessionStorage = sessionStorage.getItem(key)
    if(isInSessionStorage) {
      return
    }

    sessionStorage.setItem(key, true)
    gTagEvent(GA_LABELS.JURISDICTION_SELECTION, {
      previous_jurisdiction_slug: opts?.oldJurisdiction?.slug || null,
      jurisdiction_slug: jurisdictionSelected.slug,
    })
  },

  setModuleVisited({ commit, dispatch }, module ) {
    commit('setModuleVisited', module)
    dispatch('updateUserPreferencesAtDatabase')
  },

  updateUserPreferencesAtDatabase(context) {
    const userId = context.getters.getterLoggedUser?.id
    if (userId) {
      Api.post(`/api/users/update/${userId}`, { user_preferences: context.state.user_preferences })
    }
  },

  async checkPolicyOptionImpactsUpdate({ rootGetters, getters, dispatch, commit }, jurisdiction) {
    const policyOptions = getters['policy/getterPolicyOptionsWithPrototypes']()?.filter((pO) => pO.algorithms?.length && !pO.algorithms.every((a) => a.policy_option_slug.includes('default-state')) && pO?.prototypes?.length) || []
    if (!policyOptions?.length || window.dispatchedPOImpactLoadJurisdictions.includes(+jurisdiction.id)) return

    window.dispatchedPOImpactLoadJurisdictions.push(+jurisdiction.id)
    const allData = await Promise.all(policyOptions.map(async (policyOption) => {
      return Promise.all(policyOption.algorithms.map(async (algorithm) => {
        if (algorithm.study_type_slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
          return await getExistingBuildingsImpact(algorithm, policyOption.prototypes, jurisdiction)
        } else if (algorithm.study_type_slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
          return await getNewBuildingsImpact(algorithm, policyOption.prototypes, jurisdiction)
        }
        return null
      }))
    }))
    if (allData.length) {
      window.loadedPOImpactLoadJurisdictions.push(+jurisdiction.id)
      EventBus.$emit('policyOptionsImpactsLoaded', { jurisdiction })
    }
  },
  
}
