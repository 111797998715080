import Api from '@/util/Api'
import asyncCacheResolver from '@/util/asyncCacheResolver'
import mainVuex from '@/store'

export default class BuildingStockApiService {
  static async getJurisdictionUnits(jurisdiction_id, options = {}) {
    const { includeExtraData = false, isBPSBuildingStocks = false } = options

    const apiData = await asyncCacheResolver(
      `BuildingStockApiService.getJurisdictionUnits:${jurisdiction_id}:extraData=${Boolean(includeExtraData)}:isBPS=${Boolean(isBPSBuildingStocks)}`,
      () => {
        return Api.get(`/api/building_stocks/get_all?jurisdiction_id=${jurisdiction_id}&includeExtraData=${includeExtraData}`, {
          headers: {
            'accept-cache': 'false'
            // 'invalidate-cache': 'true', // ToDO: Remove it!
          }
        })
          .then(({ data }) => {
            return Array.isArray(data.building_stocks) ? data.building_stocks : []
          })
          .catch((error) => {
            console.error(`Could not get jurisdiction '${jurisdiction_id}' building stocks: `, error)
            return []
          })
      }
    )

    const bpsBuildingStocks = []
    const buildingStocks = []

    apiData.forEach((bs) => {
      if (+bs.climate_zone_prefix === 0) {
        bpsBuildingStocks.push(bs)
      } else {
        buildingStocks.push(bs)
      }
    })

    if (!isBPSBuildingStocks) {
      mainVuex.dispatch('assumptions/buildingStocks/setBpsJurisdictionsBuildingStocks', {
        jurisdiction_id,
        bps_building_stocks: bpsBuildingStocks
      })
      return buildingStocks
    }

    return bpsBuildingStocks
  }
}
