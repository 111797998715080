export const HOME_AVAILABLE_RESOURCES = [
  {
    key: 'cost_effectiveness_studies',
    title: 'Cost-Effectiveness Studies',
    description: 'Explore the latest statewide cost-effectiveness studies and view the findings relevant for your jurisdiction.',
    icon: 'document_scanner',
    path: 'StudyResults'
  },
  {
    key: 'building_estimates',
    title: 'Building Estimates',
    description: 'Review new and existing building stock estimates for any California jurisdiction.',
    icon: 'bar_chart',
    path: 'BuildingEstimates'
  },
]

export const HOME_TESTIMONIALS = [
  {
    description:'I used to spend most of my time helping California cities make reach codes. Now, I just show them the Explorer and get to focus on helping cities in other states.',
    user:'Amy Rider',
    position:'Director of Policy Acceleration',
    jurisdiction:'Building Decarbonization Coalition',
  },
  {
    description:`I can now find and prepare key information in minutes vs hours. And, with the Explorer's sharing features, it is easier than ever to digitally engage with stakeholders.`,
    user:'Cory Downs',
    position:'Conservation Specialist',
    jurisdiction: 'City of Chula vista'
  },
  {
    description:`The Explorer streamlines the reach code process for cities across California. No more digging through studies to get the numbers, everything is right there!`,
    user:'Jon Doe',
    position:'Head Sustainability Department',
    jurisdiction: 'City of Gotham'
  }
]
