<template>
  <div 
    data-test-id="study-results-header-actions"
    class="base-header__actions psui-flex-shrink-0 psui-w-full psui-flex psui-items-center psui-space-x-2 psui-flex-wrap"
  >
    <PsButton
      label="Share"
      icon="share"
      size="small"
      layout="onlytext"
      class="psui-text-blue-50"
      @click="copyLink"
    />
          
    <PsButton
      label="Download"
      icon="save_alt"
      size="small"
      layout="onlytext"
      class="psui-text-blue-60"
      @click="generatePdf()"
    />
          
    <PsButton 
      label="Edit Assumptions"
      icon="tune"
      size="small"
      layout="onlytext"
      @click="openAssumptionsSidebar"
    />

    <AppPolicyCreateButton />
  </div>
</template>

<script>
import AppPolicyCreateButton from '@/components/general/AppPolicyCreateButton.vue'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import StudyResultsGeneratePdfService from '@/services/pdf/StudyResultsGeneratePdfService'
import { joinCommaPlusAnd } from '@/util/Functions'
import { mapGetters } from 'vuex'

export default {
  name: 'StudyResultsHeaderActions',
  components: { AppPolicyCreateButton },
    computed: {
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited'])
  },
  methods: {
    generatePdf() {
      this.toastInstance = this.$appToast({ type: 'loading', message: 'Loading...', duration: 0 })
      this.generatePdfFile()
      this.gaEventDownloadFile(GA_LABELS.RESULTS_DOWNLOAD_PDF, window.location.href)
    },
    openAssumptionsSidebar() {
      this.$store.commit('assumptions/openDrawerAtImpactTab')
    },
    copyLink() {
      this.copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!', duration: 5500 })
      this.gaEventShareUrl(GA_LABELS.RESULTS_SHARE_URL)
    },
    async generatePdfFile() {

      this.gaEventDownloadFile(GA_LABELS.RESULTS_DOWNLOAD_PDF)

      const args = {
        title: this.lastJurisdictionVisited?.titles.title_type,
        subtitle: `Climate Zone ${joinCommaPlusAnd([this.lastClimateZoneVisited.prefix])}`,
      }

      const pdf = new StudyResultsGeneratePdfService( args )
      pdf.then(() => {    
        this.toastInstance.message =  'Your PDF is ready.'
        this.toastInstance.type =  'success'
        setTimeout(() => {
          this.toastInstance.dismiss()
        }, 5000)
      }).catch(() => {
        this.toastInstance.message =  'Sorry, something went wrong with the PDF export. Try again'
        this.toastInstance.type =  'error'
        setTimeout(() => {
          this.toastInstance.dismiss()
        }, 5000)
      })
    },
  }
}
</script>

<style>

</style>
