<template>
  <BaseDrawer 
    ref="baseDrawer" 
    :title="!getterUserCanEditPolicy ? 'Policy Requirements' : 'Configure Your Policy Requirements'"
    class="app-drawer-requirements psui-relative psui-bg-gray-10"
    layout="requirements"
    :hierarchy="DRAWER_HIERARCHY.PRIMARY"
    :enable-full-screen="true"
    drawer-width="100%"
    :open-left-panel-on-mounted="true"
    transition-type="none"
    @close="$emit('close')"
  >
    <template #leftPanel>
      <PolicyEditRequirementsNewBuildingsLeftSideBarVue
        :type_prototypes="getTypePrototypeWithClimateZoneFilteredByJurisdiction"
        :custom_combinations="custom_combinations"
        :is-loading="isLoadingItem"
        @toggleRequirement="toggleRequirement"
        @setRequirements="setRequirements"
      />
    </template>

    <template #mainHeader>
      <div
        v-if="!isLoading"
        class="new-building-header psui-w-auto psui-flex psui-justify-between psui-items-center bg-white psui-shadow-elevation-5"
      >
        <AppBreadcrumb
          v-if="getBreadcrumbs"
          :items="getBreadcrumbs"
          class="breadcrumb psui-w-3/5 psui-flex-wrap"
          @click="handleBreadcrumbClick"
        />
        <PsButton
          layout="ghost"
          label="Done"
          size="small"
          class="ml-auto"
          @click.native="saveRequirement()"
        />
      </div>
    </template>
    <template #header>
      <div
        v-if="!isLoading && custom_combinations.length != 0"
        class="psui-flex px-6 pt-2 pb-1 justify-between"
      >
        <h1 
          class="psui-text-gray-80 psui-text-small psui-font-bold"
        >
          {{ 'Configure your policy requirements for new buildings' | policyTextRephrase }}
        </h1>
        <div
          class="psui-flex psui-justify-end psui-space-x-4 psui-w-2/4 mr-2"
          :class="{'pointer-events-none': isPolicyOutdated && isOutdatedPolicyModalDisabled}"
        >
          <div 
            class="psui-flex items-center psui-cursor-pointer justify-end psui-text-blue-60 hover:opacity-80"
            @click="$eventBus.$emit('openTheEditColumnsDrawer', { userPreferenceKey: 'columnsEditRequirementsNewBuildingsSF2022' })"
          >
            <PsIcon
              icon="calendar_view_week"
              size="18"
            />
            <span class="psui-ml-1 psui-font-bold psui-text-small">Add/Hide Columns</span>
          </div>

          <div
            class="psui-flex items-center psui-cursor-pointer justify-end psui-text-blue-60 hover:opacity-80"
            @click="openAssumptionsSidebar"
          >
            <PsIcon
              icon="tune"
              size="18"
            />
            <span class="psui-ml-1 psui-font-bold psui-text-small">{{ !getterUserCanEditPolicy ? 'View Assumptions' : 'Edit Assumptions' }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="!isCloseTip"
        class="psui-mt-2 psui-mb-5 psui-mx-10 psui-rounded-md psui-bg-gray-20"
      >
        <PsAccordion>
          <template #default>
            <PsAccordionItem
              :has-custom-header="true"
              :opened="false"
            >
              <template #custom-header="{ toggle }">
                <div
                  class="psui-flex psui-w-full psui-justify-between psui-items-center psui-mx-5"
                  @click="toggle"
                >
                  <div class="psui-flex psui-cursor-pointer psui-items-center">
                    <PsIcon
                      icon="arrow_forward_ios"
                      size="14"
                      class="icon-transition psui-text-blue-60"
                    />
                    <p
                      class="psui-ml-2 psui-font-bold psui-text-blue-70 hover:psui-text-blue-60"
                    >
                      Tips to go further
                    </p>
                  </div>
                  <PsButton
                    layout="onlytext"
                    size="small"
                    icon="close"
                    class="app-close-tip psui-text-blue-60 psui-bg-gray-20"
                    @click="closeTip()"
                  />
                </div>
              </template>
              <template #default>
                <div
                  v-if="policy && policy.policy_option && policy.policy_option.alert_text"
                  class="psui-flex psui-flex-col psui-mx-10 psui-text-gray-60 psui-text-small"
                  v-html="$options.filters.policyTextRephrase(policy.policy_option.alert_text)"
                />
                <div
                  v-else
                  class="psui-flex psui-flex-col psui-mx-10 psui-text-gray-60 psui-text-small"
                >
                  To go further, consider requiring higher compliance margins
                  and/or larger PV systems.
                  <br>
                  Requiring higher compliance margins may produce more savings.
                  <br>
                  To go even further, consider requiring buildings to be
                  electric-only.
                </div>
              </template>
            </PsAccordionItem>
          </template>
        </PsAccordion>
      </div>
    </template>

    <div
      v-if="!isLoading && custom_combinations.length != 0"
      class="psui-flex psui-flex-col psui-h-full psui-bg-gray-10 overflow-y-auto"
    >
      <div v-if="isComplianceMarginEnabled">
        <EditRequirementsNewBuildingsSF2022EDR1Meta
          :policy="policy"
          :custom_combinations="custom_combinations"
          @updateCustomCombinations="updateCustomCombinations"
        />
      </div>
      <EditRequirementsNewBuildingsSF2022Meta 
        v-else
        class="psui-p-6 psui-rounded-t-md psui-z-30 psui-bg-blue-10 psui-shadow-elevation-5"
        :custom_combinations="custom_combinations"
        @updateCustomCombinations="updateCustomCombinations"
      />
      <PolicyEditRequirementsNewBuildingsSF2022Table
        class="psui-space-y-8 mt-1"
        :class="isComplianceMarginEnabled ? '' : 'psui-p-6'"
        :custom_combinations="custom_combinations"
        :policy="policy"
      />
    </div>
    <PolicyEditRequirementsSkeleton v-else-if="isLoading" />
    <PolicyEditRequirementsInfoError v-else-if="custom_combinations.length == 0" />

    <template
      v-if="!isLoading && custom_combinations.length != 0"
      #footer
    >
      <div 
        v-if="getStudySource"
        class="psui-flex psui-items-center psui-text-small psui-text-gray-50 psui-leading-none psui-mb-3 pl-6 mt-2"
        @mouseenter="onHoverTypePrototype"
        @mouseleave="onHoverTypePrototype"
      >
        <span class="psui-font-bold">
          Study Source:
        </span>
        <span
          class="psui-ml-2 psui-cursor-pointer transition-all psui-text-gray-50 hover:psui-text-blue-60"
          @click.stop="onClickStudySourceInfo(getStudySource.id)"
        >
          {{ getStudySource.title }}
        </span>
        <span class="psui-ml-2">
          <strong class="psui-font-bold">Release Date:</strong> 
        </span>        
        <span 
          v-if="getStudySource.released_at"
          class="psui-ml-2 psui-cursor-pointer transition-all psui-text-gray-50 hover:psui-text-blue-60"
          @click="$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: getStudySource.study_group_id })"
        >
          {{ formatDate({ date: getStudySource.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }}
        </span>        
        <div
          class="psui-flex psui-mx-2"
          :class="isHoveringTypePrototype ? 'opacity-1' : 'opacity-0'"
        >
          <PsIcon
            :style="{ display: 'flex', marginTop: '0px' }"
            icon="info_outline"
            size="16"
            class="psui-cursor-pointer transition-all psui-text-gray-40 hover:psui-text-blue-60"
            @click.native="openPrototypeDescriptionModal(getPrototypeFromCustomCombination)"
          />
        </div>
      </div>

      <EditRequirementsNewBuildingsSF2022Footer
        v-if="custom_combinations.length != 0"
        :custom_combinations="custom_combinations"
        :policy="policy"
      />
    </template>
  </BaseDrawer>
</template>

<script>
import PolicyEditRequirementsSkeleton from '../PolicyEditRequirementsSkeleton.vue'
import AppBreadcrumb from '@/components/general/AppBreadcrumb.vue'
import EditRequirementsNewBuildingsSF2022Meta from './EditRequirementsNewBuildingsSF2022Meta.vue'
import PolicyEditRequirementsNewBuildingsSF2022Table from './PolicyEditRequirementsNewBuildingsSF2022Table.vue'
import EditRequirementsNewBuildingsSF2022Footer from './EditRequirementsNewBuildingsSF2022Footer.vue'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import { DRAWER_HIERARCHY } from '@/util/Enums.js'
import { checkIfPolicyIsOutdated } from '@/util/Helpers'
import { mapGetters } from 'vuex'
import EditRequirementsNewBuildingsSF2022EDR1Meta from './EditRequirementsNewBuildingsSF2022EDR1Meta.vue'
import PolicyRequirementsNewBuildingsMixin from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/PolicyRequirementsNewBuildingsMixin.js'
import CustomCombination from '@/models/CustomCombination.js'
import PolicyEditRequirementsInfoError from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/PolicyEditRequirementsInfoError.vue'
import { TYPE_FUELS_DB_SLUGS } from '@/util/Enums'
import PolicyEditRequirementsNewBuildingsLeftSideBarVue from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/PolicyEditRequirementsNewBuildingsLeftSideBar.vue'
import {
  GeneralPerformanceByFuelPolicyImpactAlgorithm
} from '@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel'

export default {
  name: 'PolicyEditRequirementsNewBuildingsSF2022',
  components:{
    AppBreadcrumb,
    PolicyEditRequirementsNewBuildingsSF2022Table,
    EditRequirementsNewBuildingsSF2022Meta,
    EditRequirementsNewBuildingsSF2022Footer, 
    PolicyEditRequirementsSkeleton,
    EditRequirementsNewBuildingsSF2022EDR1Meta,
    PolicyEditRequirementsInfoError,
    PolicyEditRequirementsNewBuildingsLeftSideBarVue
  },
  props: ['filters'],
  mixins: [PolicyRequirementsNewBuildingsMixin],
  data: () => ({
    breadcrumbs: null,
    custom_combinations: [],
    DRAWER_HIERARCHY,
    policy: false,
    isLoading: true,
    isCloseTip: true,
    isPolicyOutdated: false,
    isHoveringTypePrototype: false,
    isOutdatedPolicyModalDisabled: false,
    latestCustomCombination: [],
    isLoadingItem: null
  }),
  computed:{
    showBreadcrumbItems() {
      if (!this.breadcrumbs || !this.$refs.baseDrawer.leftPanelIsOpen || this.breadcrumbs.length < 2) return this.breadcrumbs
      return [[
        ...this.breadcrumbs
      ][1]]
    },
    getterUserCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params?.policy_id)
    },
    getTypePrototypes(){
      const payload = { policy_id:this.getPolicySelected.id, study_type_id:this.getterStudyTypeNewBuildings.id }
      return this.$store.getters['policy/getterPolicyAvailableTypePrototypesByStudyType'](payload)
    },
    getTypePrototypeWithClimateZoneFilteredByJurisdiction() {

      return this.getTypePrototypes.map(tp => {

        const disabled = this.isTypePrototypeDisabled(tp.id)

        return {
          ...tp,
          disabled,
          climate_zones: this.getPolicySelected.jurisdiction?.climate_zones.map(cz => {

            const disabled = this.isClimateZoneDisabled(tp,cz)
            return {
              ...cz,
              disabled
            }
          })
        }
      })
    },
    getBreadcrumbs() {
      let breadcrumb = [
        { title: 'My Policies'},
        { title: this.policy?.title ?? '...' }, 
      ]

      const ccs = this.custom_combinations.filter((cc) => cc.climate_zone_raw && cc.prototype_id)
      if(ccs.length > 0) {
        const custom_combination = ccs[0]
        const climate_zone = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: custom_combination.climate_zone_raw })
        const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: custom_combination.prototype_id })
        breadcrumb.push(...[
          { title: prototype.title }, 
          { title: climate_zone?.prefix ? 'Climate Zone ' + climate_zone.prefix : '...' },
        ])
      }
      return breadcrumb
    },
    getPrototypeFromCustomCombination() {
      if (!this.custom_combinations.length) return false
      return this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: this.custom_combinations[0].prototype_id })
    },
    getStudySource() {
      return this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: this.getPrototypeFromCustomCombination.study_id })
    },
    getPolicySelected() {
      return this.$store.getters['policy/getterUserPolicy']({id: this.$route?.params?.policy_id})
    },
    getGlobalPrototypes() {
      return this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']()
    },
    
    ...mapGetters('assumptions',['getterAssumptionsDrawerOptions'], ['lastStudyTypeVisited'],['getterStudyTypeNewBuildings']),
  },
  watch: {
    policy() {
      // We decided on EDR1 MVP launch to remove tips to go further.
      // this.isCloseTip = Boolean(sessionStorage.getItem(`tips-to-go-further-${this.policy?.id}`))
    },
    filters() {
      this.init()    
    },
    '$route.params.policy_id': {
      immediate: true,
      handler() {
        this.setPolicyOutdatedParameters(this.getPolicySelected)
        this.openBaseDrawer()
      }
    },
    '$route'($route, $previous) {
      if ($route.params.policy_id != $previous.params.policy_id) this.$refs?.baseDrawer?.closeDrawer()      
      // if ($route?.query?.per_custom_combination_id != $previous?.query?.per_custom_combination_id) this.init()
    },
    getGlobalPrototypes() {
      this.setPolicyOutdatedParameters(this.getPolicySelected)
      this.openBaseDrawer()
    }
  },
  mounted() {
    this.setPolicyOutdatedParameters(this.getPolicySelected)
    this.openBaseDrawer()
    this.init()
  },
  methods: {
    setRequirements(event, typePrototype, climateZones, isCreating) {

      const cz = climateZones.length == 1 ? climateZones[0] : null 
      this.isLoadingItem = `${typePrototype.slug}-${cz?.raw}`

      if(isCreating) {
        return this.createRequirements(typePrototype, climateZones)
      }
      this.removeRequirements(typePrototype, climateZones)
    },
    removeRequirements(typePrototype, climateZones) {
      return this.deleteClimateZoneWithinContainer(typePrototype, climateZones) 
    },
    async createRequirements(typePrototype, climateZones) {
      this.isLoading = true
      const { study_id : latest_published_study_id, ...payload } = await this.getDefaultCustomCombinationPayload(typePrototype)

      const customCombinations = climateZones.map(cz => {
        const { id: all_electric_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC })
        const all_electric_fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : latest_published_study_id, type_fuel_id: all_electric_type_fuel_id })
        const all_electric_custom_combination = new CustomCombination({ ...payload, climate_zone_raw:cz.raw, fuel_id : all_electric_fuel.id })
      
        const { id: mixed_fuel_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.MIXED_FUEL })
        const mixed_fuel_fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : latest_published_study_id, type_fuel_id: mixed_fuel_type_fuel_id })
        const mixed_fuel_custom_combination = new CustomCombination({ ...payload, climate_zone_raw:cz.raw, fuel_id : mixed_fuel_fuel.id })
        return [all_electric_custom_combination,mixed_fuel_custom_combination]
      }).flat(Infinity)
      

      await this.$store.dispatch(
        'policy/saveCustomCombinationsToPolicy',
        customCombinations
      ).then(() => {
        this.isLoading = false
      })

      this.policy = await this.$store.dispatch('policy/getUserPolicy', { 
        policyId: this.getPolicySelected.id, 
        forceGet: true 
      })
    
      const [climateZone] = climateZones
      this.isLoadingItem = null
      this.toggleRequirement(typePrototype, climateZone)
    },
    studiesPublishedByStudyTypeAndTypePrototype(typePrototype) {
      return this.$store.getters['globalEntities/Study/getterLatestPublishedStudiesByStudyTypeAndTypePrototype']({
        type_prototype_id: typePrototype.id,
        study_type_id: this.getterStudyTypeNewBuildings.id,
      })
    },
    async addPolicyContainerToPolicy({typePrototype, studyTypeId}) {
      const newContainer = {
        policy_id: this.getPolicySelected.id,
        study_type_id: studyTypeId,
        type_prototype_id: typePrototype.id,
        type_prototype_order: typePrototype.order
      }
      return await this.$store.dispatch('policy/updateOrCreatePolicyContainer', { container: newContainer, forceGet: true })
          .then( async (data) => {
            return data
          })
          .catch(err => {
            if (err?.response?.data?.message) {
              window.$vueInstance.$appToast({ message: err?.response?.data?.message })
            } else {
              window.$vueInstance.$appToast({type:'error', message: 'Something went wrong, please try again.' })
            }
            return null
          })
    },
    async getDefaultCustomCombinationPayload(typePrototype) {
      const { id : latest_published_study_id, study_group_id } = this.studiesPublishedByStudyTypeAndTypePrototype(typePrototype)[0]

      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({
        study_id : latest_published_study_id,
        type_prototype_id: typePrototype.id
      })

      let container = this.getPolicySelected.policy_containers.find( cc => +cc.type_prototype_id === +typePrototype.id)
      if (!container) {
        const studyGroup = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroup']({id: study_group_id})
        container = await this.addPolicyContainerToPolicy({typePrototype, studyTypeId: studyGroup.study_type_id})
        await this.$store.dispatch('policy/getAllUserPolicies')
      }
      
      return {
        prototype_id: prototype.id,
        jurisdiction_id : this.getPolicySelected.jurisdiction_id,
        policy_id: this.getPolicySelected.id,
        policy_container_id : container.id,
        study_id : latest_published_study_id
      }
    },
    async deleteClimateZoneWithinContainer(typePrototype, climateZones ) {
      this.isLoading = true

      const custom_combinations = climateZones
      .map(cz => this.getCustomCombinationsByTypePrototypeAndClimateZone(typePrototype, cz))
      .flat(Infinity) 
      
      await Promise.all(custom_combinations.map(cc => CustomCombinationApiService.delete(cc.id)))


      this.$appToast({type:'success', message: 'A climate zone was successfully deleted.' })

      const deletedCustomCombinationStringJoint = custom_combinations.map(cc => cc.id).join(',')
      const currentCustomCombinationStringJoint = this.custom_combinations.map(cc => cc.id).join(',') 
      
      if(deletedCustomCombinationStringJoint == currentCustomCombinationStringJoint || climateZones.length > 1) {
        this.custom_combinations = []
        const newQuery = Object.assign({}, this.$route.query)
        delete newQuery.per_custom_combination_ids

        this.$router.replace({
          ...this.$route,
          query: newQuery
        })
      } 
      
      if(climateZones.length > 1) {
        const container = this.getPolicySelected.policy_containers.find(cc => +cc.type_prototype_id === +typePrototype.id)

        this.$store.dispatch('policy/deleteContainerFromPolicy', {
          container, 
          refreshPolicy: false 
        })
      }

      await this.$store.dispatch('policy/getUserPolicy', {
        policyId: this.getPolicySelected.id, 
        forceGet: true 
      })

      this.isLoading = false
      this.isLoadingItem = null
    },
    toggleRequirement(typePrototype, climateZone) {
      const getCustomCombinationsId = this.getCustomCombinationsByTypePrototypeAndClimateZone(typePrototype, climateZone)
      
      if(getCustomCombinationsId.length == 0) {
        return this.setRequirements(event, typePrototype, [climateZone], true)
      }
      const formatedCustomCombination = getCustomCombinationsId.map(cc => new CustomCombination(cc))
      
      this.custom_combinations = formatedCustomCombination

      this.latestCustomCombination = formatedCustomCombination

      this.$router.push({
        ...this.$route,
        query:{
          ...this.$route.query,
          per_custom_combination_ids: getCustomCombinationsId.map(cc => cc.id).join(',')
        }
      })
      .catch(()=>{})

      this.$forceUpdate()
    },
    getCustomCombinationsByTypePrototypeAndClimateZone(typePrototype, climateZone) {
      return this.getPolicySelected.policy_containers.reduce((acc,curr) => {
        if(curr.type_prototype_id == typePrototype.id) {
          const ccWithinClimateZone = curr
          .custom_combinations
          .filter( cc => cc.climate_zone_raw == climateZone.raw )
          
          acc.push(ccWithinClimateZone)
        }
        return acc
      },[]).flat(Infinity)
    },
    isClimateZoneDisabled(typePrototype, climateZone){

      const [cc] = this.getCustomCombinationsByTypePrototypeAndClimateZone(typePrototype, climateZone)
      const meta = GeneralPerformanceByFuelPolicyImpactAlgorithm.extractMetaInfoFromCustomCombination(cc)
      
      return this.getCustomCombinationsByTypePrototypeAndClimateZone(typePrototype, climateZone).length == 0 || meta.isOnDefaultState
    },
    isTypePrototypeDisabled(typePrototypeId) {
      return !this.getPolicySelected
      ?.policy_containers
      ?.find(policy_container => +policy_container.type_prototype_id === +typePrototypeId)
      ?.custom_combinations.some(cc => {
        const meta = GeneralPerformanceByFuelPolicyImpactAlgorithm.extractMetaInfoFromCustomCombination(cc)
        return !meta.isOnDefaultState
      })
    },
    handleBreadcrumbClick($event, item, index) {
      if (index > 1) return
      this.saveRequirement()
      if (index === 0) this.$router.push({path: '/policies'})
    },
    setPolicyOutdatedParameters(policy) {
      this.isPolicyOutdated = this.policyHasPrototypeOutdated(policy)
      this.isOutdatedPolicyModalDisabled = this.isPolicyDisabledOutdatedModal(policy)
    },
    async init() {
      this.isLoading = true
      await this.getPolicyCustomCombinations()
    },
    async getPolicyCustomCombinations() {
      this.isLoading = true
      const { custom_combination_ids: custom_combination_ids_as_string } = this.filters      
      // If none of custom combinations are passed, keep loading while wait for receive a next request with custom combinations
      if (custom_combination_ids_as_string) {
        const custom_combination_ids = custom_combination_ids_as_string.split(',')
        const requests = custom_combination_ids.map(cc_id => CustomCombinationApiService.get(cc_id))      
        await Promise.all(requests).then(res => {
          this.custom_combinations = res
          this.latestCustomCombination = res
          this.policy = this.$store.getters['policy/getterUserPolicy']({ id: res[0]?.policy_id })
          this.$forceUpdate()
        })

        setTimeout(() => { this.isLoading = false }, 500)
      }
    },
    openBaseDrawer() {
      if(this.isPolicyOutdated && this.isOutdatedPolicyModalDisabled) {
        this.$refs?.baseDrawer?.closeDrawer()
        return
      }
      this.$refs?.baseDrawer?.openDrawer()
    },
    openAssumptionsSidebar() {
      const getNewBuildingStudyType = this.$store.getters['globalEntities/StudyType/getterStudyTypeNewBuildings']
      this.$store.commit('assumptions/setDrawerOptions', {...this.getterAssumptionsDrawerOptions,tab:getNewBuildingStudyType})
      this.$store.commit('assumptions/openDrawerAtImpactTab')
    },
    openNewBuildingsDuplicateModal() {
      const policy = this.$store.getters['policy/getterUserPolicy']({id: this?.$route?.params?.policy_id})
      if(this.getterUserCanEditPolicy) return
      this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', {policy})
    },
    async updateCustomCombinations({ custom_combinations }) {
      this.$set(this, 'custom_combinations', custom_combinations)

      this.policy = await this.$store.dispatch('policy/getUserPolicy', {
        policyId: this.policy.id, 
        forceGet: true 
      })
    },
    closeTip() {
      this.isCloseTip = !this.isCloseTip
      if (this.isCloseTip) {
        sessionStorage.setItem(`tips-to-go-further-${this.policy.id}`, 'true')
      }
    },
    onClickStudySourceInfo(study_id){
      this.$eventBus.$emit('openDrawerContent', { type: 'study', id: study_id })
    },
    onHoverTypePrototype(){
      this.isHoveringTypePrototype = !this.isHoveringTypePrototype
    },
    openPrototypeDescriptionModal(prototype) {
      this.$eventBus.$emit('openDescriptionModal', { type: 'prototype', id: prototype.id })
    },
    policyHasPrototypeOutdated(policy) {
      return checkIfPolicyIsOutdated(policy).has_outdated_studies
    },
    isPolicyDisabledOutdatedModal(policy){
      return checkIfPolicyIsOutdated(policy).block_policy
    },
    saveRequirement() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .new-building-header {
    padding: 7px 24px 7px 16px;
    li:nth-of-type(2),li:nth-of-type(1) {
      span:hover {
        cursor: pointer;
        color: rgb(49 143 172/var(--tw-text-opacity));
      }
     }
  }

  .app-drawer-requirements {
    background-color: #F3F6F9 !important;
    
    ::v-deep .app-drawer-content {
      overflow-y: hidden;
    }

    .psui-text-accentSmall {
      letter-spacing: 0.6px;
    }

    ::v-deep .app-drawer-header {
      margin-bottom: 0.5rem;
    }

    ::v-deep .ps-tooltip-adjustments {
      padding-top: 1rem;
      padding-right: 2.5rem;
      padding-bottom: 1rem;
      padding-left: 2.5rem;
    }

    ::v-deep .app-drawer-content {
      padding-right: 0;
      padding-left: 0 !important;
      border-radius: 0;
      padding-bottom: 0;
    }
  } 

  .icon-transition {
    ::v-deep span {
      transition: transform 0.4s ease-in-out;
    }
  }
 
  .psui-el-accordion.layout-medium {
    .psui-el-accordion-item {
      &.status-opened {
        ::v-deep .icon-transition {
          span {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
 
  .app-close-tip {
    &.psui-el-button.size-small {
      background-color: #e6ecf2;
      &.icon-left {
        padding: 0;
        i {
          margin: 0;
        }
      }
    }
  }

</style>
