<template>
  <div class="card psui-flex psui-flex-col psui-shadow-elevation-5 psui-bg-white psui-rounded-lg psui-w-full xl:psui-w-1/3 relative">
    <AppNewFeatureBadge
      feature="bps"
      layout="new-badge"
    />
    <div class="card-header psui-px-5 2xl:psui-px-8 psui-py-6 psui-space-y-4 sm:psui-space-y-0 xl:psui-space-y-4 psui-flex psui-items-start">
      <img 
        src="images/home_page/home-making-policies.png"
        alt="Unlock Insights for Benchmarking Policies and BPS"
        class="flex-shrink-0"
      >

      <div class="content psui-flex-grow xl:psui-flex-grow-0 psui-space-y-4">
        <h2 class="psui-text-h4 psui-text-blue-80 psui-font-bold">
          Unlock insights for benchmarking policies and BPS
        </h2>

        <p class="psui-text-p psui-text-gray-60">
          Analyze building estimates and customize building size thresholds to assess coverage for existing nonresidential buildings.
        </p>
      </div>
    </div>

    <div class="card-footer psui-flex psui-flex-row psui-border-t psui-border-gray-20 psui-px-5 2xl:psui-px-8 psui-py-4 psui-mt-auto">
      <PsButton
        label="Explore data"
        layout="ghost"
        size="medium"
        @click="$router.push({name:'BuildingEstimates', query: { only_building_type: BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL}})"
      />
      <PsButton
        label="Learn more"
        layout="onlytext"
        size="medium"
        @click="openIntercomBpsArticle"
      />
    </div>
  </div>
</template>

<script>
import { BUILDING_TYPE_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'HomeMainBPS',
  data:() => ({
    BUILDING_TYPE_DB_SLUGS
  }),
  methods:{
    openIntercomBpsArticle(){
      window.open('https://intercom.help/explorer-local-energy-codes/en/articles/10458553-new-feature-existing-nonresidential-building-data-for-bps-and-benchmarking', '__blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    &-header {
      flex-direction: column;

      @media (min-width: 640px) {
        flex-direction: row;

        .content {
          margin-left: 1.5rem;
        }
      }
      
      @media (min-width: 1200px) {
        flex-direction: column;
        .content {
          margin-left: 0;
        }
      }
      
      img {
        max-height: 90.45px;
      }
    }

    &-footer {
      flex-direction: column;
      align-items: flex-start;

      button,
      a {
        &:not(:last-child) {
          margin-bottom: 0.5rem;

          @media (min-width: 432px) {
            margin-bottom: 0;
            margin-right: 0.5rem;
          }
        }
      }

      @media (min-width: 432px) {
        align-items: center;
        flex-direction: row;
      }
    }
  }
</style>
