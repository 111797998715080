<template>
  <div 
    ref="dropDownButton" 
    v-click-outside="close"
    class="app-checkbox relative inline-block text-left"
  >
    <button 
      :id="id" 
      ref="dropDownButtonTrigger"
      data-test-id="dropdown-button" 
      type="button" 
      class="inline-flex justify-center items-center w-full font-medium focus:shadow-outline-blue dropdown-button text-sm leading-none" 
      :class="[buttonClasses]" 
      aria-haspopup="true" 
      aria-expanded="true"
      @click.prevent="show && !toggleWhenActive ? '' : toggle()"
    >
      <slot
        v-if="show && $slots.buttonLabelOnShow"
        name="buttonLabelOnShow"
      />
      <slot
        v-else
        name="buttonLabel"
      />
    </button>

    <div 
      ref="dropdownDialog"
      role="menu" 
      class="dropdowndialog hidden origin-top-right fixed mt-2 w-auto rounded shadow-lg border border-blue05 z-50 opacity-0" 
      aria-orientation="vertical" 
      :aria-labelledby="id"
      :class="[dropdownDialogClasses]"
      :style="{ minWidth: minWidthDropdown, marginLeft: marginLeft }"
    >
      <div class="w-full">
        <h2
          v-if="title"
          class="text-gray02 font-bold whitespace-nowrap mb-4 ts--accent--1"
        >
          {{ title }}
        </h2>
        <slot name="items" />
      </div>
    </div>
  </div>
</template>

<script>
import { randomString, getParentScrollableEl } from '@/util/Functions'
import { mapGetters } from 'vuex'

export default {
  name: 'Dropdown',
  props: {
    buttonClasses: {
      type: String,
      default: 'bg-white border border-blue'
    },
    title: {
      type: String,
    },
    dropdownDialogClasses: {
      type: String,
      default: 'p-6 left-0 bg-white'
    },
    minWidthDropdown: {
      type: [String, Number],
    },
    maxWidthDropDown: {
      type: String,
      default: '340px'
    },
    buttonLabelOnShow: {
      type: Boolean,
      default: false
    },
    toggleWhenActive: {
      type: Boolean,
      default: true
    },
    runOnTop: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      id: '',
      marginLeft: '-0px',
      scrollableParentEl : null 
    }
  },
  computed: {
    ...mapGetters('assumptions', ['getterAssumptionsDrawerOptions']),
    getMaxWidth() {
      let bounds = this.$refs.dropDownButton.getBoundingClientRect()
      // console.log('bounds', bounds, this.$refs.dropDownButton.clientWidth, this.$refs.dropDownButton)
      return (document.documentElement.clientWidth - bounds['left']) -30
    }
  },
  mounted() {
    this.id = randomString(8)
    document.addEventListener("keyup", this.handleEsc)
    window.addEventListener("resize", this.updatePosition)

    this.mutationObserver = new MutationObserver(() => {
      this.updatePosition()
    })

    this.mutationObserver.observe(this.$refs.dropdownDialog, {
      attributes: true,
      attributeFilter: ['style', 'class']
    })
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleEsc)
    document.removeEventListener("resize", this.updatePosition)
    this.unwatchParentScrolling()
    this.mutationObserver.disconnect()
  },
  methods: {
    toggle() {
      this.$emit('click')
      if(this.disabled) return
      if (!this.show) {
        this.open()
      } else {
        this.close()
      }
    },
    watchParentScrolling() {
      this.scrollableParentEl = getParentScrollableEl(this.$refs.dropDownButton)
      if (this.scrollableParentEl) {
        this.scrollableParentEl.addEventListener('scroll', this.updatePosition)
      }
    },
    unwatchParentScrolling() {
      if (this.scrollableParentEl) {
        this.scrollableParentEl.removeEventListener('scroll', this.updatePosition)
      }
    },
    updatePosition() {
      const dropdownDialog = this.$refs.dropdownDialog
      const dropdownTrigger = this.$refs.dropDownButtonTrigger
      if (!dropdownDialog || !dropdownTrigger) return 
      dropdownDialog.style.position = 'fixed'      

      const triggerRect = dropdownTrigger.getBoundingClientRect()
      const dialogRect = dropdownDialog.getBoundingClientRect()

      let windowWidth = document.documentElement.clientWidth

      if (this.getterAssumptionsDrawerOptions.visibility) {
        const drawerEl = document.querySelector('.assumptions-drawer')
        if (drawerEl) {
          windowWidth -= drawerEl.getBoundingClientRect().width
        }
      }

      dropdownDialog.style.top = `${triggerRect.y + triggerRect.height}px`

      if ((triggerRect.left + dialogRect.width + 20) > windowWidth) {
        dropdownDialog.style.left = `${triggerRect.right - dialogRect.width}px`
      } else {        
        dropdownDialog.style.left = `${triggerRect.left}px`
      }
      
      if (triggerRect.top < 40 && this.runOnTop !== true) {
        this.close()
        return
      }
          
      this.$nextTick(() => {        
        dropdownDialog.style.opacity = 1
      })
    },
    open() {
      this.$emit('open')
      if (this.disabled) return
      this.show = true

      const dropdownDialog = this.$refs.dropdownDialog      
      dropdownDialog.style.display = 'block'
      dropdownDialog.style.opacity = 0   

      this.$nextTick(() => {
        void dropdownDialog.offsetHeight
        requestAnimationFrame(() => {
          this.updatePosition()
          requestAnimationFrame(() => this.updatePosition())
          this.watchParentScrolling()
        })
      })
    },
    close() {
      if (this.show) {
        this.$emit('close')
        this.$refs.dropdownDialog.style.display = 'none'
        this.$refs.dropdownDialog.style.opacity = 0
        this.show = false
        this.unwatchParentScrolling()
      }
    },
    handleEsc(evt) {
      if (this.show && evt.keyCode === 27) this.close()
    },
  }
}
</script>

<style>
  .dropdown-button {
    background-color: transparent;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    min-height: 27px;
  }

  .dropdown-button:focus {
    outline: none;
  }

  .dropdowndialog {
    transition: opacity 150ms ease-in-out;
    &:has(.has-right-label) {
      border-radius: 6px !important;
    }
  }

</style>
