<template>
  <thead   
    :class="[
      'sticky z-20 top-0 rounded-t-sm',
      { 'scrolling': isScrolling, 'overflow-active': isOverflowActive },
      { 'not-scrolling': !isScrolling }
    ]"
  >
    <tr>
      <td class="sticky-column-left rounded-tl-sm z-20 bg-[#c2deec] h-full">
        <div class="w-full block h-[23px]" />
      </td>
      <td
        class="impact-row psui-text-small bg-[#c2deec] psui-text-blue-70"
        :colspan="columns.length - 1"
      >
        <div class="impact-preview-wrapper flex items-center place-content-center font-bold h-[23px]">
          <span>City-wide Impact Preview</span>
          <PsIcon
            icon="info_outline"
            size="16"
            class="impact-preview-wrapper-icon psui-text-gray-40 hover:psui-text-blue-60 psui-transition leading-none cursor-pointer ml-1"
            display="flex"
            @click="$eventBus.$emit('openDescriptionModal', impactPreviewHelper)"
          />
        </div>
      </td>
      <td class="sticky-column-right bg-[#e6ecf2] h-[23px]" />
    </tr>
    <tr>
      <th
        v-for="(item, index) in columns"
        :key="`${item.key}-${index}`"
        :class="[
          'psui-text-small bg-[#ecf7fb] h-[43px]',
          { 'first-header-row sticky-column-left': index === 0 },
        ]"
      >
        <div
          v-if="index === 0"
          class="border-r border-[#e6ecf2] h-full"
          :class="[
            'pt-1 pb-1 pr-[23px] text-[#00465F]',
            { 'pl-4': index === 0 },
          ]"
        >
          <span>
            Requirements for permit applicants
          </span>
        </div>
        <div
          v-else
          class="table-header items-center px-4/5"
        >
          <span
            class="psui-text-small psui-text-blue-70 mx-auto text-center w-fit"
          >{{ item.title }}</span>
          <PsIcon
            icon="info_outline"
            size="16"
            display="flex"
            class="table-header-info-icon psui-text-gray-40 cursor-pointer hover:psui-text-blue-60 w-fit ml-[2px] mt-[1px]"
            @click="$eventBus.$emit('openDescriptionModal', item.hasHelper)"
          />
          <span
            v-if="item.description"
            class="psui-text-gray-50 psui-text-xsmall font-normal text-center"
          >{{ item.description }}</span>
        </div>
      </th>
      <th class="sticky-column-right bg-[#e6ecf2]">
        <div />
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'PolicyOptionsContentTableHeader',
  props: ['columns', 'isOverflowActive', 'isScrolling'],
  data: () => ({
    impactPreviewHelper: {
      type: 'helpers',
      slug: "impact_preview"  
    }
  })
}
</script>

<style lang="scss" scoped>
$blue-30: #bedceb;
$gray-20: #e6ecf2;

@mixin absolute-full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin cell-box-shadow($shadow-value, $important: false) {
  @media (min-width: 910px) {
    > div {
      @include absolute-full;
      box-shadow: $shadow-value;
      @if $important {
        box-shadow: $shadow-value !important;
      }
    }
  }
}

thead {
  tr {
    .sticky-column-right {
      width: 120px;
      @media (min-width: 1200px) {
        min-width: 140px;
      }
    }

    th:first-child,
    td:first-child {
      width: 320px;
      border-right: none;

      @media (min-width: 1200px) {
        width: 404px;
      }
    }
  }

  tr:not(:first-child) {

    th:not(.sticky-column-right):not(:first-of-type):not(:nth-of-type(5)),
    td:not(.sticky-column-right):not(:first-of-type):not(:nth-of-type(5)) {
      min-width: 120px;
      max-width: 172px;

      border-right: $gray-20 solid 1px;
    }

    th:not(:first-child),
    td:not(:first-child) {
      @media (max-width: 1200px) {
        span {
          width: 70px;
          text-align: center;
        }
      }
    }
  }

  tr:nth-of-type(1) td:first-child,
  tr:nth-of-type(2) th:first-child {
    box-shadow: inset 2px 0 0 $blue-30;
  }

  tr:nth-of-type(1) td:last-child,
  tr:nth-of-type(2) th:last-child {
    box-shadow: inset 1px 0 0 $gray-20 !important;
  }
}

.not-scrolling {
  tr {
    &:first-of-type {
      td:first-of-type {
        div {
          border-right: $gray-20 1px solid;
        }
      }
    }
  }
}

.scrolling {
  tr:nth-of-type(1) td:first-child,
  tr:nth-of-type(2) th:first-child {
    @include cell-box-shadow(3px 0 6px -5px rgba(192, 192, 192, 1));
  }
}

.overflow-active {
  tr:nth-of-type(1) td:last-child,
  tr:nth-of-type(2) th:last-child {
    @include cell-box-shadow(-3px 0px 6px -6px rgba(192, 192, 192, 1), true);
  }
}

.table-header {
  display: grid;
  justify-items: center;
  margin-left: 18px;
  place-self: center;
  align-items: center;
  grid-template-columns: max-content min-content;
  grid-template-rows: max-content auto;

  @media (max-width:1400px) {
    grid-template-columns: min-content auto;
    grid-template-rows: auto auto;
  }
}

.impact-row {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 10px; /* Adjust for thickness */
    height: 10px; /* Same as width for a quarter-circle */
    box-shadow: 5px -5px 0 5px $gray-20;
    border-radius: 0 4px 0 0; /* Top-right curve */
  }
}

.impact-preview-wrapper {
  &:hover {
    .impact-preview-wrapper-icon {
      visibility: visible;
    }
  }
  .impact-preview-wrapper-icon {
    visibility: hidden;
  }
}

.table-header {
  &:hover {
    .table-header-info-icon {
      visibility: visible;
    }
  }

  .table-header-info-icon {
    visibility: hidden;
  }
}

.first-header-row {
  position: relative;
}

.other-header-rows {
  position: relative;
}

.sticky-column-left {
  @media (min-width: 910px) {
    position: sticky;
    left: 0;
    z-index: 20;
  }
}

.sticky-column-right {
  @media (min-width: 910px) {
    position: sticky;
    right: 0;
    z-index: 2;
  }
}
</style>
