import ExcelJS from 'exceljs'
import Store from '@/store'
import { parsejurisdictionAsFullText } from '@/util/Helpers.js'
import BuildingStockApiService from '@/services/api/BuildingStockApiService.js'

function updateColumnLabels(worksheet, selectedAreaLabel) {
  let areaRange
  try {
    areaRange = JSON.parse(selectedAreaLabel)
  } catch (e) {
    console.error('Error parsing selectedAreaLabel:', e)
    return
  }

  const buckets = [
    { slug: '>100k', start: 100000 },
    { slug: '50-100k', start: 50000 },
    { slug: '40-50k', start: 40000 },
    { slug: '30-400k', start: 30000 },
    { slug: '25-30k', start: 25000 },
    { slug: '20-25k', start: 20000 },
    { slug: '15-20k', start: 15000 },
    { slug: '10-15k', start: 10000 },
    { slug: '7.5-10k', start: 7500 },
    { slug: '5-7.5k', start: 5000 },
    { slug: '<5k', start: 0 }
  ]

  const bucketIndex = areaRange.max
  const bucket = buckets[bucketIndex]

  if (!bucket) {
    console.error('Bucket not found for index:', bucketIndex)
    return
  }
  const getFormattedBucketLabel = (bucket) => {
    switch(bucket.slug) {
      case '<5k':
        return `<5K+ sqft`
      case '>100k':
        return `>${bucket.start/1000}K+ sqft`
      default:
        return `${bucket.slug.split('-')[0].replace('k', '')}K+ sqft`
      }
  } 
  const areaLabel = getFormattedBucketLabel(bucket)

  worksheet.getCell('D9').value = `Buildings (${areaLabel})`
  worksheet.getCell('E9').value = `Floor Area (${areaLabel})`
}

export function exportRawBuildingLevelData(workbook) {
  return new Promise((resolve, reject) => {
    try {
      const jurisdiction = Store.getters['lastJurisdictionVisited']
      const jurisdictionId = jurisdiction ? jurisdiction.id : null

      if (!jurisdictionId) {
        console.warn('No jurisdiction ID available')
        resolve()
        return
      }

      BuildingStockApiService.getJurisdictionUnits(jurisdictionId, {
        includeExtraData: true,
        isBPSBuildingStocks: true
      })
        .then((bpsData) => {
          if (!Array.isArray(bpsData)) {
            console.warn('bpsData is not an array')
            resolve()
            return
          }

          const header = [
            'jurisdiction',
            'type',
            'countyname',
            'bldg_count',
            'area_mean',
            'area_sum',
            'yearbuilt_mean',
            'stories',
            'doe_prototype',
            'propertytype',
            'propertysubtype',
            'cbecs',
            'statefp',
            'placeid',
            'countyid'
          ]
          const tableData = [header]

          bpsData.forEach((record) => {
            const extraData = record.extra_data || {}

            const jurisdictionName = extraData.city_name_raw || jurisdiction.title || 'N/A'
            const jurisdictionType = jurisdiction?.type || 'N/A'
            const countyName = extraData.countyname || jurisdiction?.county || 'N/A'
            const bldgCount = record.units || 0
            const areaMean = record.floor_area || 0
            const areaSum = extraData.area_sum || bldgCount * areaMean
            const yearbuiltMean = extraData.yearbuilt_mean || 'N/A'
            const stories = extraData.stories || 'N/A'

            const typePrototype = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({
              id: record.type_prototype_id
            })

            let doePrototype = typePrototype?.slug || 'unknown'
            if (doePrototype.startsWith('bps-')) {
              doePrototype = doePrototype.substring(4)
            }

            const propertytype = extraData.reported_propertytype || typePrototype?.title || 'N/A'
            const propertysubtype = extraData.reported_propertysubtype || 'N/A'

            const cbecs = extraData.cbecs || 'N/A'
            const statefp = extraData.statefp || 'N/A'
            const placeid = extraData.placeid || 'N/A'
            const countyid = extraData.countyid || 'N/A'

            tableData.push([
              jurisdictionName,
              jurisdictionType,
              countyName,
              bldgCount,
              areaMean,
              areaSum,
              yearbuiltMean,
              stories,
              doePrototype,
              propertytype,
              propertysubtype,
              cbecs,
              statefp,
              placeid,
              countyid
            ])
          })

          const rawDataSheet = workbook.addWorksheet('Raw Building Level Data')
          rawDataSheet.addRows(tableData)

          const rightAlignColumns = [4, 5, 6, 7, 8, 13, 14, 15]

          const headerRow = rawDataSheet.getRow(1)
          headerRow.font = { bold: true }
          headerRow.eachCell((cell) => {
            cell.alignment = { horizontal: 'left' }
          })

          rawDataSheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber === 1) return

            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
              if (rightAlignColumns.includes(colNumber)) {
                cell.alignment = { horizontal: 'right' }
              } else {
                cell.alignment = { horizontal: 'left' }
              }
            })
          })

          rawDataSheet.columns.forEach((column) => {
            let maxLength = 10
            column.eachCell({ includeEmpty: true }, (cell) => {
              if (cell.value != null) {
                const cellText = cell.value.toString()
                maxLength = Math.max(maxLength, cellText.length)
              }
            })

            column.width = maxLength + 2
          })

          resolve()
        })
        .catch((error) => {
          console.error('Error exporting raw building level data:', error)
          reject(error)
        })
    } catch (error) {
      console.error('Error in exportRawBuildingLevelData:', error)
      reject(error)
    }
  })
}

export function exportExistingBuildingsNonResidentialXLS() {
  return new Promise((resolve, reject) => {
    try {
      const table = document.querySelector('.building-table-xls')
      const selectedAreaLabel = document.querySelector('.selected-area-label-xls')?.innerText || 'N/A'

      if (!table) {
        console.error('Table not found for export.')
        reject(new Error('Table not found'))
        return
      }

      const today = new Date()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')
      const year = today.getFullYear()
      const formattedDate = `${month}/${day}/${year}`
      const jurisdiction = parsejurisdictionAsFullText(Store.getters['lastJurisdictionVisited'], true) ?? ''

      const infoData = [
        [`${jurisdiction} - Existing Multifamily and Nonresidential Building Data`],
        [`GENERATED BY: Cost-Effectiveness Explorer, Exported ${formattedDate}. https://explorer.localenergycodes.com/jurisdiction/sacramento-city/building-estimates`],
        [
          "DATA SOURCE: National Renewable Energy Laboratory. (2020). City and County Commercial Building Inventories '2019 Commercial Building Inventory - West.xlsb'.  https://dx.doi.org/10.25984/1788089."
        ],
        ['METHODOLOGY: https://intercom.help/explorer-local-energy-codes/en/articles/10236236-existing-nonresidential-building-data'],
        [''],
        [''],
        ['Table1 - Summary of Existing Multifamily and Nonresidential Buildings'],
        ['']
      ]

      const tableData = []

      const thead = table.querySelector('thead')
      if (thead) {
        thead.querySelectorAll('tr').forEach((row) => {
          const rowData = []
          row.querySelectorAll('th').forEach((th) => {
            let text = th.innerText.trim().replace('info_outline', '').trim()
            rowData.push(text)
          })
          tableData.push(rowData)
        })
      }

      const tbody = table.querySelector('tbody')
      if (tbody) {
        tbody.querySelectorAll('tr').forEach((row) => {
          const rowData = []
          row.querySelectorAll('td').forEach((td) => {
            rowData.push(td.innerText.trim())
          })
          tableData.push(rowData)
        })
      }

      const tfoot = table.querySelector('tfoot')
      if (tfoot) {
        tfoot.querySelectorAll('tr').forEach((row) => {
          const rowData = []
          row.querySelectorAll('th').forEach((th) => {
            let text = th.innerText.trim()
            if (text === 'All') {
              text = 'All Types'
            }
            rowData.push(text)
          })
          tableData.push(rowData)
        })
      }

      let column4HasOnlyZeros = true
      let column5HasOnlyZeros = true

      for (let i = 1; i < tableData.length; i++) {
        const row = tableData[i]

        if (row.length > 3 && row[3] && row[3] !== '0') {
          column4HasOnlyZeros = false
        }

        if (row.length > 4 && row[4] && row[4] !== '0') {
          column5HasOnlyZeros = false
        }
      }

      const filteredTableData = tableData.map((row) => {
        const newRow = [...row]

        if (column5HasOnlyZeros && newRow.length > 4) {
          newRow.splice(4, 1)
        }

        if (column4HasOnlyZeros && newRow.length > 3) {
          newRow.splice(3, 1)
        }

        return newRow
      })

      const allData = [...infoData, ...filteredTableData]

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('About and Table Data')

      worksheet.addRows(allData)

      updateColumnLabels(worksheet, selectedAreaLabel)

      worksheet.getColumn(1).width = 30

      const maxCols = allData.reduce((max, row) => Math.max(max, row.length), 0)
      for (let i = 2; i <= maxCols; i++) {
        worksheet.getColumn(i).width = 23
      }

      const tableStartRowIndex = infoData.length + 1
      const tableEndRowIndex = allData.length
      const borderOnlyRowIndex = tableEndRowIndex - 1

      worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.font = { name: 'Arial', size: 10 }
        })
      })

      const titleCell = worksheet.getCell('A1')
      titleCell.font = {
        name: 'Arial',
        size: 12,
        bold: true
      }

      const tableHeaderCell = worksheet.getCell('A7')
      tableHeaderCell.font = {
        name: 'Arial',
        size: 11,
        underline: true
      }

      const explorerLinkCell = worksheet.getCell('A2')
      const explorerText = explorerLinkCell.value
      const explorerUrl = 'https://explorer.localenergycodes.com/jurisdiction/sacramento-city/building-estimates'
      const explorerPrefix = 'GENERATED BY: Cost-Effectiveness Explorer, Exported ' + formattedDate + '. '

      explorerLinkCell.value = {
        richText: [
          { text: explorerPrefix, font: { name: 'Arial', size: 10 } },
          {
            text: explorerUrl,
            font: { name: 'Arial', size: 10, color: { argb: 'FF0000FF' }, underline: true },
            hyperlink: explorerUrl
          }
        ]
      }

      const dataSourceLinkCell = worksheet.getCell('A3')
      const dataSourceText =
        "DATA SOURCE: National Renewable Energy Laboratory. (2020). City and County Commercial Building Inventories '2019 Commercial Building Inventory - West.xlsb'.  "
      const dataSourceUrl = 'https://dx.doi.org/10.25984/1788089'

      dataSourceLinkCell.value = {
        richText: [
          { text: dataSourceText, font: { name: 'Arial', size: 10 } },
          {
            text: dataSourceUrl,
            font: { name: 'Arial', size: 10, color: { argb: 'FF0000FF' }, underline: true },
            hyperlink: dataSourceUrl
          }
        ]
      }

      const methodologyLinkCell = worksheet.getCell('A4')
      const methodologyText = 'METHODOLOGY: '
      const methodologyUrl = 'https://intercom.help/explorer-local-energy-codes/en/articles/10236236-existing-nonresidential-building-data'

      methodologyLinkCell.value = {
        richText: [
          { text: methodologyText, font: { name: 'Arial', size: 10 } },
          {
            text: methodologyUrl,
            font: { name: 'Arial', size: 10, color: { argb: 'FF0000FF' }, underline: true },
            hyperlink: methodologyUrl
          }
        ]
      }

      for (let r = tableStartRowIndex; r <= tableEndRowIndex; r++) {
        const row = worksheet.getRow(r)
        const isHeaderOrFooter = r === tableStartRowIndex || r === tableEndRowIndex
        const shouldHaveBorder = r === borderOnlyRowIndex

        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          if (shouldHaveBorder) {
            cell.border = {
              top: { style: 'none' },
              left: { style: 'none' },
              bottom: { style: 'thin' },
              right: { style: 'none' }
            }
          } else {
            cell.border = {
              top: { style: 'none' },
              left: { style: 'none' },
              bottom: { style: 'none' },
              right: { style: 'none' }
            }
          }

          if (isHeaderOrFooter) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFF3F3F3' }
            }
          }

          if (r === tableStartRowIndex) {
            cell.font = {
              name: 'Arial',
              size: 10,
              bold: true
            }
          }

          if (r === tableStartRowIndex || colNumber === 1) {
            cell.alignment = { horizontal: 'left', vertical: 'middle' }
          } else {
            cell.alignment = { horizontal: 'right', vertical: 'middle' }
          }
        })
      }

      exportRawBuildingLevelData(workbook)
        .then(() => {
          const rawDataSheet = workbook.getWorksheet('Raw Building Level Data')
          if (rawDataSheet) {
            rawDataSheet.eachRow({ includeEmpty: true }, (row) => {
              row.eachCell({ includeEmpty: true }, (cell) => {
                const isBold = cell.font?.bold
                cell.font = {
                  name: 'Arial',
                  size: 10,
                  bold: isBold || false
                }
              })
            })
          }

          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Existing_Buildings_Non_Residential.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
            resolve()
          })
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    } catch (error) {
      console.error('Error exporting XLS:', error)
      reject(error)
    }
  })
}
