import GraphQL from '@/util/GraphQL'
import * as sha1 from 'sha1'
import asyncCacheResolver from '@/util/asyncCacheResolver'
import { GA_LABELS, gTagEvent } from '@/mixins/GaEventsMixin'
import Store from '@/store'

export default class HelperApiService {

  static async get({ type, id = null, slug = null, avoidAnalyticsEvent = false }) {
    let where = ''
    if (slug) where += `${!where ? `where` : `,orWhere`}: [{column: "slug", operation: "=", value: "${slug}"}]`
    if (id) where += `${!where ? `where` : `,orWhere`}: [{column: "id", operation: "=", value: "${id}"}]`

    const query = `
      {
        ${type} (
          ${where}
        ) {
          id
          title
          ${type === 'study' ? '': 'description'} 
          content
          ${type !== 'study' ? `slug` : ``}
          ${type === 'page' ? 'subtitle' : ''}
        }
      }
    `

    const cacheKey = sha1(query)
    const result = await asyncCacheResolver(cacheKey, async () => {
      const { data } = await GraphQL({ query }).catch(() => ({data: {}}))
      return data?.[type] || []
    })

    if (avoidAnalyticsEvent !== true) {
      gTagEvent(GA_LABELS.INFO_HELP_CLICK, {
        helper_type: type,
        helper_id: id || undefined,
        helper_slug: slug || undefined,
        jurisdiction_slug: Store.getters?.['lastJurisdictionVisited']?.slug || 'undefined',
      })
    }

    return result
  }
}
