import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType'
import { BUILDING_TYPES_DB_SLUGS }from '@/store/global-entities/BuildingType.js'
import { BUILDING_TYPE_DB_SLUGS, POLICY_TYPE_PDF } from '@/util/Enums.js'

import BasePdfService from '@/services/pdf/BasePdfService'
import html2canvas from "html2canvas"

export default class BuildingEstimatesGeneratePdfService extends BasePdfService {

  fontSizeDefault = 10
  type_prototypes = []
  prototypes = []
  elemsToImage = []
  html2canvasOptions = {}
  _pageImages = []
  building_type = false
  studyType = null
  updateStateCallback = null

  type = POLICY_TYPE_PDF.BUILDING_ESTIMATES  

  constructor(elemsToImage, updateStateCallback, args, building_type, studyType) {
    super(args)
    this.elemsToImage = elemsToImage
    this.updateStateCallback = updateStateCallback
    if (args.html2canvasOptions) {
      this.html2canvasOptions = args.html2canvasOptions
    }
    if(building_type){
      this.building_type = building_type
    }
    if (studyType) {
      this.studyType = studyType
    }
    return this.initPdf()
  }
  
  async initPdf() {
    try {
      this._pageImages = await this.getPageImageElements()
      if (!this._pageImages || !Array.isArray(this._pageImages) || this._pageImages.length <= 0) {
        throw new Error('No page images informed')
      }
      await this.addImagesAsPages({images: this._pageImages})
      await this.export()
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  }

  async getPageImageElements() {
    if (this.elemsToImage.length <= 0) {
      throw new Error('no-elements-to-export')
    }

    const placeholderClones = []
    this.elemsToImage.forEach(el => {
      const clone = el.cloneNode(true)
      el.parentNode.insertBefore(clone, el.nextSibling)
      // el.parentElement.insertBefore(clone, el)
      placeholderClones.push(clone)
    })

    const steps = []
    if (!this.studyType || this.studyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
      steps.push(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)
    }
    if (!this.studyType || this.studyType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
      steps.push(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)
    }

    let results = []
    for await (const step of steps) {
      results = [...results, ...(await this.getStepImages(step, step === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 1 : 2))]
    }

    if (this.updateStateCallback) {
      this.updateStateCallback(false)
    }
    placeholderClones.forEach(clone => {
      clone.remove()
    })
    this.elemsToImage.forEach((el) => {
      const customWidth = el.getAttribute('attr-export-width')
      if (customWidth) {
        el.style.width = null
      }
      el.classList.remove('exporting-to-pdf')
    })
    return results
  }

  getStepImages(step,idx) {
    return new Promise((resolve, reject) => {
      if (this.updateStateCallback) {
        this.updateStateCallback(true, step)
      }

      const fixElemTemplates = () => {
        return new Promise((resolve) => {
          let times = 40
          const fixFunc = () => {
            this.elemsToImage.forEach(el => {
              el.classList.add('exporting-to-pdf')
              const customWidth = el.getAttribute('attr-export-width')
              if (customWidth) {
                el.style.width = customWidth
              }
            })
          }
          fixFunc()
          const interval = setInterval(async () => {
            fixFunc()
            times--
            if (times <= 0) {
              clearInterval(interval)
              resolve()
            }
          }, 40)
        })
      }

      fixElemTemplates().then(() => {
        setTimeout(() => {
          const promises = []
          this.elemsToImage.forEach((el) => {
            const exportSteps = el.getAttribute('attr-export-steps')?.split(',')?.map((i) => Number(i)) || null
            const studyTypeFilter = el.getAttribute('attr-export-study-type')?.split(',') || null
            if ((!exportSteps || exportSteps?.includes(idx)) && (!studyTypeFilter || studyTypeFilter?.includes(step))) {
              const options = { ...this.html2canvasOptions }
              const image = html2canvas(el, options).then(canvas => canvas.toDataURL())
              promises.push(image)
            }
          })
          return Promise.all(promises).then((result) => {
            resolve(result)
          }).catch(reject)
        }, 3000)
      })
    })
  }

  addDocumentGeneratedInfo() {
    if (this.studyType !== STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS || this.building_type?.slug !== BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL) {
      return super.addDocumentGeneratedInfo()
    }

    this.doc.setFont('Lato-Bold')
    this.doc.setFontSize(11)
    this.doc.setTextColor(this.colors.gray02)
    this.doc.text(`Data Source:`, 30, this.pageHeight  - 137)

    this.doc.setFont('Lato-Regular')
    this.doc.text(`National Renewable Energy Laboratory. (2020). City and County Commercial Building Inventories '2019 Commercial Building Inventory - West.xlsb'.`, 30, this.pageHeight - 125)
    this.doc.text(`Retrieved from`, 30, this.pageHeight - 113)
    this.doc.setTextColor(this.colors.blue02)
    this.doc.textWithLink('https://dx.doi.org/10.25984/1788089', 87, this.pageHeight - 113, { url: 'https://dx.doi.org/10.25984/1788089' })


    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.text(this.generatedInfoText, 30, this.pageHeight - 89)

    this.doc.setFont('Lato-Regular')
    this.doc.setTextColor(this.colors.blue02)
    this.doc.textWithLink(this.sourceUrl, 30, this.pageHeight - 77, { url: this.sourceUrl })
  }

}
