import BasePdfPortraitService from '@/services/pdf/BasePdfPortraitService'
import Policy from '@/models/Policy'
import MainVuex from '@/store'
import Api from '@/util/Api'
import autoTable from 'jspdf-autotable'
import { POLICY_TYPE_PDF } from '@/util/Enums.js'
import { getPolicyCustomCombinationsBasedOnFlexiblePathSetupIfNeeded } from '@/util/Functions.js'
import { jurisdictionTitleTypeBasedOnJurisdictionType } from '@/util/Helpers.js'

export default class PolicyFlexibleCompliancePdf extends BasePdfPortraitService {
  policy_id = null
  study_types = []
  studies = []
  type_prototypes = []
  prototypes = []
  vintages = []
  fuels = []
  policy = new Policy()
  tables = []
  policyType = ''
  is_flexible_set_up = null
  flexible_path_setup = {}

  type = POLICY_TYPE_PDF.FLEXIBLE_COMPLIANCE_TABLES
  headerTitle = POLICY_TYPE_PDF.REQUIREMENTS_SUMMARY
  headerSubtitle = POLICY_TYPE_PDF.FLEXIBLE_COMPLIANCE_TABLES

  fontSizeDefault = 10
  fontSizeTableHeader = 9
  fontSizeTableBody = 9
  cellPaddingsTable = { top: 3, right: 5, left: 5, bottom: 2 }
  startYPositionPageTitle = this.margins.top + 18
  startYPositionTable = this.startYPositionPageTitle + 7
  endYPositionTable = this.margins.bottom

  constructor(args) {
    super(args)
    this.policy_id = args.policy_id
    this.policyType = args.policyType
    this.is_flexible_set_up = args.is_flexible_set_up
    this.flexible_path_setup = args.flexible_path_setup
    return this.initPdf()
  }

  setPdfArgs() {
    this.setPdfTitle()
    this.setPdfSubtitle()
    this.setPdfSources()
  }

  setPdfTitle() {
    this.title = jurisdictionTitleTypeBasedOnJurisdictionType(this.policy.jurisdiction, 'title_type', null, true)
    if (this.title.length >= 48) {
      const words = this.title.split(' ')
      let currentLine = ''
      let lines = []
      for (const word of words) {
        if ((currentLine + ' ' + word).length <= 48) {
          currentLine += ' ' + word
        } else {
          lines.push(currentLine.trim())
          currentLine = word
        }
      }
      lines.push(currentLine.trim())
      this.title = lines.join('\n')
    }

    this.pageHeaderTitle = this.policy.jurisdiction?.titles.title_type
  }

  setPdfSubtitle() {
    const { title } = this.policy
    this.subtitle = title.length > 60 ? title.slice(0, 58) + '...' : title
    this.pageHeaderSubtitle = title

    this.headline = `Requirements Summary Flexible Compliance Tables`
  }

  setPdfSources() {
    let policy_custom_combinations = getPolicyCustomCombinationsBasedOnFlexiblePathSetupIfNeeded(this.policy_id, this.flexible_path_setup)

    this.prototypes = MainVuex.getters['globalEntities/Prototype/getterGlobalPrototypes']({
      id: policy_custom_combinations.map((combination) => combination.prototype_id || combination.prototype.id)
    })

    this.prototypes.forEach((prototype) => {
      const study = MainVuex.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
      if (!this.sources.findInArray({ id: study.id })) this.registerSourceStudy(study.id)
    })
  }

  async loadXlsResources() {
    const { data } = await Api.get(`/api/xls/get_content?type=MeasureMenuXls&policy_id=${this.policy_id}`)
    this.xls_resources = data.xls_resources
  }

  async initPdf() {
    try {
      await this.getPolicy()
      await this.loadXlsResources()
      this.setPdfArgs()
      this.generateTables()
      await this.export()
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  }

  async getPolicy() {
    const policy = MainVuex.getters['policy/getterUserPolicies']({ id: this.policy_id })[0]
    this.policy = new Policy(policy)
  }

  renderTableHeader(buildingType, climateZone, measureCount, continued = false) {
    this.doc.setFont('Lato-Bold')
    this.doc.setFontSize(11)
    this.doc.setTextColor(this.colors.blue80)
    const titleText = 'Available Measures and Scores'
    const titleWidth = this.doc.getTextWidth(titleText)
    this.doc.text(titleText, this.margins.left, this.startYPositionPageTitle)

    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(11)
    this.doc.setTextColor(this.colors.blue80)
    this.doc.text(`(Existing ${buildingType} in ${climateZone})`, this.margins.left + +titleWidth + 2, this.startYPositionPageTitle)
  }

  getTableBody(items) {
    return items.flatMap((item) => {
      const rows = []
      rows.push([item.title, ...item.values])
      return rows
    })
  }

  async generateTables() {
    let lastClimateZone = null
    let lastBuildingType = null

    for (let i = 0; i < this.xls_resources.contentTables.length; i += 2) {
      const targetScoreTable = this.xls_resources.contentTables[i]
      const measureMenuTable = this.xls_resources.contentTables[i + 1]

      const [buildingType, climateZone] = measureMenuTable.subtitle.split('-').map((part) => part.trim())

      if ((lastClimateZone && climateZone !== lastClimateZone) || (lastBuildingType && buildingType !== lastBuildingType)) {
        this.addPage()
      }

      lastClimateZone = climateZone
      lastBuildingType = buildingType

      this.renderTableHeader(buildingType, climateZone, measureMenuTable.items.length)

      const head = [
        [
          {
            content: 'Building Vintage',
            styles: { halign: 'left' }
          },
          ...targetScoreTable.columns
        ],
        [
          {
            content: 'Required Flexible Score',
            styles: { halign: 'left' }
          },
          ...targetScoreTable.items[0].values
        ]
      ]

      const body = this.getTableBody(measureMenuTable.items)

      this.renderTableSinglePage(head, body, buildingType, climateZone, measureMenuTable.items.length)
    }
  }

  calculateTextHeight(text, columnWidth, fontSize) {
    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(fontSize)
    const textString = String(text)

    const words = textString.split(' ')

    let currentLineWidth = 0
    let numberOfLines = 1

    const scalingFactor = 1 // 1 / 0.75

    for (let i = 0; i < words.length; i++) {
      const word = words[i]
      const wordDimensions = this.doc.getTextDimensions(word)
      const correctedWordWidth = wordDimensions.w * scalingFactor

      if (currentLineWidth === 0) {
        currentLineWidth = correctedWordWidth
      } else {
        const spaceWidth = this.doc.getTextDimensions(' ').w * scalingFactor
        if (currentLineWidth + spaceWidth + correctedWordWidth <= columnWidth) {
          currentLineWidth += spaceWidth + correctedWordWidth
        } else {
          numberOfLines++
          currentLineWidth = correctedWordWidth
        }
      }
    }

    return numberOfLines * fontSize
  }

  calculateRowHeight(row, columnWidth, fontSize) {
    const cellPadding = this.cellPaddingsTable
    const totalYPaddings = cellPadding.top + cellPadding.bottom
    const firstCellContent = typeof row[0] === 'object' ? row[0].content : String(row[0])
    const columnWidthLessPadding = columnWidth - (cellPadding.left + cellPadding.right)

    if (typeof firstCellContent === 'string') {
      const textHeight = this.calculateTextHeight(firstCellContent, columnWidthLessPadding, fontSize)
      return textHeight + totalYPaddings
    }
  }

  renderTableSinglePage(head, body, buildingType, climateZone, measureCount) {
    const pageWidth = this.doc.internal.pageSize.getWidth()
    const pageHeight = this.doc.internal.pageSize.getHeight()
    const availableWidth = pageWidth - this.margins.left - this.margins.right
    const availableHeight = pageHeight - this.startYPositionTable + 15

    const baseMargin = {
      top: this.margins.top,
      left: this.margins.left,
      right: this.margins.right,
      bottom: this.endYPositionTable
    }

    const baseTableConfig = {
      theme: 'grid',
      margin: baseMargin,
      headStyles: {
        font: 'Lato-Bold',
        fontSize: this.fontSizeTableHeader,
        textColor: this.colors.gray80,
        lineWidth: 0.25,
        lineColor: this.colors.gray30,
        fillColor: this.colors.gray30,
        halign: 'center',
        valign: 'middle'
      },
      bodyStyles: {
        font: 'Lato-Regular',
        fontSize: this.fontSizeTableBody,
        textColor: this.colors.gray80,
        lineWidth: 0.25,
        lineColor: this.colors.gray30,
        halign: 'center',
        valign: 'middle'
      },
      styles: {
        cellPadding: this.cellPaddingsTable
      },
      columnStyles: {
        0: {
          halign: 'left',
          cellWidth: 'wrap'
        }
      },
      didParseCell: (data) => {
        if (data.column.index === 0) {
          data.cell.styles.cellPadding = { ...data.cell.styles.cellPadding, left: 10 }
          data.cell.styles.cellWidth = 240
        }

        if (data.section === 'head') {
          if (data.row.index === 1) {
            data.cell.styles.fillColor = this.colors.gray10

            if ([1, 2, 3].includes(data.column.index)) {
              data.cell.styles.font = 'Lato-Regular'
            }
          }
        }
      }
    }

    const calculateHeights = () => {
      const headerHeights = head.reduce((sum, row) => sum + this.calculateRowHeight(row, availableWidth, this.fontSizeTableHeader), 0)
      const bodyHeights = body.map((row) => this.calculateRowHeight(row, availableWidth, this.fontSizeTableBody))
      return { headerHeights, bodyHeights }
    }

    const { headerHeights, bodyHeights } = calculateHeights()
    const totalHeightFull = headerHeights + bodyHeights.reduce((a, b) => a + b, 0)

    if (totalHeightFull <= availableHeight) {
      this.doc.autoTable({
        ...baseTableConfig,
        cellWidth: availableWidth,
        startY: this.startYPositionTable,
        head: head,
        body: body
      })
      return
    }

    const availableBodyHeight = availableHeight - headerHeights
    let accumulatedHeight = 0
    let rowsThatFit = 0

    for (let i = 0; i < body.length; i++) {
      const rowHeight = bodyHeights[i]
      if (accumulatedHeight + rowHeight <= availableBodyHeight) {
        accumulatedHeight += rowHeight
        rowsThatFit++
      } else {
        break
      }
    }

    const currentPageBody = body.slice(0, rowsThatFit)
    const remainingBody = body.slice(rowsThatFit)

    this.doc.autoTable({
      ...baseTableConfig,
      cellWidth: availableWidth,
      startY: this.startYPositionTable,
      head: head,
      body: currentPageBody
    })

    if (remainingBody.length > 0) {
      const modifiedHead = JSON.parse(JSON.stringify(head))
      const sufix = '  (Continued)'
      if (!modifiedHead[0][0].content.includes(sufix)) {
        modifiedHead[0][0].content += sufix
      }
      this.addPage()
      this.renderTableHeader(buildingType, climateZone, measureCount, true)
      this.renderTableSinglePage(modifiedHead, remainingBody, buildingType, climateZone, measureCount)
    }
  }

  renderTableInTwoColumns(head, body, buildingType, climateZone, measureCount) {
    const pageWidth = this.doc.internal.pageSize.getWidth()
    const pageHeight = this.doc.internal.pageSize.getHeight()
    const availableWidth = pageWidth - this.margins.left - this.margins.right
    const availableHeight = pageHeight - this.startYPositionTable // do not subtract this.margins.bottom

    const baseMargin = {
      top: this.margins.top,
      left: this.margins.left,
      right: this.margins.right,
      bottom: this.endYPositionTable
    }

    const baseTableConfig = {
      theme: 'grid',
      margin: baseMargin,
      headStyles: {
        font: 'Lato-Bold',
        fontSize: this.fontSizeTableHeader,
        textColor: this.colors.gray80,
        lineWidth: 0.25,
        lineColor: this.colors.gray20,
        fillColor: this.colors.gray30,
        halign: 'center',
        valign: 'middle'
      },
      bodyStyles: {
        font: 'Lato-Regular',
        fontSize: this.fontSizeTableBody,
        textColor: this.colors.gray80,
        lineWidth: 0.25,
        lineColor: this.colors.gray20,
        halign: 'center',
        valign: 'middle'
      },
      styles: {
        cellPadding: this.cellPaddingsTable
      },
      columnStyles: {
        0: {
          halign: 'left'
        }
      },
      didParseCell: (data) => {
        if (data.section === 'head') {
          if (data.row.index === 1 && [1, 2, 3].includes(data.column.index)) {
            data.cell.styles.font = 'Lato-Regular'
          }

          if (data.row.index === 2) {
            data.cell.styles.font = 'Lato-Regular'
            data.cell.styles.fontSize = this.fontSizeTableHeader
            data.cell.styles.textColor = this.colors.gray80
            data.cell.styles.lineWidth = 0.25
            data.cell.styles.lineColor = this.colors.gray20
            data.cell.styles.fillColor = '#ffffff'
            data.cell.styles.valign = 'middle'
          }
        }
        // if (data.section === 'body') {}
      }
    }

    const firstFullTableColumnWidth = 200
    const headerHeights = head.reduce((sum, row) => sum + this.calculateRowHeight(row, firstFullTableColumnWidth, this.fontSizeTableHeader), 0)
    const bodyHeights = body.map((row) => this.calculateRowHeight(row, firstFullTableColumnWidth, this.fontSizeTableBody))
    const totalHeightFull = headerHeights + bodyHeights.reduce((sum, h) => sum + h, 0)

    if (totalHeightFull <= availableHeight) {
      this.doc.autoTable({
        ...baseTableConfig,
        cellWidth: availableWidth,
        startY: this.startYPositionTable,
        head: head,
        body: body,
        columnStyles: {
          0: {
            cellWidth: firstFullTableColumnWidth,
            halign: 'left'
          }
        }
      })
      return
    }

    const firstDividedTableColumnWidth = 70
    const columnGap = 10
    const columnWidth = (availableWidth - columnGap) / 2
    const headerHeightColumn = head.reduce((sum, row) => {
      return sum + this.calculateRowHeight(row, firstDividedTableColumnWidth, this.fontSizeTableHeader)
    }, 0)

    let leftColumnHeight = headerHeightColumn
    let leftRowsCount = 0
    for (const row of body) {
      const rowHeight = this.calculateRowHeight(row, firstDividedTableColumnWidth, this.fontSizeTableBody)

      if (leftColumnHeight + rowHeight <= availableHeight) {
        leftColumnHeight += rowHeight
        leftRowsCount++
      } else {
        break
      }
    }

    let rightColumnHeight = headerHeightColumn
    let rightRowsCount = 0
    const remainingRows = body.slice(leftRowsCount)

    for (const row of remainingRows) {
      const rowHeight = this.calculateRowHeight(row, firstDividedTableColumnWidth, this.fontSizeTableBody)
      if (rightColumnHeight + rowHeight <= availableHeight) {
        rightColumnHeight += rowHeight
        rightRowsCount++
      } else {
        break
      }
    }

    const leftColumnRows = body.slice(0, leftRowsCount)
    const rightColumnRows = remainingRows.slice(0, rightRowsCount)
    const nextPageRows = remainingRows.slice(rightRowsCount)

    this.doc.autoTable({
      ...baseTableConfig,
      startY: this.startYPositionTable,
      cellWidth: columnWidth,
      head: head,
      body: leftColumnRows,
      columnStyles: {
        0: { cellWidth: firstDividedTableColumnWidth, halign: 'left' }
      }
    })

    this.doc.lastAutoTable = { finalY: this.margins.top }
    this.doc.autoTable.previous = { finalY: this.margins.top }

    let rightHead = JSON.parse(JSON.stringify(head))

    const rightColumnX = this.margins.left + columnWidth + columnGap

    this.doc.autoTable({
      ...baseTableConfig,
      startY: this.startYPositionTable,
      cellWidth: columnWidth,
      head: rightHead,
      body: rightColumnRows,
      pageBreak: 'avoid',
      margin: {
        ...baseMargin,
        left: rightColumnX
      },
      columnStyles: {
        0: { cellWidth: firstDividedTableColumnWidth, halign: 'left' }
      }
    })

    if (nextPageRows.length > 0) {
      const sufix = '  (Continued)'
      const modifiedHead = JSON.parse(JSON.stringify(head))

      if (!modifiedHead[0][0].content.includes(sufix)) {
        modifiedHead[0][0].content += sufix
      }
      this.addPage()
      this.renderTableHeader(buildingType, climateZone, measureCount, true)
      this.renderTableInTwoColumns(modifiedHead, nextPageRows, buildingType, climateZone, measureCount)
    }
  }

  async addTables() {
    let lastClimateZone = null
    let lastBuildingType = null

    for (const contentTable of this.xls_resources.contentTables) {
      const currentClimateZone = contentTable.subtitle.split('-')[1].trim()
      const currentBuildingType = contentTable.subtitle.split('-')[0].trim()

      if ((lastClimateZone && currentClimateZone !== lastClimateZone) || (lastBuildingType && currentBuildingType !== lastBuildingType)) {
        this.addPage()
      }

      lastClimateZone = currentClimateZone
      lastBuildingType = currentBuildingType

      const head = [
        [
          {
            content: contentTable.pdfTableTitle,
            colSpan: 4,
            styles: { fontSize: 12, font: 'Lato-Bold', fillColor: '#FFF', halign: 'left', textColor: this.colors.blue80, lineWidth: 0, cellPadding: { top: 4, bottom: 8 } }
          }
        ],
        [{ content: contentTable.pdfTableHeaderFirstColumn, styles: { halign: 'left' } }, ...contentTable.columns]
      ]
      const body = contentTable.items.map((item) => {
        return [item.title, ...item.values]
      })

      autoTable(this.doc, this.getTableOptions({ body, head }))
    }
  }

  getTableOptions({ body, head }) {
    return {
      theme: 'plain',
      body,
      head,
      cellWidth: 'auto',
      headStyles: {
        fillColor: this.colors.gray10,
        lineColor: this.colors.gray20,
        lineWidth: 0.25,
        halign: 'center',
        font: 'Lato-Bold',
        textColor: this.colors.gray80,
        columnStyles: {
          0: { halign: 'left' }
        }
      },
      bodyStyles: {
        lineColor: this.colors.gray20,
        lineWidth: 0.25,
        halign: 'center',
        textColor: this.colors.gray80
      },
      styles: {
        font: 'Lato-Regular',
        fontSize: 9,
        lineColor: this.colors.gray20,
        textColor: this.colors.gray80
      },

      columnStyles: {
        0: { halign: 'left', minCellWidth: 90 }
      },
      margin: { ...this.margins, top: this.margins.top + 40 },
      willDrawCell: (data) => {
        this.onPolicyFlexibleComplianceTableBreak(data)
      },
      didDrawPage: () => {
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(16)
        this.doc.setFont('Lato-Regular')
        this.doc.text(`Requirements Summary - Flexible Compliance Tables`, this.margins.left, this.margins.top + 30, { baseline: 'bottom' })
      }
    }
  }

  onPolicyFlexibleComplianceTableBreak(data) {
    if (data.pageCount > 1) {
      const sufix = '  (Continued)'
      const currentTitle = data.table.head[1].cells[0].text[0]
      const modifiedTitle = data.table.head[1].cells[0].text + sufix

      if (currentTitle.indexOf(sufix) === -1) {
        data.table.head[1].cells[0].text = [modifiedTitle]
      }
    }
  }

  // Hiding info page for now: https://epcgo.atlassian.net/browse/DDEV-1996
  // async addInfoPage() {

  //   this.addPage()

  //   const { pageNumber } = this.doc.internal.getCurrentPageInfo()
  //   this.doc.movePage(pageNumber, 2)

  //   this.doc.setTextColor(this.colors.blue80)
  //   this.doc.setFontSize(16)
  //   this.doc.setFont('Lato-Regular')
  //   this.doc.text(`Requirements Summary - Flexible Compliance Tables`, this.margins.left, this.margins.top + 30, { baseline : 'bottom' })

  //   const aboutContentItemKeysToMap = [
  //     'about',
  //     'what_is_included',
  //     'intended_use_for_ordinance',
  //     'intended_use_for_policy_makers',
  //     'notes_on_building_vintage'
  //   ]

  //   let currentTextPosition = this.margins.top + 50
  //   for(const key of aboutContentItemKeysToMap) {
  //     const item = ArrayHelpers.find(this.xls_resources.aboutContent.items, { key })

  //     this.doc.setTextColor(this.colors.blue80)
  //     this.doc.setFont('Lato-Bold')
  //     this.doc.setFontSize(12)
  //     this.doc.text(item.title, this.margins.left, currentTextPosition)

  //     currentTextPosition += 15
  //     this.doc.setFont('Lato-Regular')
  //     this.doc.setFontSize(9)
  //     this.doc.setTextColor(this.colors.gray02)

  //     const { h: height } = this.doc.getTextDimensions(item.pdfContent || item.content, { maxWidth: 420 })
  //     this.doc.text(item.pdfContent || item.content, this.margins.left, currentTextPosition, { maxWidth: 420 })

  //     currentTextPosition += height + 10
  //   }

  //   const boxWidth = 150
  //   const boxHeight = 260
  //   const boxX = 460
  //   const boxY = this.margins.top + 50

  //   this.doc.setFillColor(this.colors.blue20)
  //   this.doc.rect(boxX, boxY, boxWidth, boxHeight, 'F')

  //   var currentY = boxY + 24
  //   this.doc.setFontSize(12)
  //   this.doc.setTextColor(this.colors.blue80)
  //   this.doc.setFont('Lato-Bold')
  //   this.doc.setFontSize(12)
  //   this.doc.text('How to use the tables', boxX + 12, currentY)

  //   currentY+= 20
  //   this.doc.setFontSize(10)
  //   this.doc.setTextColor(this.colors.blue60)
  //   this.doc.setFont('Lato-Bold')
  //   this.doc.text('Target Scores Table', boxX + 12, currentY)

  //   currentY+= 15
  //   this.doc.setFontSize(10)
  //   this.doc.setTextColor(this.colors.gray02)
  //   this.doc.setFont('Lato-Regular')
  //   this.doc.text('This table shows the minimum point score that covered units must earn to meet the ordinance requirements. The applicable minimum score depends on the building vintage. Covered units earn points for each measure they choose to install from Measure Menu Table.', boxX + 12, currentY, { maxWidth: 126 })

  //   currentY+= 80
  //   this.doc.setFontSize(10)
  //   this.doc.setTextColor(this.colors.blue60)
  //   this.doc.setFont('Lato-Bold')
  //   this.doc.text('Measure Menu Table', boxX + 12, currentY)

  //   currentY+= 15
  //   this.doc.setFontSize(10)
  //   this.doc.setTextColor(this.colors.gray02)
  //   this.doc.setFont('Lato-Regular')
  //   this.doc.text(`This table lists the available measures to install and their point values.  Covered units must choose from among these to comply with ordinance requirements. Each measure has a specific point value depending on the building vintage. Measures designated as 'Mandatory' must be installed. Mandatory measures may not be substituted and do not earn a point value.`, boxX + 12, currentY, { maxWidth: 126 })

  //   this.addPageGrid()
  // }

  async addSecondPage() {
    this.addPage()
    const { pageNumber } = this.doc.internal.getCurrentPageInfo()
    this.doc.movePage(pageNumber, 2)

    let pageTitleFontSize = 15
    let titleFontSize = 11
    let textFontSize = 8
    let maxWidth = this.pageWidth - (this.margins.left + this.margins.right)
    let text = ''
    let policy = {}
    let splitText

    const startYPositionContent = this.margins.top + 30

    this.doc.setTextColor(this.colors.blue80)
    this.doc.setFontSize(pageTitleFontSize)
    this.doc.setFont('Lato-Bold')
    this.doc.text(this.headline, this.margins.left, startYPositionContent, { baseline: 'bottom' })

    if (this.policy) {
      // First paragraph
      this.doc.setTextColor(this.colors.blue80)
      this.doc.setFontSize(titleFontSize)
      this.doc.setFont('Lato-Bold')
      this.doc.text(`About this document`, this.margins.left, startYPositionContent + 25, { baseline: 'bottom' })

      this.doc.setTextColor(this.colors.gray02)
      this.doc.setFontSize(textFontSize)
      this.doc.setFont('Lato-Regular')
      text = `This document features tables from the policy you crafted using the Cost-Effectiveness Explorer. These tables outline the criteria for a flexible compliance policy. This is designed to allow permit applicants to choose measures that work best for them from a qualified list.`
      splitText = this.doc.splitTextToSize(text, maxWidth)
      this.doc.text(splitText, this.margins.left, startYPositionContent + 35, { baseline: 'bottom' })

      // Second paragraph
      this.doc.setTextColor(this.colors.blue80)
      this.doc.setFontSize(titleFontSize)
      this.doc.setFont('Lato-Bold')
      this.doc.text(`What is included`, this.margins.left, startYPositionContent + 60, { baseline: 'bottom' })

      this.doc.setTextColor(this.colors.gray02)
      this.doc.setFontSize(textFontSize)
      this.doc.setFont('Lato-Regular')
      text = 'This document includes tables on the following prototype and climate zone combinations:\n'
      policy = this.policy
      for (let container of policy.policy_containers) {
        let prototypeTitle = container.custom_combinations[0].prototype.title
        let climateZones = policy.jurisdiction.climate_zones.map((zone) => zone.prefix)

        if (climateZones.length > 1) {
          let lastZone = climateZones.pop()
          text += `${prototypeTitle}: Climate Zones ${climateZones.join(', ')} and ${lastZone}. `
        } else {
          text += `${prototypeTitle}: Climate Zone ${climateZones[0]}. `
        }
      }
      this.doc.setTextColor(this.colors.gray02)
      this.doc.setFontSize(textFontSize)
      this.doc.setFont('Lato-Regular')
      splitText = this.doc.splitTextToSize(text, maxWidth)
      this.doc.text(splitText, this.margins.left, startYPositionContent + 70, { baseline: 'bottom' })

      // Third paragraph
      this.doc.setTextColor(this.colors.blue80)
      this.doc.setFontSize(titleFontSize)
      this.doc.setFont('Lato-Bold')
      this.doc.text(`Intended use for ordinance compliance`, this.margins.left, startYPositionContent + 95, { baseline: 'bottom' })

      this.doc.setTextColor(this.colors.gray02)
      this.doc.setFontSize(textFontSize)
      this.doc.setFont('Lato-Regular')
      text = `Building owners must meet the applicable required flexible score for their property established in Table 1A. Table 1B shows the scores that building owners earn for measures they select to install. To comply, the sum of points earned from Table 1B must be greater than or equal to the required score from Table 1A.`
      splitText = this.doc.splitTextToSize(text, maxWidth)
      this.doc.text(splitText, this.margins.left, startYPositionContent + 105, { baseline: 'bottom' })

      // Fourth paragraph
      this.doc.setTextColor(this.colors.blue80)
      this.doc.setFontSize(titleFontSize)
      this.doc.setFont('Lato-Bold')
      this.doc.text(`Intended use for policy makers`, this.margins.left, startYPositionContent + 135, { baseline: 'bottom' })

      this.doc.setTextColor(this.colors.gray02)
      this.doc.setFontSize(textFontSize)
      this.doc.setFont('Lato-Regular')
      text =
        'While developing your policy, use these tables in discussions with colleagues and stakeholders to clarify the mechanics of your flexible path policy. When drafting ordinance language, incorporate these tables to specify your required flexible score and available measure. And when designing public outreach materials and compliance documents, incorporate these tables to guide permit applicants in assessing their options.\n\nPlease use caution when changing these tables. They are based on formulas designed by the reach code team to meet state and federal requirements. We recommend contacting the reach code team for assistance at www.localenergycodes.com/content/contact-us.'
      splitText = this.doc.splitTextToSize(text, maxWidth)
      this.doc.text(splitText, this.margins.left, startYPositionContent + 145, { baseline: 'bottom' })

      // Fifth paragraph
      if (this.policyType == 'existing') {
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`Notes on building vintage for existing buildings`, this.margins.left, startYPositionContent + 195, { baseline: 'bottom' })

        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text =
          'This tables show the measures required under the ordinance according to building vintage.\n\nBuilding vintage is the year in which the original construction permit for the building was submitted.  The dates in the model ordinance are different than those in the cost-effectiveness study and Cost-Effectiveness Explorer.  The dates here represent the year in which the code cycle went into effect (i.e. 1979), as opposed to the nominal code cycle date cited in the study (i.e. 1978).\n\nAdditionally the suggested end year for the third vintage is 2010 rather than 2005 as energy codes from 2005 to 2011 were substantially similar. The Cost-Effectiveness Explorer uses 2005 to correspond with available building stock data however we are in the process of updating those data to include these additional years.'
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, startYPositionContent + 205, { baseline: 'bottom' })
      }

      // Sixth paragraph
      if (this.policyType == 'existing' && this.is_flexible_set_up) {
        this.doc.setFillColor(this.colors.blue20)
        let rectHeight = 70
        this.doc.rect(this.margins.left, startYPositionContent + 270, maxWidth, rectHeight, 'F')

        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`How to use the tables`, this.margins.left + 10, startYPositionContent + 290, { baseline: 'bottom' })

        this.doc.setTextColor(this.colors.blue60)
        titleFontSize = 10
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`Required Flexible Score Table`, this.margins.left + 10, startYPositionContent + 310, { baseline: 'bottom' })

        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = `This table shows the minimum energy savings score that permit applicants must earn to meet the ordinance requirements. The appropriate minimum score depends on the building vintage and type. Permit applicants earn points for each measure they choose to install from the Measure List.`
        splitText = this.doc.splitTextToSize(text, maxWidth - 10)
        this.doc.text(splitText, this.margins.left + 10, startYPositionContent + 320, { baseline: 'bottom' })

        this.doc.setFillColor('#FFFFFF')
      }
    }
  }
}
