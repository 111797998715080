<template>
  <div
    class="psui-flex psui-flex-col psui-items-start psui-bg-white psui-shadow-elevation-10 psui-rounded psui-p-6"
  >
    <p class="psui-font-bold text-header-3 text-deepsky-800 psui-mb-4">
      {{ title | policyTextRephrase('small') }}
    </p>
    <p class="psui-text-small psui-text-gray-60 psui-mb-4">
      {{ description | policyTextRephrase }}
    </p>

    <div
      class="psui-flex"
      :class="{
        'psui-flex-col psui-items-start psui-space-y-2' : getterContentContainerWidth <= 1460,
        'psui-flex-row psui-items-center psui-space-x-6' : getterContentContainerWidth > 1460,
      }"
    > 
      <slot name="actions">
        <PsButton
          data-test-id="summary-explorer-policy-options-button"
          :label="'Explore policy options' | policyTextRephrase('small')"
          size="small"
          @click="$emit('click')"
        />
      </slot>
      <slot name="prototypes">
        <PsTooltip class="psui-z-10 psui-cursor-pointer">
          <template #trigger>
            <div
              class="psui-flex psui-items-center"
            >
              <span class="psui-text-small psui-text-gray-50 psui-mr-2">
                {{ `${prototypesAvailable.length} building ${prototypesAvailable.length > 1 ? 'types' : 'type'} available` }} 
              </span>
              <PsIcon
                :icon="icon"
                :class="iconClasses"
                :size="iconSize"
              />
            </div>
          </template>
          <template #content>
            <div class="flex flex-col">
              {{ tooltipPolicyTypeTitle }} {{ 'policies' | policyTextRephrase('small') }} are available for: <br>
              <span
                v-for="prototype in prototypesAvailable"
                :key="`prototype-${prototype.title}`"
              >
                • {{ prototype.title }}
              </span>
            </div>
          </template>
        </PsTooltip>
      </slot>
    </div>

    <slot name="content" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name:"ExploreStudyTypeCard",
    props:{
      title:{
        type: String,
        default:''
      },
      description:{
        type:String,
        default:''
      },
      tooltipPolicyTypeTitle:{
        type: String,
        default:''
      },
      icon:{
        type: String,
        default:'info_outline'
      },
      iconClasses:{
        type:String,
        default:'psui-ml-2 psui-text-gray-50'
      },
      iconSize:{
        type:String,
        default:"18"
      },
      prototypesAvailable:{
        type: Array,
      } 
    },
    computed:{
      ...mapGetters({getterContentContainerWidth: 'general/getterContentContainerWidth'}),
    }

}
</script>
