<template>
  <StudyResultsContentCard
    v-if="lastJurisdictionVisited && lastClimateZoneVisited"
    :expanded.sync="expanded"
    :prototype="prototype"
    :fuel="fuel"
    :vintage="false"
    :climate_zone="lastClimateZoneVisited"
    :jurisdiction="lastJurisdictionVisited"
    @study-data-update="onStudyDataUpdate($event)"
  >
    <template #header>
      <div class="w-full flex items-center psui-mb-5">
        <div
          class="w-auto whitespace-nowrap mr-4 show-helper-on-hover flex cursor-pointer"
          @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'fuel', id: fuel.id })"
        >
          <h4 class="font-bold psui-text-gray-80 psui-text-h6">
            {{ fuel.type_fuel ? fuel.type_fuel.title : fuel.title }}
          </h4>
        </div>
        <div
          v-if="expanded && getMeasuresLength !== 0"
          data-test-id="study-results-content-fuel-add-hide-columns"
          class="w-full flex items-center psui-cursor-pointer justify-end psui-text-blue-60 hover:opacity-80"
          @click="openDrawerWithEditColumns"
        >
          <PsIcon
            icon="calendar_view_week"
            size="18"
          />
          <span class="psui-ml-1 psui-font-bold psui-text-small">Add/Hide Columns</span>
        </div>
      </div>
    </template>
    <template #body>
      <ForecastTable
        ref="forecastTable"
        :study="study"
        :prototype="prototype"
        :study_data="studyData"
        :building_estimates="buildingEstimates"
        :fuel="fuel"
      />
    </template>
  </StudyResultsContentCard>
</template>

<script>
import StudyResultsContentCard from './StudyResultsContentCard.vue'
import ForecastTable from './table/ForecastTable'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import StudyDataApiService from '@/services/api/StudyDataApiService';

export default {
  name: 'StudyResultsContentFuel',
  components: { 
    ForecastTable, 
    StudyResultsContentCard, 
  },
  props: {
    prototype: {
      type: Object,
      required: true
    },
    fuel: {
      type: Object,
      required: true,
    },
    study: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      expanded: true,
      studyData: [],
    }
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited']),
    // ...mapState('assumptions', ['newBuildingsResultsImpactAssumptions']),
    buildingEstimatesFilter() {
      return {
        jurisdiction_id: this.lastJurisdictionVisited?.id ?? null,
        climate_zone_prefix: this.$route?.params?.climate_zone_raw?.split('-')?.[0] ?? null,
        type_prototype_id: this.prototype.type_prototype_id,
        prototype_id: this.prototype.id,
      }
    },
    buildingEstimates() {
      if (this.cityWideDisabled) {
        return []
      }
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimates'](this.buildingEstimatesFilter)
    },
    cityWideDisabled() {
      if (!this.study || !this.study.released_at) {
        return true
      }

      // Change following lines to enable based on assumptions and not about study release date
      // const assumptionStartYear = dayjs(this.newBuildingsResultsImpactAssumptions.start_at).year()
      // const assumptionEndYear = dayjs(this.newBuildingsResultsImpactAssumptions.terminationDate).year()
      // const estimateYears = Array.from({ length: (assumptionEndYear-assumptionStartYear+1) }, (_, i) => assumptionStartYear + i)
      const studyReleaseYear = dayjs(this.study.released_at).year()
      const estimateYears = Array.from({ length: 2 }, (_, i) => studyReleaseYear + i + 1)
      const buildingEstimates = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimates'](this.buildingEstimatesFilter)
      const buildingEstimatesYears = [...new Set(buildingEstimates.map((be) => be.year).filter(y => y != null))].sort((a, b) => a - b)
      const remainingYears = estimateYears.filter(x => !buildingEstimatesYears.includes(x))
      const dataYears = estimateYears.filter(x => buildingEstimatesYears.includes(x))
      return (studyReleaseYear >= 2024) ? !dataYears.length : !!remainingYears.length
    },
    getMeasuresLength() {
      return this.studyData.length
    }
  },
  methods: {
   async onStudyDataUpdate($event) {
      this.studyData = $event

     // Defines main climate zone based on displaying study data and emit it to the parent to update Utility Rate Information
     let mainClimateZoneData = [...(this.studyData || [])]
     if (!mainClimateZoneData.length) {
       mainClimateZoneData = await StudyDataApiService.getByStudy(this.study.id, this.lastClimateZoneVisited.raw)
     }

     const climateZones = mainClimateZoneData?.reduce((acc, curr) => {
       const key = curr.climate_zone_raw.toUpperCase()
       if (key) {
         acc[key] = (acc?.[key] ?? 0) + 1
       }
       return acc
     }, {})

     let mainClimateZone = null
     Object.keys(climateZones).forEach((climateZone) => {
       if (!mainClimateZone || climateZones[climateZone] > climateZones[mainClimateZone]) {
         mainClimateZone = climateZone
       }
     })
     this.$emit('onUpdateMainStudyDataClimateZone', mainClimateZone, this.study, this.prototype)
    },
    openDrawerWithEditColumns() {
      const studyId = this.study.id
      this.$eventBus.$emit('openStudyResultsDrawerEditColumns', 'New Buildings', this.cityWideDisabled, this.$refs.forecastTable.getStudyDataOrdered, studyId)
    }
  },
}
</script>
