var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-w-full"},[(_vm.hasContent)?_c('PolicyShowContentHeader',{class:{
      'opacity-10': _vm.isLoading,
    },attrs:{"title":_vm._f("policyTextRephrase")('Policy Impact'),"subtitle":_vm._f("policyTextRephrase")('Understand how your policy will impact your jurisdiction')}}):_vm._e(),(_vm.isLoading)?_c('LoaderSpinner'):_vm._e(),(_vm.getIfInformativeTextShouldBeShown)?_c('PsDialog',{staticClass:"mb-6 items-center",attrs:{"theme":"informative","icon":"bar_chart","has-close":false},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getInformativeText)}})]},proxy:true},{key:"action",fn:function(){return [_c('button',{on:{"click":_vm.openBuildingEstimates}},[_vm._v(" Edit Building Estimates ")])]},proxy:true}],null,false,2125295280)}):_vm._e(),(_vm.hasContent)?_c('div',{staticClass:"psui-w-full"},[_c('div',{staticClass:"psui-w-full"},[(_vm.policy_summary_data)?_c('PolicyChartCard',{class:{
          'opacity-10': _vm.isLoading,
        },attrs:{"policy":_vm.policy,"policy_summary_data":_vm.policy_summary_data,"column-selected-key":_vm.columnSelectedKey,"custom-title":_vm.custom_chart_title},on:{"update:columnSelectedKey":function($event){_vm.columnSelectedKey=$event},"update:column-selected-key":function($event){_vm.columnSelectedKey=$event},"resetPolicyImpactItemSelected":_vm.resetPolicyImpactItemSelected}}):_vm._e(),(_vm.policy_impact_breakdown)?_c('PolicyTable',{ref:"policy-impact-table",class:{
          'opacity-10': _vm.isLoading,
        },attrs:{"policy":_vm.policy,"policy_summary_data":_vm.policy_impact_breakdown}}):_vm._e()],1)]):_vm._e(),((!_vm.hasContent && !_vm.isLoading))?_c('PolicyShowContentEmpty'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }