<template>
  <div 
    class="psui-w-full"
  >
    <PolicyShowContentHeader
      v-if="hasContent"
      :title="'Policy Impact' | policyTextRephrase"
      :subtitle="'Understand how your policy will impact your jurisdiction' | policyTextRephrase"
      :class="{
        'opacity-10': isLoading,
      }"
    />
    <LoaderSpinner
      v-if="isLoading"
    />
    <PsDialog
      v-if="getIfInformativeTextShouldBeShown"
      theme="informative"
      icon="bar_chart"
      class="mb-6 items-center"
      :has-close="false"
    >
      <template #default>
        <span v-html="getInformativeText" />
      </template>
      <template #action>
        <button @click="openBuildingEstimates">
          Edit Building Estimates
        </button>
      </template> 
    </PsDialog>
    <div 
      v-if="hasContent"
      class="psui-w-full"
    >
      <div class="psui-w-full">
        <PolicyChartCard
          v-if="policy_summary_data"
          :policy="policy"
          :policy_summary_data="policy_summary_data"
          :column-selected-key.sync="columnSelectedKey"
          :custom-title="custom_chart_title"
          :class="{
            'opacity-10': isLoading,
          }"
          @resetPolicyImpactItemSelected="resetPolicyImpactItemSelected"
        />
        <PolicyTable
          v-if="policy_impact_breakdown"
          ref="policy-impact-table"
          :policy="policy"
          :policy_summary_data="policy_impact_breakdown"
          :class="{
            'opacity-10': isLoading,
          }"
        />
      </div>
    </div>
    <PolicyShowContentEmpty v-if="(!hasContent && !isLoading)" />
  </div>
</template>

<script>
import PolicyChartCard from './PolicyChartCard'
import PolicyTable from './PolicyTable'
import PolicyShowContentHeader from '../PolicyShowContentHeader.vue'
import PolicyShowContentEmpty from './PolicyShowContentEmpty.vue'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
import LoaderSpinner from '@/components/general/LoaderSpinner.vue'

export default {
  name: 'PolicyImpact',
  components: {
    PolicyChartCard,
    PolicyTable,
    PolicyShowContentHeader,
    PolicyShowContentEmpty,
    LoaderSpinner
  },
  props: [
    'policy', 
    'policy_summary_data', 
    'is-loading', 
    'policy_impact_breakdown', 
    'custom_chart_title',
    'is_flexible_set_up'
  ],
  data() {
    return {
      policyItemSelected: null,
      columnSelectedKey: false,
      STUDY_TYPES_DB_SLUGS,
      ASSUMPTIONS_PATHS
    }
  },
  computed: {
    policyCustomCombinations() {
      const policyId = this.$route.params.policy_id
      if (!policyId) return []
      return this.$store.getters['policy/getterPolicyCustomCombinations'](policyId) 
    },
    hasContent() {
      return Boolean(this.policy_summary_data?.data?.length || this.policyCustomCombinations?.length)
    },
    prototypeBuildingEstimates() {
      return (this.$store.getters['policy/getterUserPolicy']({id:this.$route.params.policy_id})?.policy_containers || [])
      .map(container => { return {
        'building_estimates':this.getBuildingEstimatesUnitsFromTypePrototypeWithinPolicy(container.type_prototype_id),
        'building_stock': this.getBuildingStockUnitsFromTypePrototypeWithinPolicy(container.type_prototype_id),
        'type_prototype_id': container.type_prototype_id
      }})
    },
    getPolicySelected(){
      return this.$store.getters['policy/getterUserPolicy']({id:this.$route.params.policy_id})
    },
    getIfWeShouldCheckEstimatesOrStock(){
      return this.$store.getters['policy/getterPolicyStudyTypes']({policy:this.getPolicySelected, policy_id:this.$route.params.policy_id})
      .shift() == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'building_stock' : 'building_estimates'
    },
    getIfInformativeTextShouldBeShown(){
      return this.prototypeBuildingEstimates.some(object => object[this.getIfWeShouldCheckEstimatesOrStock] == 0)
    },
    getInformativeText(){
      const text1 = "We've noticed that there are no estimated units for the building types in your policy. <br>To better project policy impacts, we recommend adjusting your building assumptions accordingly."
      const text2 = "We've noticed that one of the building types in your policy doesn't have estimated units. <br>To ensure accurate projections of policy impacts, we recommend adjusting your building assumptions for that specific building type."

      const rephrasedText1 = this.$options.filters.policyTextRephrase(text1)
      const rephrasedText2 = this.$options.filters.policyTextRephrase(text2)

      return this.prototypeBuildingEstimates.every(object => object[this.getIfWeShouldCheckEstimatesOrStock] == 0) ? rephrasedText1 : rephrasedText2
    }
  },
  watch:{
    'prototypeBuildingEstimates': {
      immediate:true,
      deep:true,
      handler: function(){
        this.$emit('buildingEstimatesChanged')
      }
    }
  },
  mounted() {
    this.$eventBus.$on('setPolicyItemSelected', ({ item, columnSelectedKey }) => {
      this.policyItemSelected = { ...item }
      delete this.policyItemSelected.items
      this.columnSelectedKey = columnSelectedKey
      this.$emit('updateSummaryId', item.id)
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('setPolicyItemSelected')
  },
  methods:{
    resetPolicyImpactItemSelected(item, columnSelectedKey){
      this.$refs['policy-impact-table'].$children[1].resetPolicy(item, columnSelectedKey)
    },
    getBuildingEstimatesUnitsFromTypePrototypeWithinPolicy(type_prototype_id){
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({ type_prototype_id })
    },
    getBuildingStockUnitsFromTypePrototypeWithinPolicy(type_prototype_id){
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ type_prototype_id })
    },
    openBuildingEstimates(){
      const getTypePrototypeWithNoBuildingEstimates = this.prototypeBuildingEstimates.find(type_prototype => type_prototype.building_estimates === 0)
      const getTypePrototypeSlug = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({id:getTypePrototypeWithNoBuildingEstimates.type_prototype_id})
      this.$store.commit('assumptions/openDrawer')
      this.$store.commit('assumptions/updateDrawerPath', ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS) 
      this.$eventBus.$emit('watchToScrollToElement', `item-${getTypePrototypeSlug.slug}`)
    }
  }
  
}
</script>
<style lang="scss">

  .opacity-10 {
    opacity: 0.10;
  }

  .psui-el-dialog.layout-horizontal .psui-el-dialog-action {
    display: flex;
    justify-items: center;
  }
   
</style>