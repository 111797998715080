<template>
  <div
    ref="buildingContainer"
    class="building-container psui-shadow-elevation-5"
  >
    <div
      class="--add-to-pdf"
      attr-export-steps="1"
      :attr-export-study-type="STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS"
      :attr-export-width="`1280px`"
    >
      <div class="header-container flex justify-between items-center">
        <div class="py-2 flex flex-col space-y-1">
          <p class="existing-buildings-label">
            Existing Buildings by Size
          </p>
          <small class="psui-text-xsmall psui-text-gray-60 block">Data Source:
            <a
              href="https://intercom.help/explorer-local-energy-codes/en/articles/10236236-existing-nonresidential-building-data"
              class="psui-text-blue-60"
              target="_blank"
            >NREL, City and County Commercial Building Inventories</a></small>
        </div>

        <div class="flex justify-between items-center self-stretch">
          <div
            v-if="showClimateZoneWarn || (!isLoading && !bpsBuildingData.length)"
            class="climate-zone-warn mt-2"
          >
            <PsIcon
              icon="info"
              size="16"
              display="flex"
              class="psui-text-gray-50"
            />
            <p
              v-if="!bpsBuildingData.length"
              class="psui-text-xsmall psui-text-gray-50 psui-font-bold ml-1"
            >
              Data is not available for the selected jurisdiction
            </p>
            <p
              v-else
              class="psui-text-xsmall psui-text-gray-50 psui-font-bold ml-1"
            >
              Data cannot be broken down by climate zone
            </p>
          </div>

          <div class="heading-right-item">
            <p class="psui-text-gray-80 psui-text-small psui-font-bold block">
              Total
            </p>
            <p class="psui-text-gray-50 psui-text-small block">
              as of 2020
            </p>
          </div>
          <div class="heading-right-item items-end">
            <div class="relative flex">
              <PsIcon
                icon="info_outline"
                size="16"
                display="flex"
                class="helper-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60 absolute"
                style="left: -20px"
                @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'bps_buildings_unities' })"
              />
              <p class="psui-text-xsmall psui-text-gray-70 psui-font-bold block">
                Buildings
              </p>
            </div>
            <p class="psui-text-p psui-text-gray-80 block">
              {{ totalBuildingsFormatted }}
            </p>
          </div>
          <div class="heading-right-item items-end">
            <div class="relative flex ml-2">
              <PsIcon
                icon="info_outline"
                size="16"
                display="flex"
                class="helper-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60 absolute"
                style="left: -20px"
                @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'bps_floor_area' })"
              />
              <p class="psui-text-xsmall psui-text-gray-70 psui-font-bold block">
                Floor Area
              </p>
            </div>
            <p class="psui-text-p psui-text-gray-80 block">
              {{ totalFloorAreaFormatted }} <span class="psui-text-small psui-text-gray-60">ft²</span>
            </p>
          </div>
        </div>
      </div>

      <hr class="horizontal-line">

      <div class="flex">
        <BuildingEstimatesLoadingScreen v-if="isLoading" />

        <div
          id="left-side"
          :class="{'hide-on-export': (!isCumulativeShareActive || (!showCumulativeShareFloorArea && !showCumulativeShareBuildings)) && !isBuildingTypeActive}"
        >
          <div
            class="options-container w-full"
            :class="{'hide-on-export': !isCumulativeShareActive || (!showCumulativeShareFloorArea && !showCumulativeShareBuildings)}"
          >
            <div class="cumulative-options-container">
              <PsAccordionItem
                ref="psAccordionItemCumulativeShare"
                data-ga-elem-name="Cumulative Share Control"
              >
                <template #header-additionals>
                  <div
                    class="flex items-center justify-between w-full"
                    @click.stop
                  >
                    <div class="cumulative-share-label-container flex items-center">
                      <label
                        class="cumulative-share-label"
                        @click.stop
                      > Cumulative share </label>
                      <PsIcon
                        icon="info_outline"
                        size="16"
                        display="flex"
                        class="cumulative-share-info-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
                        style="margin-left: 4px; margin-top: 2px"
                        @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'bps_cumulative_share' })"
                      />
                    </div>
                    <div class="flex items-center ml-auto hide-on-export">
                      <PsSwitch
                        :value="isCumulativeShareActive"
                        size="small"
                        label="Cumulative share"
                        background-color="psui-bg-blue-60"
                        @click.stop.native="toggleCumulativeShare"
                      />
                      <PsIcon
                        :icon="isCumulativeShareAccordionExpanded ? 'expand_less' : 'expand_more'"
                        size="16"
                        display="flex"
                        class="psui-items-center psui-h-5 hover:psui-text-blue-60"
                        style="margin-left: 4px"
                        @click.stop.native="toggleCumulativeShareAccordionItem"
                      />
                    </div>
                  </div>
                </template>
                <template #default>
                  <div ref="cumulativeShareCheckboxes">
                    <label
                      class="checkbox-container cursor-pointer"
                      :class="{'hide-on-export': !showCumulativeShareFloorArea}"
                    >
                      <input
                        v-model="showCumulativeShareFloorArea"
                        type="checkbox"
                        class="cumulative-share-input hide-on-export"
                      >
                      <span class="color-box floor-area" />
                      <span class="text-content pt-0.5 pb-1"> Floor area </span>
                    </label>
                    <label
                      class="checkbox-container cursor-pointer"
                      :class="{'hide-on-export': !showCumulativeShareBuildings}"
                    >
                      <input
                        v-model="showCumulativeShareBuildings"
                        type="checkbox"
                        class="cumulative-share-input hide-on-export"
                      >
                      <span class="color-box buildings" />
                      <span class="text-content pt-1 pb-1"> Buildings </span>
                    </label>
                    <label class="checkbox-container cursor-pointer pb-2 hide-on-export">
                      <input
                        v-model="showDataLabelsToggle"
                        type="checkbox"
                        class="cumulative-share-input"
                      >
                      <span class="text-content pt-1"> % Values </span>
                    </label>
                  </div>
                </template>
              </PsAccordionItem>
            </div>
          </div>

          <div
            ref="optionsContainer"
            class="input-options-container"
            :class="{'hide-on-export': !isBuildingTypeActive}"
          >
            <PsAccordionItem
              ref="psAccordionItemBuildingType"
              data-ga-elem-name="Building Type Control"
            >
              <template #header-additionals>
                <div
                  class="flex items-center justify-between w-full"
                  @click.stop
                >
                  <div class="building-type-label-container flex items-center">
                    <label
                      class="building-type-label"
                      @click.stop
                    > Building type </label>
                    <PsIcon
                      icon="info_outline"
                      size="16"
                      display="flex"
                      class="building-type-info-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
                      style="margin-left: 4px; margin-top: 2px"
                      @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'bps_building_types' })"
                    />
                  </div>
                  <div class="flex items-center ml-auto hide-on-export">
                    <PsSwitch
                      :value="isBuildingTypeActive"
                      size="small"
                      label="Building type"
                      background-color="psui-bg-blue-60"
                      @click.stop.native="toggleBuildingType"
                    />
                    <PsIcon
                      :icon="isBuildingTypeAccordionExpanded ? 'expand_less' : 'expand_more'"
                      size="16"
                      display="flex"
                      class="psui-items-center psui-h-5 hover:psui-text-blue-60"
                      style="margin-left: 4px"
                      @click.stop.native="toggleBuildingTypeAccordionItem"
                    />
                  </div>
                </div>
              </template>
              <template #default>
                <label
                  class="input-checkbox-container cursor-pointer hide-on-export"
                >
                  <input
                    ref="allCheckbox"
                    v-model="isAllBuildingTypeSelected"
                    type="checkbox"
                    class="building-type-input"
                    @change="toggleAllBuildingTypes()"
                  >
                  <span class="text-content pt-0.5 pb-1"> All </span>
                </label>
                <div
                  v-for="(option, index) in sortedOptions"
                  :key="option.slug"
                >
                  <label
                    v-if="index !== 0"
                    :ref="'option-' + option.slug"
                    class="input-checkbox-container cursor-pointer"
                    :class="{'hide-on-export': !isBuildingTypeSelected(option.slug)}"
                  >
                    <input
                      type="checkbox"
                      class="building-type-input hide-on-export"
                      :name="`bps-building-type-checkbox-${option.slug}`"
                      :checked="isBuildingTypeSelected(option.slug)"
                      @change="handleCheckboxChange(option.slug, $event)"
                    >
                    <span
                      :ref="'colorBox-' + option.slug"
                      class="input-color-box"
                      :style="{ backgroundColor: buildingColors[option.slug] }"
                    />
                    <span
                      :ref="'label-' + option.slug"
                      v-tooltip="getTooltipContent(option, index)"
                      class="option-label text-content pt-1 pb-1 text-overflow"
                    >
                      {{ option.name }}
                    </span>
                    <PsIcon
                      icon="info_outline"
                      size="16"
                      display="flex"
                      class="building-types-info-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
                      style="margin-left: 4px; margin-top: 2px"
                      @click.stop.prevent="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: option.helper_slug })"
                    />
                  </label>
                </div>
              </template>
            </PsAccordionItem>
          </div>
        </div>

        <div
          id="middle-side"
          class="relative flex flex-col shrink pb-2"
          :class="{
            'px-0': showCumulativeShareFloorArea || showCumulativeShareBuildings,
            'apex-chart-general': !(showCumulativeShareFloorArea || showCumulativeShareBuildings) && isAllSelected,
            'custom-padding': !showCumulativeShareFloorArea && !showCumulativeShareBuildings,
          }"
        >
          <ApexSelectionReworked
            v-if="!isLoading"
            ref="apexSelection"
            :is-cumulative-share-active="isCumulativeShareActive"
            @onXAxis="handleSelection"
          />
          <ApexChartGeneral
            ref="apexChart"
            :avoid-destroy="true"
            :disable-updates="true"
            :override-selection-function="true"
            margin-top="26px"
            class="w-full place-self-end relative"
          />
          <div class="x-axis-title-container -mt-3">
            <span class="x-axis-title">
              Individual Building Size ft²
              <PsIcon
                icon="info_outline"
                size="16"
                display="flex"
                class="info-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
                style="margin-left: 4px; margin-top: 0"
                @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'bps_individual_building_size' })"
              />
            </span>
          </div>
        </div>

        <div id="right-side">
          <transition
            name="fade"
            mode="out-in"
          >
            <BuildingEstimatesNonAndLargeSideBar
              :slugs="seriesSlug"
              :data="buildingSelectedData"
              :chart-series="generateSelectionData"
              :categories="categories"
              :selected-area="selectedArea"
            />
          </transition>
          <span class="selected-area-label-xls hidden">{{ selectedArea }}</span>
        </div>
      </div>
    </div>

    <div
      class="--add-to-pdf"
      attr-export-steps="1"
      :attr-export-study-type="STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS"
    >
      <div
        id="toggle"
        class="flex items-center justify-between mr-3 ml-3 cursor-pointer"
        :class="[{ 'mb-2': !isTableVisible }, { 'mt-2 mb-2': showSideBar }]"
        @click="toggleTableVisibility"
      >
        <p class="cursor-pointer hover:opacity-80 text-[#002A3A] font-lato font-bold text-sm leading-[1.3] table-heading">
          {{ isExporting ? 'Existing Buildings Table' : 'Table' }}
        </p>
        <PsIcon
          :icon="isTableVisible ? 'expand_less' : 'expand_more'"
          size="24"
          display="flex"
          class="psui-items-center psui-text-gray-50 hover:psui-text-blue-60 cursor-pointer pr-1 hide-on-export"
        />
      </div>
      <transition
        name="building-size-chart-table-fade"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end"
      >
        <div
          v-if="isTableVisible"
          class="building-table-container w-full"
        >
          <table
            class="building-table building-table-xls w-full table-fixed overflow-hidden rounded"
            :class="{ 'show-side-bar': showSideBar }"
          >
            <thead v-if="getTableHeaders">
              <tr>
                <th
                  v-for="(header, index) in getTableHeaders"
                  :key="header.slug"
                  :class="['cursor-pointer py-1', { 'pl-4': index === 0 }, { 'w-fit min-w-[170px]': index !== 0 }]"
                  @click="setOrderColumn(header.slug)"
                >
                  <div
                    class="header-label flex items-center"
                    :class="{ 'justify-end': index !== 0 }"
                  >
                    <template v-if="index === 0">
                      <span>{{ header.title }}</span>
                      <PsIcon
                        icon="info_outline"
                        size="16"
                        display="flex"
                        class="header-info-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
                        style="margin-left: 4px; margin-top: 2px"
                        @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: header.helper_slug })"
                      />
                    </template>
                    <template v-else>
                      <PsIcon
                        icon="info_outline"
                        size="16"
                        display="flex"
                        class="header-info-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
                        style="margin-right: 4px; margin-top: 0.5px"
                        @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: header.helper_slug })"
                      />
                      <span>{{ header.title }}</span>
                      <PsIcon
                        :icon="`/icons/sort-up.svg`"
                        width="24"
                        height="24"
                        display="flex"
                        color="psui-text-gray-40"
                        :disable-stroke="true"
                        style="margin-top: 1px"
                        class="psui-items-center psui-justify-center"
                      />
                    </template>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(dt, index) in getTableRows"
                :key="index"
              >
                <td
                  v-for="(header, idx) in getTableHeaders"
                  :key="header.slug"
                  :class="['text-content', { 'w-fit': showSideBar && idx !== 0, 'text-right': idx !== 0 }]"
                >
                  {{ formatBodyCellValue(index, header.slug, dt) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  v-for="(footer, index) in getTableFooter"
                  :key="index"
                  :class="['footer-label', { 'fit-width': showSideBar && index !== 0, 'text-right': index !== 0 }]"
                >
                  {{ formatFooterCellValue(footer) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { buildingSizeChartColors } from '@/business-logic/constants/chartDefaultOptions'
import { calculateMaxValue, roundUpToNearest } from '@/util/Functions.js'
import { STUDY_TYPES_DB_SLUGS, ASSUMPTIONS_PATHS } from '@/util/Enums.js'
import BuildingEstimatesNonAndLargeSideBar from '@/components/charts/building-estimates/BuildingEstimatesNonAndLargeSideBar.vue'
import ApexSelectionReworked from './ApexSelectionReworked.vue'
import BuildingEstimatesLoadingScreen from '@/components/charts/building-estimates/BuildingEstimatesLoadingScreen.vue'
import { mapGetters } from 'vuex'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import * as sha1 from 'sha1'

export default {
  name: 'BuildingSizeChart',
  components: {
    BuildingEstimatesNonAndLargeSideBar,
    ApexSelectionReworked,
    BuildingEstimatesLoadingScreen,
  },
  props: ['getBuildingTypeSelected'],
  data: () => ({
    STUDY_TYPES_DB_SLUGS,
    lastMouseDownTimestamp: 0,
    canChangeSelectedArea: false,
    onMouseDownSelection: [],
    mouseDown: false,
    showCumulativeShareFloorArea: true,
    showCumulativeShareBuildings: true,
    showDataLabelsToggle: false,
    buildingSizeSelectedData: [],
    selectionRange: {
      min: 0,
      max: 0,
    },
    showSideBar: false,
    selectedOptions: [],
    savedBuildingTypeOptions: [],
    categories: ['>100K', '50-100K', '40-50K', '30-40K', '25-30K', '20-25K', '15-20K', '10-15K', '7.5-10K', '5-7.5K', '<5K'],
    buildingSizeChartColors,
    isTableVisible: true,
    showMore: false,
    visibleOptions: 0,
    resizeObserver: null,
    selectedArea: {
      min: undefined,
      max: undefined,
    },
    chartOptions: {},
    tooltipVisible: false,
    lastClickedOption: null,
    lastGTagData: null,
    createdGTags: null,
    orderByColumn: 'building_type',
    orderByDirection: 'asc',
    floorAreaData: [],
    buildingsData: [],
    showCumulative: false,
    isCumulativeShareActive: true,
    isCumulativeShareAccordionExpanded: true,
    isBuildingTypeActive: true,
    isBuildingTypeAccordionExpanded: true,
    isAllBuildingTypeSelected: true,
    buildingTypeSlug: 'building_type',
    totalNumberSlug: 'total_number',
    totalFloorAreaSlug: 'total_floor_area',
    selectionNumberSlug: 'selection_number',
    selectionFloorAreaSlug: 'selection_floor_area',
    headers: [
      {
        slug: 'building_type',
        title: 'Building Type',
        helper_slug: 'bps_building_types',
      },
      {
        slug: 'total_number',
        title: 'Buildings',
        helper_slug: 'bps_buildings_unities',
      },
      {
        slug: 'total_floor_area',
        title: 'Floor Area',
        helper_slug: 'bps_floor_area',
      },
      {
        slug: 'selection_number',
        title: 'Selected Buildings',
        helper_slug: 'bps_selected_buildings_unities',
      },
      {
        slug: 'selection_floor_area',
        title: 'Selected Floor Area',
        helper_slug: 'bps_selected_floor_area',
      },
    ],
    truncatedOptions: {},
    isLoading: true,
    loadingTime: new Date().valueOf(),
    debouncers: {},
    adjustedMaxHeight: '450px',
    tooltipPositions: {},
    prevExportState: null,
    isExporting: false,
    downloadChartSize: 0,
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    assumptionsDrawerOptions() {
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    },
    chartMarkerColors() {
      const colors = ['#BAB25E', '#87C0BA'].filter((_, index) => {
        if (this.showCumulativeShareFloorArea && index == 0) return true
        if (this.showCumulativeShareBuildings && index == 1) return true
        return false
      })

      return {
        size: 3,
        colors: ['#fff'],
        strokeColors: colors,
        hover: {
          size: 3,
          sizeOffset: 3,
        },
      }
    },
    showClimateZoneWarn() {
      const allClimateZones = this.$store.getters['lastJurisdictionVisited']?.climate_zones ?? []
      const activeClimateZones = allClimateZones.filter((cz) => !this.checkQueryKeyValue('exclude_climate_zone_prefix', cz.prefix))
      return allClimateZones?.length !== activeClimateZones?.length
    },
    buildingSelectedData() {
      return this.buildingSizeSelectedData
    },
    bpsBuildingData() {
      const bpsData = this.$store.getters['assumptions/buildingStocks/getterBpsDefaultJurisdictionBuildingStocks']()
      return Array.isArray(bpsData) ? bpsData : []
    },
    generateSelectionData() {
      if (this.selectedOptions.length === 0) {
        return [
          {
            slug: this.seriesSlug.FLOOR_AREA,
            data: [
              {
                data: Array(10).fill(0),
                total: Array(11).fill(1),
              },
            ],
          },
          {
            slug: this.seriesSlug.BUILDINGS,
            data: [
              {
                data: Array(10).fill(0),
                total: Array(11).fill(1),
              },
            ],
          },
        ]
      }

      const data = [
        {
          slug: this.seriesSlug.FLOOR_AREA,
          data: this.floorAreaData,
        },
        {
          slug: this.seriesSlug.BUILDINGS,
          data: this.buildingsData,
        },
      ]

      const result = data.reduce((acc, curr) => {
        const { min, max } = this.selectionRange

        curr.data = curr.data.reduce((acc, curr) => {
          if (this.selectedOptions.includes(curr.slug) || this.selectedOptions.includes(this.seriesSlug.ALL)) {
            const datapoint = {
              ...curr,
              data: curr.data.filter((_, idx) => idx >= min && idx <= max),
              total: curr.data,
            }

            acc.push(datapoint)
          }
          return acc
        }, [])

        acc.push(curr)
        return acc
      }, [])

      return result
    },
    enableSelection() {
      return true
    },
    computedSelectedOptions: {
      get() {
        return this.selectedOptions
      },
      set(value) {
        this.selectedOptions = value
      },
    },
    isAllSelected() {
      return this.selectedOptions.includes(this.seriesSlug.ALL)
    },
    categoriesFiltered() {
      return this.categories
      // return this.isAllSelected ? this.categories : this.categories.filter((cat) => cat !== 'All')
    },
    seriesName() {
      const names = {
        FLOOR_AREA: 'Floor area',
        BUILDINGS: 'Buildings',
        ALL: 'All',
      }
      if (Array.isArray(this.buildingsData)) {
        this.buildingsData.forEach((building) => {
          if (building && building.slug) {
            names[building.slug.toUpperCase().replace(/-/g, '_')] = building.name
          }
        })
      }
      return names
    },
    seriesSlug() {
      const slugs = {
        FLOOR_AREA: 'floor-area',
        BUILDINGS: 'buildings',
        ALL: 'all',
      }
      if (Array.isArray(this.buildingsData)) {
        this.buildingsData.forEach((building) => {
          if (building && building.slug) {
            slugs[building.slug.toUpperCase().replace(/-/g, '_')] = building.slug
          }
        })
      }
      return slugs
    },
    options() {
      return [
        { name: this.seriesName.ALL, slug: this.seriesSlug.ALL, valueClass: 'all' },
        ...this.buildingsData.map((building) => ({
          name: building.name,
          slug: building.slug,
          helper_slug: building.slug?.replace(/-/g, '_'),
          valueClass: building.slug,
        })),
      ]
    },
    sortedOptions() {
      const optionsWithoutAll = this.options.filter((item) => item.slug !== this.seriesSlug.ALL)

      const optionsWithData = optionsWithoutAll.map((option) => {
        const building = this.buildingsDataWithTotal.find((b) => b.slug === option.slug)
        const lastDataPoint = building ? building.data[building.data.length - 1] : 0
        return {
          ...option,
          lastDataPoint,
        }
      })

      optionsWithData.sort((a, b) => b.lastDataPoint - a.lastDataPoint)

      const allOption = this.options[0]
      return [allOption, ...optionsWithData]
    },
    optionsFiltered() {
      return this.isAllSelected ? this.options : this.options.filter((opt) => opt.slug !== 'all')
    },
    buildingColors() {
      const colors = {
        [this.seriesSlug.FLOOR_AREA]: '#ece9c670',
        [this.seriesSlug.BUILDINGS]: '#d6f5f270',
        [this.seriesSlug.ALL]: this.buildingSizeChartColors[0],
      }
      const sortedOptiosWithoutAll = this.sortedOptions.filter((item) => item.slug !== this.seriesSlug.ALL)
      sortedOptiosWithoutAll.forEach((building, index) => {
        colors[building.slug] = this.getColor(index)
      })
      return colors
    },
    getTableHeaders() {
      return this.headers.filter((header) => {
        if (this.showSideBar && (header.helper_slug == this.selectionFloorAreaSlug || header.helper_slug == this.selectionNumberSlug)) return false
        return true
      })
    },
    buildingsDataWithTotal() {
      return this.calculateTotal(this.buildingsData)
    },
    totalBuildingsFormatted() {
      return this.reduceData(null, this.buildingsDataWithTotal, this.totalNumberSlug)
    },
    totalFloorAreaFormatted() {
      return this.reduceData(null, this.floorAreaDataWithTotal, this.totalFloorAreaSlug)
    },
    getTableRows() {
      if (!this.selectedOptions.length) {
        return []
      }

      const buildingsDataWithTotal = this.buildingsDataWithTotal
      const floorAreaDataWithTotal = this.floorAreaDataWithTotal

      const buildingData = this.generateSelectionData.find((item) => item.slug == this.seriesSlug.BUILDINGS)
      const selectionBuildingDataWithTotal = this.calculateTotal(buildingData?.data)
      const floorData = this.generateSelectionData.find((item) => item.slug == this.seriesSlug.FLOOR_AREA)
      const selectionFloorDataWithTotal = this.calculateTotal(floorData?.data)

      const optionsToShow = buildingsDataWithTotal.map((b) => b.slug)

      const rows = optionsToShow.map((option) => {
        const buildingData = buildingsDataWithTotal.find((building) => building.slug === option)
        const floorAreaData = floorAreaDataWithTotal.find((floorArea) => floorArea.slug === option)

        const number = buildingData ? buildingData?.data[buildingData?.data.length - 1] : 0
        const floorArea = floorAreaData ? floorAreaData?.data[floorAreaData?.data.length - 1] : 0

        let selection = {}
        if (this.showSideBar) {
          const selectionBuildingData = selectionBuildingDataWithTotal.find((sbd) => sbd.slug == option)
          const selectionBuildingFloor = selectionFloorDataWithTotal.find((sbd) => sbd.slug == option)
          selection.selection_number = selectionBuildingData?.data[selectionBuildingData?.data.length - 1] || 0
          selection.selection_floor_area = selectionBuildingFloor?.data[selectionBuildingFloor?.data.length - 1] || 0
        }
        return {
          building_type: buildingData ? buildingData.name : '',
          slug: buildingData.slug,
          total_number: this.formatNumber(number),
          total_number_raw: number,
          total_floor_area: this.formatNumber(floorArea),
          total_floor_area_raw: floorArea,
          selection_number: this.formatNumber(selection.selection_number),
          selection_number_raw: selection.selection_number,
          selection_floor_area: this.formatNumber(selection.selection_floor_area),
          selection_floor_area_raw: selection.selection_floor_area,
        }
      })

      rows.sort((a, b) => {
        let valueA = a[this.orderByColumn]
        let valueB = b[this.orderByColumn]

        if ([this.totalNumberSlug, this.totalFloorAreaSlug, this.selectionNumberSlug, this.selectionFloorAreaSlug].includes(this.orderByColumn)) {
          valueA = a[`${this.orderByColumn}_raw`]
          valueB = b[`${this.orderByColumn}_raw`]
        }

        let comparison = 0
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          comparison = valueA - valueB
        } else {
          comparison = (valueA || '').toString().localeCompare((valueB || '').toString())
        }

        return comparison * (this.orderByDirection === 'asc' ? 1 : -1)
      })

      return rows
    },
    getTableFooter() {
      const buildingsDataWithTotal = this.buildingsDataWithTotal
      const floorAreaDataWithTotal = this.floorAreaDataWithTotal

      const buildingData = this.generateSelectionData.find((item) => item.slug == this.seriesSlug.BUILDINGS)
      const selectionBuildingDataWithTotal = this.calculateTotal(buildingData?.data)
      const floorData = this.generateSelectionData.find((item) => item.slug == this.seriesSlug.FLOOR_AREA)
      const selectionFloorDataWithTotal = this.calculateTotal(floorData?.data)

      const optionsToShow = buildingsDataWithTotal.map((b) => b.slug)

      const totalNumber = this.reduceData(optionsToShow, buildingsDataWithTotal, this.totalNumberSlug)
      const totalFloorArea = this.reduceData(optionsToShow, floorAreaDataWithTotal, this.totalFloorAreaSlug)
      let footer = [
        'All',
        {
          header_slug: this.totalNumberSlug,
          value: totalNumber,
        },
        {
          header_slug: this.totalFloorAreaSlug,
          value: totalFloorArea,
        },
      ]

      if (this.showSideBar) {
        const selectionTotalNumber = this.reduceData(optionsToShow, selectionBuildingDataWithTotal, this.selectionNumberSlug)
        const selectionTotalFloorArea = this.reduceData(optionsToShow, selectionFloorDataWithTotal, this.selectionFloorAreaSlug)
        footer = footer.concat([
          {
            header_slug: this.selectionNumberSlug,
            value: selectionTotalNumber,
          },
          {
            header_slug: this.selectionFloorAreaSlug,
            value: selectionTotalFloorArea,
          },
        ])
      }

      return footer
    },
    buildingsDataWithSum() {
      const allValues = Array(this.categories.length).fill(0)

      this.buildingsData.forEach((building) => {
        building.data.forEach((value, index) => {
          allValues[index] += value
        })
      })

      return [
        // ...this.buildingsData,
        {
          name: this.seriesName.ALL,
          slug: this.seriesSlug.ALL,
          data: allValues,
        },
      ]
    },
    floorAreaDataWithTotal() {
      return this.calculateTotal(this.floorAreaData)
    },
    floorAreaDataWithSum() {
      const allValues = Array(this.categories.length).fill(0)

      this.floorAreaData.forEach((building) => {
        building.data.forEach((value, index) => {
          allValues[index] += value
        })
      })
      return [
        // ...this.floorAreaData,
        {
          name: this.seriesName.ALL,
          slug: this.seriesSlug.ALL,
          data: allValues,
        },
      ]
    },
    chartSeries() {
      if (this.selectedOptions.length === 0) {
        return this.generateZeroDataSeries()
      }

      const filteredOptions = this.isAllSelected ? [this.seriesSlug.ALL] : this.selectedOptions
      const buildingsDataSet = this.isAllSelected ? this.buildingsDataWithSum : this.buildingsData
      const floorAreaDataSet = this.isAllSelected ? this.floorAreaDataWithSum : this.floorAreaData

      const selectedData = filteredOptions.map((optionValue) => {
        const option = this.optionsFiltered.find((opt) => opt.slug === optionValue)
        const buildingsDataFiltered = buildingsDataSet.find((building) => building.slug === optionValue)
        const floorAreaDataFiltered = floorAreaDataSet.find((floorArea) => floorArea.slug === optionValue)

        return {
          floorArea: floorAreaDataFiltered ? floorAreaDataFiltered.data : [],
          buildings: buildingsDataFiltered ? buildingsDataFiltered.data : [],
          name: option ? option.name : '',
          slug: option ? option.slug : '',
        }
      })

      const sortedSlugs = this.sortedOptions.slice(1).map((option) => option.slug)

      const selectedDataFiltered = sortedSlugs
        .map((slug) => {
          return selectedData.find((data) => data.slug === slug)
        })
        .filter(Boolean)

      const allData = selectedData.find((data) => data.slug === this.seriesSlug.ALL)

      const cumulativeShareFloorAreaPercentages = this.calculateCumulativePercentages(selectedData, 'floorArea')

      const cumulativeShareFloorAreaData = this.showCumulativeShareFloorArea
        ? [
            {
              name: this.seriesName.FLOOR_AREA,
              type: 'area',
              slug: this.seriesSlug.FLOOR_AREA,
              data: cumulativeShareFloorAreaPercentages.map((value, index) => ({
                x: this.categoriesFiltered[index],
                y: this.formatNumber(value),
              })),
              color: this.buildingColors[this.seriesSlug.FLOOR_AREA],
              group: 'floor-area-group',
            },
          ]
        : []

      const cumulativeShareBuildingsPercentages = this.calculateCumulativePercentages(selectedData, 'buildings')

      const cumulativeShareBuildingsData = this.showCumulativeShareBuildings
        ? [
            {
              name: this.seriesName.BUILDINGS,
              type: 'area',
              slug: this.seriesSlug.BUILDINGS,
              data: cumulativeShareBuildingsPercentages.map((value, index) => ({
                x: this.categoriesFiltered[index],
                y: this.formatNumber(value),
              })),
              color: this.buildingColors[this.seriesSlug.BUILDINGS],
              group: 'buildings-group',
            },
          ]
        : []

      const numberOfBuildingsSeries = selectedDataFiltered.map((data) => {
        let acc = 0
        return {
          name: data.name,
          type: 'bar',
          slug: data.slug,
          data: data.buildings.map((value, index) => {
            if (this.showCumulative) {
              acc += value
              return {
                x: this.categoriesFiltered[index],
                y: acc,
              }
            }
            return {
              x: this.categoriesFiltered[index],
              y: value,
            }
          }),
          color: this.buildingColors[data.slug],
          group: 'main-group',
        }
      })

      let allacc = 0
      const allBuildingsSeries = allData
        ? [
            {
              name: allData.name,
              type: 'bar',
              slug: allData.slug,
              data: allData.buildings.map((value, index) => {
                if (this.showCumulative && this.categoriesFiltered[index] != 'All') {
                  allacc += value
                  return {
                    x: this.categoriesFiltered[index],
                    y: allacc,
                  }
                }
                return {
                  x: this.categoriesFiltered[index],
                  y: value,
                }
              }),

              color: this.buildingColors[allData.slug],
              group: 'all-group',
            },
          ]
        : []

      return [...cumulativeShareFloorAreaData, ...cumulativeShareBuildingsData, ...numberOfBuildingsSeries, ...allBuildingsSeries]
    },
    chartDataLabels() {
      const showBoth = this.showCumulativeShareFloorArea && this.showCumulativeShareBuildings
      const showOne = this.showCumulativeShareFloorArea || this.showCumulativeShareBuildings

      const dataLabels = {
        enabled: this.showDataLabelsToggle ? showOne : false,
        enabledOnSeries: showBoth ? [0, 1] : [0],
        formatter: function (val, opts) {
          if (val == 100) return
          return Math.round(val) + '%'
        },
        style: {
          colors: showBoth ? ['#515e6a', '#515e6a'] : this.showCumulativeShareFloorArea ? ['#515e6a'] : ['#515e6a'],
        },
        offsetX: 0,
        offsetY: -5,
        textAnchor: 'middle',
        background: {
          enabled: false,
        },
      }

      return dataLabels
    },
    chartSroke() {
      const showBoth = this.showCumulativeShareFloorArea && this.showCumulativeShareBuildings
      const showOne = this.showCumulativeShareFloorArea || this.showCumulativeShareBuildings
      const seriesLength = this.chartSeries.length

      const curve = Array(seriesLength).fill('straight')
      const width = Array(seriesLength).fill(0.5)
      const colors = Array(seriesLength).fill('')

      const floorAreaStrokeColor = '#c1b96b'
      const buildingStrokeColor = '#87c0ba'

      if (showBoth) {
        width[0] = 2
        width[1] = 2
        colors[0] = floorAreaStrokeColor
        colors[1] = buildingStrokeColor
      } else if (showOne) {
        width[0] = 2
        colors[0] = this.showCumulativeShareFloorArea ? floorAreaStrokeColor : buildingStrokeColor
      }

      return {
        show: showOne,
        curve: curve,
        width: width,
        colors: colors,
      }
    },
    chartYAxis() {
      const showCumulativeShare = this.showCumulativeShareFloorArea || this.showCumulativeShareBuildings
      // const allSeries = this.chartSeries.find((series) => series.slug === 'all' && series.group === 'all-group')

      let maxValue
      // if (allSeries) {
      //   const allData = allSeries.data.find((point) => point.x === 'All')
      //   maxValue = allData ? allData.y : 0
      // } else {
      //   maxValue = calculateMaxValue(this.chartSeries, 'bar')
      // }
      // const roundedMaxValue = maxValue

      maxValue = calculateMaxValue(this.chartSeries, 'bar')
      const roundedMaxValue = roundUpToNearest(maxValue)
      const seriesNames = this.options.map((option) => option.name)

      const yAxis = [
        {
          title: {
            text: 'Number of Buildings',
            style: {
              color: '#34404A',
              fontSize: '12px',
              fontWeight: 700,
            },
          },
          axisBorder: {
            show: true,
            color: '#D6DDE5',
            offsetX: 0,
            offsetY: 1,
          },
          labels: {
            formatter: function (value) {
              return this.formatUnits(value)
            }.bind(this),
            style: {
              colors: ['#798490'],
              fontSize: '12px',
              fontFamily: 'Lato, Helvetica Neue, sans-serif',
              fontWeight: 700,
              cssClass: 'apexcharts-xaxis-label psui-text-left',
            },
          },
          tickAmount: 4,
          min: 0,
          max: roundedMaxValue !== 0 ? roundedMaxValue : 100,
          seriesName: seriesNames,
        },
      ]

      if (showCumulativeShare) {
        yAxis.push({
          title: {
            rotate: 90,
            style: {
              fontSize: '12px',
              fontFamily: 'Lato, Helvetica Neue, sans-serif',
              fontWeight: 700,
            },
          },
          axisBorder: {
            show: true,
            color: '#D6DDE5',
            offsetX: 0,
            offsetY: 1,
          },
          labels: {
            formatter: function (val) {
              return val + '%'
            },
            style: {
              colors: ['#798490'],
              fontSize: '12px',
              fontFamily: 'Lato, Helvetica Neue, sans-serif',
              fontWeight: 700,
              cssClass: 'apexcharts-xaxis-label psui-text-left',
            },
          },
          opposite: true,
          tickAmount: 4,
          min: 0,
          max: 100,
          seriesName: [this.seriesName.FLOOR_AREA, this.seriesName.BUILDINGS],
        })
      }

      return yAxis
    },
    getBuildingsDataTotalValue() {
      const item = this.buildingsDataWithSum.find((item) => item.slug === 'all')
      return item ? this.formatNumber(item.data[item.data.length - 1]) : 0
    },
    getStudyTypes() {
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    createColumnCategoryMapperSize() {
      return [
        { slug: '>100k', start: 100_000 },
        { slug: '50-100k', start: 50_000 },
        { slug: '40-50k', start: 40_000 },
        { slug: '30-400k', start: 30_000 },
        { slug: '25-30k', start: 25_000 },
        { slug: '20-25k', start: 20_000 },
        { slug: '15-20k', start: 15_000 },
        { slug: '10-15k', start: 10_000 },
        { slug: '7.5-10k', start: 7_500 },
        { slug: '5-7.5k', start: 5_000 },
        { slug: '<5k', start: 0 },
      ]
    },
    isShowCumulativeShareFloorAreaOrShowCumulativeShareBuildings() {
      return this.showCumulativeShareFloorArea || this.showCumulativeShareBuildings
    },
  },
  watch: {
    lastJurisdictionVisited: {
      handler() {
        this.loadingTime = new Date().valueOf()
        this.isLoading = true
      },
    },
    assumptionsDrawerOptions: {
      handler(drawerOptions) {
        const middleContainer = document.getElementById('middle-side')
        if (drawerOptions.visibility) {
          middleContainer.style.setProperty('--assumptions-width', `${342}px`)
        } else {
          middleContainer.style.setProperty('--assumptions-width', `${0}px`)
        }
      },
      deep: true,
    },
    bpsBuildingData: {
      handler(newData) {
        if (this.isLoading && !newData?.length) {
          return
        }
        this.floorAreaData = []
        this.buildingsData = []
        this.initData(newData)
        this.updateChartOptions(this.chartOptions)
      },
    },
    isLoading: {
      handler(value, oldValue) {
        if (oldValue && !value) {
          this.floorAreaData = []
          this.buildingsData = []
          this.initData(this.bpsBuildingData)
          this.updateChartOptions(this.chartOptions)
        }
      },
    },
    chartOptions: {
      handler(newOptions) {
        this.updateChartOptions(newOptions)
      },
      immediate: true,
    },
    chartSeries: {
      handler(newOptions) {
        this.chartOptions.series = newOptions
        this.updateChartOptions(this.chartOptions)
      },
      deep: true,
    },
    chartDataLabels: {
      handler(newOptions) {
        this.chartOptions.dataLabels = newOptions
        this.updateChartOptions(this.chartOptions)
      },
      deep: true,
    },
    chartMarkerColors: {
      handler(newOptions) {
        this.chartOptions.markers = newOptions
        this.updateChartOptions(this.chartOptions)
      },
      deep: true,
    },
    chartSroke: {
      handler(newOptions) {
        this.chartOptions.stroke = newOptions
        this.updateChartOptions(this.chartOptions)
      },
      deep: true,
    },
    chartYAxis: {
      handler(newOptions) {
        this.chartOptions.yaxis = newOptions
        this.updateChartOptions(this.chartOptions)
      },
      deep: true,
    },
    categoriesFiltered: {
      handler(newOptions) {
        this.chartOptions.xaxis.categories = newOptions
        this.updateChartOptions(this.chartOptions)
      },
      deep: true,
    },
    selectedArea: {
      handler(newOptions) {
        this.chartOptions.chart.selection.xaxis = newOptions
      },
      deep: true,
    },
    showDataLabelsToggle() {
      this.$nextTick(() => {
        this.chartOptions = this.getChartOptions()
        this.chartSeries
      })
    },
    computedSelectedOptions(value) {
      this.updateAllSelectedState(value)
    },
    isCumulativeShareAccordionExpanded(newVal) {
      const baseMaxHeight = 450

      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.cumulativeShareCheckboxes) {
            const checkboxesHeight = this.$refs.cumulativeShareCheckboxes.offsetHeight || 0
            this.adjustedMaxHeight = `${baseMaxHeight - checkboxesHeight}px`
          } else {
            this.adjustedMaxHeight = `${baseMaxHeight}px`
          }
        })
      } else {
        this.adjustedMaxHeight = `${baseMaxHeight}px`
      }
    },
    isShowCumulativeShareFloorAreaOrShowCumulativeShareBuildings: {
      handler(newVal) {
        if (newVal) {
          this.isCumulativeShareActive = true
        } else {
          this.isCumulativeShareActive = false
        }
      },
    },
  },
  async mounted() {
    this.$eventBus.$on('bpsBuildingStocksLoaded', this.onBpsBuildingDataLoaded)

    let loadTries = 10
    const debounce = setInterval(() => {
      if (this.bpsBuildingData.length || loadTries < 0) {
        this.isLoading = false
        this.startAnalyticsWatchers()
        clearInterval(debounce)
      }
      loadTries--
    }, 500)

    this.initSelection()
    this.chartOptions = this.getChartOptions()
    this.mouseDown = false

    this.$nextTick(() => {
      this.$refs.psAccordionItemCumulativeShare.toggle()
      this.$refs.psAccordionItemBuildingType.toggle()
      this.measureTruncation()
      this.calculateTooltipLeftPosition()
    })
  },
  updated() {
    this.$nextTick(() => {
      this.measureTruncation()
      this.calculateTooltipLeftPosition()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('bpsBuildingStocksLoaded', this.onBpsBuildingDataLoaded)
    if (this.resizeObserver) {
      if (this.$refs.optionsContainer) {
        this.resizeObserver.unobserve(this.$refs.optionsContainer)
      }
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    childOf(c,p) { while((c=c.parentNode)&&c!==p); return !!c },
    isTitleItem(elem) {
      return Boolean(elem?.nodeName === 'LABEL' || elem?.classList?.contains('title') || elem?.classList?.contains('label'))
    },
    getElemNameTarget(elem, upCount = 0) {
      if (!elem || elem?.childElementCount > 5 && elem?.nodeName === 'DIV' || upCount > 4) {
        return null
      }

      if (this.isTitleItem(elem)) {
        return elem
      }

      const childTItleElem = [...elem.childNodes].find((i) => this.isTitleItem(i))
      if (childTItleElem && upCount > 0) {
        return childTItleElem
      }

      return this.getElemNameTarget(elem?.parentNode, upCount + 1)
    },
    extractElemName(elem) {
      let elemToExtractText = this.getElemNameTarget(elem)
      if (!elemToExtractText) return elem?.textContent?.split('  ')?.filter((i) => !['info_outline', 'expand_more', 'expand_less'].some((j) => i.includes(j)))?.map((i) => i?.trim())?.join(' ') || 'unknown'

      const texts = [(elemToExtractText?.childElementCount > 0 ? null : elemToExtractText), ...elemToExtractText.childNodes].map((i) => {
        return [...i?.classList || []]?.some((j) => j?.includes('material-') || j?.includes('-icon')) ? null : i?.innerText
      }).filter((i) => i?.length)

      return texts.join(' ')
    },
    gaEventHandler(target, isClick = false) {
      const targetClassList = [...target?.classList || []]
      const enabledClasses = ['psui-el-accordion-item', 'psui-el-accordion-item-header', 'psui-el-switch-button', 'track-ga-click', 'selection-right-axis', 'option-label']
      const chartElem = this.$refs.apexChart?.$el
      const isChartChild = this.childOf(target, chartElem)
      if (isChartChild || enabledClasses.some((c) => targetClassList?.includes(c)) || ['BUTTON', 'SELECT', 'INPUT'].includes(target?.nodeName)) {
        const elementName = target?.getAttribute('data-ga-elem-name') || this.extractElemName(target)
        let elementType = ['BUTTON', 'SELECT', 'INPUT'].includes(target?.nodeName) ? target.nodeName.toLowerCase() :
            target?.getAttribute('data-ga-elem-type') || null
        const specialClassesMapping = [
          {
            type: 'toggle',
            classes: ['psui-el-accordion-item', 'psui-el-accordion-item-header', 'psui-el-switch-button'],
          },
          {
            type: 'input',
            classes: ['option-label'],
          },
          {
            type: 'selection',
            classes: ['selection-right-axis'],
          },
          {
            type: 'left-sidebar',
            childOf: document.getElementById('left-side')
          },
          {
            type: 'right-sidebar',
            childOf: document.getElementById('right-side')
          },
          {
            type: 'chart',
            childOf: document.getElementById('middle-side')
          },
        ]
        specialClassesMapping.forEach((especialTypes) => {
          if ((especialTypes.classes && targetClassList.some((c) => especialTypes.classes.includes(c))) ||
              (especialTypes.childOf && this.childOf(target, especialTypes.childOf)))
          {
            elementType = elementType ? `${especialTypes.type}-${elementType}`: especialTypes.type
          }
        })

        const interactionValue = isClick && ['SELECT', 'INPUT'].includes(target?.nodeName) ? target?.checked ?? target?.value : undefined
        if (!isClick) {
          const hash = sha1(`${elementName}-${elementType}`)
          if (sessionStorage.getItem(hash)) {
            return
          }
          sessionStorage.setItem(hash, 'true')
        }
        this.gaEvent(GA_LABELS.BPS_INTERACTION, { jurisdiction_slug: this.lastJurisdictionVisited.slug, interaction_type: isClick ? 'click' : 'hover', element_name: elementName, element_type: elementType, interaction_value: interactionValue })
      }
    },
    startAnalyticsWatchers() {
      // GA hover event
      this.$refs.buildingContainer.addEventListener('mousemove', (e) => {
        this.gaEventHandler(e.target, false)
      })

      // GA Click Event
      this.$refs.buildingContainer.addEventListener('click', (e) => {
        this.gaEventHandler(e.target, true)
      })
    },
    debounce(func, debouncerName = 'default', timeout = 500) {
      if (this.debouncers[debouncerName]) {
        clearTimeout(this.debouncers[debouncerName])
      }
      this.debouncers[debouncerName] = setTimeout(async () => {
        this.debouncers[debouncerName] = null
        func()
      }, timeout)
    },
    isBuildingTypeSelected(slug) {
      return this.selectedOptions.includes(slug)
    },
    onBpsBuildingDataLoaded() {
      const debounceTime = 1_500 - (new Date().valueOf() - this.loadingTime || 0)
      this.debounce(() => {
        if (this.isLoading) {
          this.isLoading = false
        }
      }, 'removeLoading', debounceTime < 0 ? 0 : debounceTime)
    },
    updateAllSelectedState(value) {
      const totalOptions = this.options.length - 1
      const filteredValues = value.filter((option) => option !== this.seriesSlug.ALL)
      const selectedCount = filteredValues.length

      if (selectedCount === 0) {
        this.isAllBuildingTypeSelected = false
        if (this.$refs.allCheckbox) {
          this.$refs.allCheckbox.indeterminate = false
        }
      } else if (selectedCount === totalOptions) {
        this.isAllBuildingTypeSelected = true
        if (this.$refs.allCheckbox) {
          this.$refs.allCheckbox.indeterminate = false
        }
      } else {
        this.isAllBuildingTypeSelected = false
        if (this.$refs.allCheckbox) {
          this.$refs.allCheckbox.indeterminate = true
        }
      }

      this.isBuildingTypeActive = Boolean(selectedCount)
      if (!selectedCount && !value.find((i) => i === this.seriesSlug.ALL)) {
        this.selectedOptions = [this.seriesSlug.ALL]
      } else if (selectedCount && value.find((i) => i === this.seriesSlug.ALL)) {
        this.selectedOptions = value.filter((i) => i !== this.seriesSlug.ALL)
      }
    },
    formatFooterCellValue(item) {
      // const isAllNotSelected = this.selectedOptions.every((slug) => slug == this.seriesSlug.ALL)
      // if (isAllNotSelected && (item.header_slug == this.selectionNumberSlug || item.header_slug == this.selectionFloorAreaSlug)) {
      //   return '--'
      // }
      return item.value ?? item
    },
    formatBodyCellValue(index, headerSlug, dt) {
      const finded = this.selectedOptions.includes(dt.slug) || (this.selectedOptions.length === 1 && this.selectedOptions.includes(this.seriesSlug.ALL))
      if (!finded && !this.selectedOptions.every((value) => value == undefined) && (headerSlug == this.selectionNumberSlug || headerSlug == this.selectionFloorAreaSlug)) {
        return '--'
      }

      if (headerSlug == this.totalNumberSlug || headerSlug == this.selectionNumberSlug) {
        return this.formatUnits(this.getTableRows[index][headerSlug])
      }
      
      return this.getTableRows[index][headerSlug]
    },
    initSelection() {
      const initChartInterval = setInterval(() => {
        const selectionRightAxis = document.getElementById('selection-right-axis')
        const selectionLeftAxis = document.getElementById('selection-left-axis')
        if (this.$refs.apexChart?.chartInstance && selectionRightAxis && selectionLeftAxis) {
          this.handleSelection({
            min: selectionLeftAxis.getBoundingClientRect().x,
            max: selectionRightAxis.getBoundingClientRect().x,
          })
          clearInterval(initChartInterval)
        }
      }, 1000)
    },
    setAccumulatedData(data) {
      let sum = 0

      return data.map((item) => {
        return {
          ...item,
          data: item.data.map((item) => {
            sum += item.y
            if (item.x != 'All') {
              return {
                x: item.x,
                y: sum,
              }
            }
            return item
          }),
        }
      })
    },
    initData(generalData) {
      this.floorAreaData = []
      this.buildingsData = []

      const floorAreaKey = 'floor_area'
      const buildingsKey = 'units'
      const findIndexPosition = (value) => {
        const finded = this.createColumnCategoryMapperSize.findIndex((el) => {
          return (
            el ===
            this.createColumnCategoryMapperSize
              .filter((el) => {
                return value >= el.start
              })
              .shift()
          )
        })

        return finded == -1 ? 9 : finded
      }

      generalData.forEach((bps) => {
        const typePrototype = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({
          id: bps.type_prototype_id,
        })

        const floorAreafinded = this.floorAreaData?.find((item) => typePrototype && item?.slug == typePrototype?.slug)
        const buildingFinded = this.buildingsData?.find((item) => typePrototype && item?.slug == typePrototype?.slug)

        const dataIndex = findIndexPosition(bps?.[floorAreaKey])

        if (!floorAreafinded || !buildingFinded) {
          let baseFloorData = new Array(11).fill(0, 0)
          let baseBuildingData = new Array(11).fill(0, 0)

          baseFloorData.splice(dataIndex, 1, bps[floorAreaKey] * bps[buildingsKey])
          baseBuildingData.splice(dataIndex, 1, bps[buildingsKey])
          this.floorAreaData.push({
            name: typePrototype.title,
            slug: typePrototype.slug,
            data: [...baseFloorData],
          })

          this.buildingsData.push({
            name: typePrototype.title,
            slug: typePrototype.slug,
            data: baseBuildingData,
          })
        } else {
          const prototypeIndex = this.floorAreaData.findIndex((data) => data.slug == floorAreafinded.slug)

          floorAreafinded.data.splice(dataIndex, 1, floorAreafinded.data[dataIndex] + bps?.[floorAreaKey] * bps?.[buildingsKey])
          buildingFinded.data.splice(dataIndex, 1, buildingFinded.data[dataIndex] + bps?.[buildingsKey])

          this.floorAreaData.splice(prototypeIndex, 1, floorAreafinded)
          this.buildingsData.splice(prototypeIndex, 1, buildingFinded)
        }
      })
      this.selectedOptions = this.buildingsData.map((building) => building.slug)
    },
    setOrderColumn(column) {
      if (this.orderByColumn === column) {
        this.orderByDirection = this.orderByDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.orderByColumn = column
        this.orderByDirection = 'asc'
      }
    },
    getRealMinAndMaxIdx({ min, max }, chartContext) {
      if (typeof min !== 'number' || typeof max !== 'number' || isNaN(min) || isNaN(max)) {
        return { min: 0, max: 0 }
      }

      const { stepSize } = this.getXAxisProperties(chartContext)
      const barHalfSize = (stepSize ?? 1) / 4
      const barStartPoint = 1 - barHalfSize

      const truncMin = Math.trunc(min)
      const truncMax = Math.trunc(max)

      const newMin = min - truncMin > barHalfSize ? truncMin : truncMin - 1

      const maxDifference = max - truncMax
      const newMax = truncMax - 1 + (maxDifference < barStartPoint ? 0 : 1)

      return {
        min: newMin,
        max: newMax,
      }
    },
    getChartOptions() {
      const self = this
      return {
        series: self.chartSeries,
        dataLabels: self.chartDataLabels,
        stroke: self.chartSroke,
        yaxis: self.chartYAxis,
        markers: self.chartMarkerColors,
        chart: {
          type: 'line',
          width: '100%',
          height: '480px',
          stacked: true,
          fontFamily: 'Lato, Helvetica Neue, sans-serif',
          foreColor: '#798490',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          pan: {
            enabled: false,
          },
          selection: {
            enabled: false,
          },
          events: {
            beforeZoom: function (chartContext, { xaxis }) {
              return {
                xaxis: {
                  min: chartContext.w.globals.minX,
                  max: chartContext.w.globals.maxX,
                },
              }
            },
            updated: () => {
              this.$refs?.apexSelection?.init(true)
              this.downloadChartSize = this.$el?.clientWidth || 0
            },
          },
        },
        xaxis: {
          type: 'category',
          categories: self.categoriesFiltered,
          labels: {
            show: true,
            rotate: -75,
            rotateAlways: true,
            style: {
              colors: ['#798490'],
              fontSize: '12px',
              fontFamily: 'Lato, Helvetica Neue, sans-serif',
              fontWeight: 700,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          axisBorder: {
            show: true,
            color: '#A2ACB7',
            height: 1,
            width: '100%',
          },
          tooltip: {
            enabled: false,
          },
          axisTicks: { show: false },
          crosshairs: {
            position: 'front',
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          active: {
            filter: { type: 'none' },
            allowMultipleDataPointsSelection: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
            borderRadius: 1,
          },
          dataLabels: {
            position: 'top',
          },
          stacked: true,
        },
        grid: {
          show: true,
          borderColor: '#E8E8E8',
          strokeDashArray: 0,
          position: 'back',
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: false,
        },
        fill: { opacity: 1 },
        tooltip: {
          enabled: true,
          intersect: true,
          shared: false,
          custom: function ({ seriesIndex, dataPointIndex, w }) {
            const series = self.chartSeries[seriesIndex]
            if (!series || !series.data || !series.data[dataPointIndex]) {
              return ''
            }

            const item = series.data[dataPointIndex]
            const category = self.categories[dataPointIndex]
            const value = item.y
            if (!item || !category || value === undefined) {
              return ''
            }

            const isLastIndex = dataPointIndex === self.categories.length - 1
            const cumulativeShareDisplayText = isLastIndex ? 'All Building Size' : `Larger than ${self.getCumulativeShareFullCategoryLabel(category)} ft²`
            const buildingsDisplayText = isLastIndex
              ? 'Smaller than 5K ft²'
              : dataPointIndex === 0
              ? `Larger than ${self.getBuildingsFullCategoryLabel(category)} ft²`
              : `${self.getBuildingsFullCategoryLabel(category)} ft²`

            let htmlContent = ''

            if (series.group === 'floor-area-group') {
              const floorAreaTooltipColor = '#ece9c6'
              const floorAreaValue = self.getFloorAreaValue ? self.getFloorAreaValue(category) : ''
              htmlContent = `
                <div class="rounded-sm psui-shadow-elevation-30 flex" style="font-family: Lato, sans-serif; background-color: ${floorAreaTooltipColor} !important;">
                  <div class="w-full bg-white px-3 py-2 ml-2 flex flex-col">
                    <h4 class="psui-text-gray-80 psui-text-small">${cumulativeShareDisplayText}</h4>
                    <h4 class="psui-text-gray-80 psui-text-small font-bold">${self.formatNumber(value)}% of all floor area</h4>
                    <h4 class="psui-text-gray-60 psui-text-small">${floorAreaValue} ft² of floor area</h4>
                  </div>
                </div>
              `
            } else if (series.group === 'buildings-group') {
              const buildingsTooltipColor = '#d6f5f2'
              const floorAreaValue = self.getFloorAreaValue ? self.getFloorAreaValue(category) : ''
              htmlContent = `
                <div class="rounded-sm psui-shadow-elevation-30 flex" style="font-family: Lato, sans-serif; background-color: ${buildingsTooltipColor} !important;">
                  <div class="w-full bg-white px-3 py-2 ml-2 flex flex-col">
                    <h4 class="psui-text-gray-80 psui-text-small">${cumulativeShareDisplayText}</h4>
                    <h4 class="psui-text-gray-80 psui-text-small font-bold">${self.formatNumber(value)}% of all buildings</h4>
                    <h4 class="psui-text-gray-60 psui-text-small">${floorAreaValue} ft² of floor area</h4>
                  </div>
                </div>
              `
            } else if (series.group === 'main-group') {
              const buildingCount = value
              const percentage = self.getPercentageOfType ? self.getPercentageOfType(series.slug, dataPointIndex) : ''
              htmlContent = `
                <div class="rounded-sm psui-shadow-elevation-30 flex" style="font-family: Lato, sans-serif; background-color: ${self.buildingColors[series.slug]} !important;">
                  <div class="w-full bg-white px-3 py-2 ml-2 flex flex-col">
                    <h4 class="psui-text-gray-80 psui-text-small">${buildingsDisplayText}</h4>
                    <h4 class="psui-text-gray-80 psui-text-small font-bold">${buildingCount} ${series.name}</h4>
                    <h4 class="psui-text-gray-60 psui-text-small">${percentage} of all ${series.name}</h4>
                  </div>
                </div>
              `
            } else if (series.group === 'all-group') {
              const buildingCount = value
              const allSeries = self.chartSeries.find((s) => s.slug === 'all')
              const totalCount = allSeries.data.reduce((acc, d) => acc + d.y, 0)
              const percentageValue = (buildingCount / totalCount) * 100
              const percentage = `${self.formatNumber(percentageValue)}%`
              htmlContent = `
                <div class="rounded-sm psui-shadow-elevation-30 flex" style="font-family: Lato, sans-serif; background-color: ${self.buildingColors[series.slug]} !important;">
                  <div class="w-full bg-white px-3 py-2 ml-2 flex flex-col">
                    <h4 class="psui-text-gray-80 psui-text-small">${buildingsDisplayText}</h4>
                    <h4 class="psui-text-gray-80 psui-text-small font-bold">${buildingCount} buildings</h4>
                    <h4 class="psui-text-gray-60 psui-text-small">${percentage} of all buildings</h4>
                  </div>
                </div>
              `
            }

            return htmlContent
          },
        },
      }
    },
    getCumulativeShareFullCategoryLabel(category) {
      const cleaned = category.trim().replace(/^>|^</, '')

      if (cleaned.includes('-')) {
        const parts = cleaned.split('-')
        const start = this.convertKToNumber(parts[0])
        return this.formatNumber(start)
      } else {
        const num = this.convertKToNumber(cleaned)
        return this.formatNumber(num)
      }
    },
    convertKToNumber(value) {
      const val = value.toLowerCase()
      const multiplier = 1000
      const num = parseFloat(val) * multiplier
      return isNaN(num) ? 0 : num
    },
    getBuildingsFullCategoryLabel(category) {
      return category.replace(/[<>]/g, '')
    },
    getFloorAreaValue(category) {
      const categoryIndex = this.categoriesFiltered.indexOf(category)
      if (categoryIndex === -1) {
        return 0
      }

      let total = 0
      this.floorAreaData.forEach((item) => {
        if (item.data && item.data.length > 0) {
          let sumByCategory = 0
          for (let i = 0; i <= categoryIndex; i++) {
            if (item.data[i] != null) {
              sumByCategory += item.data[i]
            }
          }
          total += sumByCategory
        }
      })

      return `${this.formatNumber(total)}`
    },
    getPercentageOfType(slug, dataPointIndex) {
      const buildingItem = this.buildingsData.find((b) => b.slug === slug)
      if (!buildingItem || !buildingItem.data || buildingItem.data.length === 0) {
        return '0%'
      }

      const total = buildingItem.data.reduce((acc, val) => acc + val, 0)
      const value = buildingItem.data[dataPointIndex] || 0

      if (total === 0) {
        return '0%'
      }

      const percentage = (value / total) * 100
      return `${this.formatNumber(percentage)}%`
    },
    getXAxisProperties(chartContext) {
      const xAxisMin = chartContext.w.globals.minX
      const xAxisMax = chartContext.w.globals.maxX
      const numberOfSteps = this.categoriesFiltered.length - 1
      const stepSize = (xAxisMax - xAxisMin) / numberOfSteps
      return { stepSize }
    },
    getNumberOfBars(xaxis, initialValue, spaceWidth, barWidth) {
      if (delta <= initialValue) return 0
      const delta = Math.trunc(xaxis.max - xaxis.min - initialValue)

      const denominator = Math.trunc(spaceWidth + barWidth)

      return Math.ceil(delta / denominator)
    },
    handleSelection(xaxis) {
      const apexInnerChartWidth = document.querySelector('.apexcharts-inner.apexcharts-graphical').getBoundingClientRect().width
      const barWidth = this.$refs.apexChart.chartInstance.w.globals.barWidth
      const spaceWidth = (apexInnerChartWidth - 11 * barWidth) / 11

      const seriesData = document.querySelector('.apexcharts-bar-series.apexcharts-plot-series > .apexcharts-series')
      const leftAxis = document.querySelector('.selection-left-axis')

      const initialValue = seriesData.getBoundingClientRect().x - leftAxis.getBoundingClientRect().x

      const maxAxisValue = this.getNumberOfBars(xaxis, initialValue, spaceWidth, barWidth) - 1

      this.selectedArea = {
        min: 0,
        max: maxAxisValue,
      }
      this.updateBuildingSizeSelectedData(this.selectedArea)
    },
    updateBuildingSizeSelectedData(xaxis) {
      const label = [...document.querySelectorAll('.apexcharts-xaxis > .apexcharts-xaxis-texts-g .apexcharts-text tspan')]?.[xaxis.max]?.textContent
      if (label && xaxis.max >= 0 && this.$refs?.apexSelection) {
        this.$refs.apexSelection.selectedMinimumBuildingSizeCategory = label
      }

      const chartContext = this.$refs.apexChart.chartInstance

      this.selectionRange = {
        min: xaxis.min,
        max: xaxis.max,
      }
      this.buildingSizeSelectedData = chartContext.data.twoDSeriesX.filter((ctg, index) => {
        return index >= xaxis.min && index <= xaxis.max && (ctg !== 'All' || (ctg === 'All' && this.selectedOptions.includes(this.seriesSlug.ALL)))
      })

      setTimeout(() => {
        if (!this.mouseDown) {
          this.showSideBar = true
          this.canChangeSelectedArea = false
          this.onMouseDownSelection = []
          this.selectedArea = xaxis
        }
      }, 100)
    },
    reduceData(loop, data, type) {
      if (!loop) loop = data.map((i) => i.slug)
      const result = loop.reduce((sum, option) => {
        const buildingData = data.find((building) => building.slug === option)
        return sum + (buildingData ? buildingData.data[buildingData.data.length - 1] : 0)
      }, 0)

      if (type == this.totalNumberSlug || type == this.selectionNumberSlug) {
        return this.formatUnits(result)
      }

      return this.formatNumber(result)
    },
    toggleTableVisibility() {
      this.isTableVisible = !this.isTableVisible
    },
    isSelected(slug) {
      return this.selectedOptions.includes(slug)
    },
    calculateTotal(data) {
      if (!data) return []
      return data.map((item) => {
        const total = item.data.reduce((sum, value) => sum + value, 0)
        return {
          ...item,
          data: [...item.data, total],
        }
      })
    },
    calculateCumulativePercentages(selectedData, property) {
      const total = selectedData.reduce((sum, data) => {
        return sum + data[property].reduce((acc, value) => acc + value, 0)
      }, 0)

      const cumulativeSum = this.categoriesFiltered.map((_, index) => {
        return selectedData.reduce((sum, data) => {
          return sum + data[property].slice(0, index + 1).reduce((acc, value) => acc + value, 0)
        }, 0)
      })

      const cumulativePercentages = cumulativeSum.map((sum) => (sum / total) * 100)
      return cumulativePercentages
    },
    generateZeroDataSeries() {
      return [
        {
          name: this.seriesName.ALL,
          type: 'bar',
          data: this.categoriesFiltered.map((category) => ({
            x: category,
            y: 0,
          })),
          color: this.buildingColors[this.seriesSlug.ALL],
          group: 'apexcharts-axis-0',
        },
        {
          name: this.seriesName.FLOOR_AREA,
          type: 'area',
          data: this.categoriesFiltered.map((category) => ({
            x: category,
            y: 0,
          })),
          color: this.buildingColors[this.seriesSlug.FLOOR_AREA],
          group: 'apexcharts-axis-1',
        },
        {
          name: this.seriesName.BUILDINGS,
          type: 'area',
          data: this.categoriesFiltered.map((category) => ({
            x: category,
            y: 0,
          })),
          color: this.buildingColors[this.seriesSlug.BUILDINGS],
          group: 'apexcharts-axis-1',
        },
      ]
    },
    getColor(index) {
      const colors = this.buildingSizeChartColors
      return colors[index % colors.length]
    },
    handleCheckboxChange(slug) {
      const isRemove = !!this.selectedOptions.find((s) => s === slug)
      if (isRemove) {
        this.selectedOptions = this.selectedOptions.filter((s) => s !== slug)
      } else {
        this.selectedOptions = [...this.selectedOptions, slug]
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + 'px'
      el.style.padding = el.scrollPaddingBottom + 'px'
    },
    end(el) {
      el.style.height = ''
      el.style.padding = ''
    },
    openAssumptionsDrawer() {
      const tabSelected = this.getStudyTypes.findInArray({ slug: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS })
      this.$store.dispatch('setUserLastStudyTypeVisited', { value: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS, context: 'updateLastStudyTypeVisitedFromBuildingSizeChart' })
      this.$store.commit('assumptions/openDrawer', tabSelected)
      this.$store.commit('assumptions/updateDrawerPath', ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS)
    },
    updateChartOptions(newOptions) {
      let tries = 10

      const interval = setInterval(() => {
        if (this.$refs.apexChart) {
          this.$refs.apexChart.setOptions(newOptions)

          return clearInterval(interval)
        }
        tries--
        if (tries <= 0) clearInterval(interval)
      }, 100)
    },
    toggleAllBuildingTypes() {
      if (this.isAllBuildingTypeSelected) {
        this.selectedOptions = this.options.map((option) => option.slug).filter((slug) => slug !== this.seriesSlug.ALL)
      } else {
        this.selectedOptions = []
      }
    },
    toggleCumulativeShare() {
      this.gaEventHandler(this.$refs.psAccordionItemCumulativeShare.$el, true)
      this.isCumulativeShareActive = !this.isCumulativeShareActive

      if (this.isCumulativeShareActive && !this.isCumulativeShareAccordionExpanded) {
        this.$refs.psAccordionItemCumulativeShare.toggle()
        this.isCumulativeShareAccordionExpanded = true
      }

      if (!this.isCumulativeShareActive) {
        this.showCumulativeShareFloorArea = false
        this.showCumulativeShareBuildings = false
        this.showDataLabelsToggle = false
      } else {
        this.showCumulativeShareFloorArea = true
        this.showCumulativeShareBuildings = true
      }
    },
    toggleCumulativeShareAccordionItem() {
      this.$refs.psAccordionItemCumulativeShare.toggle()
      this.isCumulativeShareAccordionExpanded = !this.isCumulativeShareAccordionExpanded
    },
    toggleBuildingType() {
      this.gaEventHandler(this.$refs.psAccordionItemBuildingType.$el, true)
      this.isBuildingTypeActive = !this.isBuildingTypeActive

      if (this.isBuildingTypeActive && !this.isBuildingTypeAccordionExpanded) {
        this.$refs.psAccordionItemBuildingType.toggle()
        this.isBuildingTypeAccordionExpanded = true
      }

      if (!this.isBuildingTypeActive) {
        this.selectedOptions = []
      } else {
        this.selectedOptions = this.options.map((option) => option.slug).filter((slug) => slug !== this.seriesSlug.ALL)
      }
    },
    toggleBuildingTypeAccordionItem() {
      this.$refs.psAccordionItemBuildingType.toggle()
      this.isBuildingTypeAccordionExpanded = !this.isBuildingTypeAccordionExpanded
      this.$nextTick(() => {
        this.updateAllSelectedState(this.selectedOptions)
      })
    },
    measureTruncation() {
      this.sortedOptions.forEach((opt) => {
        const labelEl = this.$refs['label-' + opt.slug]
        if (labelEl) {
          this.$set(this.truncatedOptions, opt.slug, labelEl[0].scrollWidth > labelEl[0].clientWidth)
        }
      })
    },
    calculateTooltipLeftPosition() {
      const leftSideRect = document.getElementById('left-side').getBoundingClientRect()

      this.sortedOptions.forEach((opt) => {
        const labelEl = this.$refs['label-' + opt.slug]

        if (labelEl && labelEl[0]) {
          const labelRect = labelEl[0].getBoundingClientRect()

          let additionalOffset = 0

          switch (opt.slug) {
            case 'bps-office':
              additionalOffset = -24.5
              break
            case 'bps-strip-mall':
              additionalOffset = -2
              break
            default:
              additionalOffset = 0
          }

          const tooltipLeft = -(labelRect.left - leftSideRect.left) - additionalOffset
          this.$set(this.tooltipPositions, opt.slug, `${tooltipLeft}px`)
        }
      })

      this.generateTooltipStyles()
    },
    generateTooltipStyles() {
      let styles = ''

      for (const [slug, leftPos] of Object.entries(this.tooltipPositions)) {
        styles += `
        .tooltip-${slug} {
          left: ${leftPos} !important;
        }
      `
      }

      let styleTag = document.getElementById('dynamic-tooltip-styles')
      if (!styleTag) {
        styleTag = document.createElement('style')
        styleTag.id = 'dynamic-tooltip-styles'
        document.head.appendChild(styleTag)
      }

      styleTag.innerHTML = styles
    },
    getTooltipContent(option, index) {
      const isTruncated = this.truncatedOptions[option.slug] || false
      if (!isTruncated) {
        return { content: false, show: false }
      }

      const totalOptions = this.sortedOptions.length
      const isSecondToLast = index === totalOptions - 2
      const isTheLast = index === totalOptions - 1

      return {
        content: option.name,
        placement: 'bottom',
        classes: [isSecondToLast ? 'tooltip-second-to-last' : isTheLast ? 'tooltip-the-last' : 'custom-v-tooltip', `tooltip-${option.slug}`].join(' '),
      }
    },
    setExporting(value) {
      if (value) {
        this.prevExportState = {
          isTableVisible: this.isTableVisible,
        }
        this.isTableVisible = true
        this.isExporting = true
      } else {
        this.isTableVisible = this.prevExportState.isTableVisible
        this.prevExportState = null
        this.isExporting = false
        window.dispatchEvent(new Event('resize'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$character-title-85: rgba(0, 0, 0, 0.85);
$gray-80: #28323b;
$gray-10: #f3f6f9;
$gray-20: #e6ecf2;
$gray-white: #fff;
$border-color-light: rgba(0, 0, 0, 0.06);
$background-color-gray: #e6ecf2;

.text-base {
  color: $character-title-85;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.text-roboto {
  @extend .text-base;
  font-family: Roboto, sans-serif;
}

.text-lato {
  @extend .text-base;
  font-family: Lato, sans-serif;
}

@mixin checkbox-styles {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  margin-right: 4px;
  border: 1.5px solid #a2acb7;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  min-width: 13px;

  &:hover {
    border-color: #7f8a96;
  }

  &:focus {
    outline: 2px solid #5b9dd9;
    outline-offset: 2px;
  }

  &:checked {
    background-color: #a2acb7;
    border-color: #a2acb7;

    &:hover {
      background-color: #7f8a96;
      border-color: #7f8a96;
    }

    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: 2.5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
    }
  }
}

@mixin label-styles {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin accordion-base-styles {
  :deep(.psui-el-accordion-item) {
    border-bottom: none;
    width: 100%;

    .psui-el-accordion-item-header {
      padding-top: 8px;
      padding-right: 8px;
      padding-bottom: 8px;
      width: 100%;

      .psui-el-accordion-item-header-wrapper {
        .psui-el-accordion-item-icon {
          display: none;
        }
      }
    }

    .psui-el-accordion-item-content {
      overflow-y: auto;
      padding-right: 6px;
    }
  }
}

.building-container {
  margin-bottom: 20px;
  border-radius: 5px;
  background: #fff;
  flex-shrink: 0;

  .horizontal-line {
    border-top: 1px solid #d6dde5;
    width: 100%;
  }

  .header-container {
    padding: 0 16px 0 16px;

    a:hover {
      text-decoration: underline;
    }

    .self-stretch {
      align-self: stretch;
    }

    .existing-buildings-label {
      @extend .text-lato;
      color: $gray-80;
      font-size: 18px;
      letter-spacing: 120%;
      font-weight: 700;
    }

    .climate-zone-warn {
      margin-right: 12px;
      padding: 6px;
      align-self: start;
      justify-self: start;
      border-radius: 4px;
      background-color: $gray-10;
      display: inline-flex;
    }

    .heading-right-item {
      height: 100%;
      padding: 8px 12px;
      gap: 4px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      border-left: 1px solid $gray-20;

      &:last-of-type {
        padding-left: 24px;
        padding-right: 0;
      }

      .helper-icon {
        visibility: hidden;
      }

      &:hover {
        .helper-icon {
          visibility: visible;
        }
      }
    }

    .heading-right-item ~ .heading-right-item {
      padding: 8px 12px 8px 32px;
    }
  }

  #left-side {
    width: 100%;
    max-width: 230px;
    border-right: 1px solid #d6dde5;
    border-bottom: 1px solid #d6dde5;

    .options-container {
      display: flex;
      align-items: center;
      padding-left: 14px;
      border-bottom: 1px solid #d6dde5;

      .cumulative-options-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;

        .cumulative-share-label-container {
          position: relative;

          .cumulative-share-label {
            @extend .text-lato;
            font-size: 14px;
            font-weight: 700;
          }

          .cumulative-share-info-icon {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }

          &:hover .cumulative-share-info-icon {
            opacity: 1;
          }
        }

        .checkbox-container {
          display: flex;
          align-items: center;

          .cumulative-share-input {
            @include checkbox-styles;
          }

          .color-box {
            display: inline-block;
            margin-right: 2px;

            &.floor-area {
              border-radius: 50%;
              background: #ece9c6;
              width: 13px;
              height: 13px;
              min-width: 13px;
            }

            &.buildings {
              border-radius: 50%;
              background: #d7f5f3;
              width: 13px;
              height: 13px;
              min-width: 13px;
            }
          }
        }

        @include accordion-base-styles;
      }
    }

    .input-options-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 14px;
      position: relative;
      width: 100%;

      .building-type-label-container {
        position: relative;

        .building-type-label {
          @extend .text-lato;
          font-size: 14px;
          font-weight: 700;
        }

        .building-type-info-icon {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        &:hover .building-type-info-icon {
          opacity: 1;
        }
      }

      .input-checkbox-container {
        display: flex;
        align-items: center;

        .building-type-input {
          @include checkbox-styles;

          &:indeterminate {
            background-color: #a2acb7;
            border-color: #a2acb7;

            &::after {
              content: '';
              position: absolute;
              top: 5px;
              left: 0.5px;
              width: 9.5px;
              height: 1.5px;
              background-color: white;
            }
          }
        }

        .input-color-box {
          display: inline-block;
          margin-right: 2px;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          white-space: nowrap;
          min-width: 13px;

          &.all {
            background-color: #a2c1e2;
          }
        }

        .option-label {
          @include label-styles;
        }

        .building-types-info-icon {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        &:hover .building-types-info-icon {
          opacity: 1;
        }
      }

      @include accordion-base-styles;
      :deep(.psui-el-accordion-item-content) {
        max-height: v-bind('adjustedMaxHeight');
      }
    }
  }

  #middle-side {
    --assumptions-width: 0px;

    width: calc(100% - var(--assumptions-width));
    border-right: 1px solid #d6dde5;
    border-bottom: 1px solid #d6dde5;
  }

  #right-side {
    width: 100%;
    max-width: 139px;
    @media (min-width: 1180px) {
      max-width: 262px;
    }
    border-bottom: 1px solid #d6dde5;
  }

  .apex-chart-general {
    padding-right: 18px;
    padding-left: 18px;
  }

  .custom-padding {
    padding-left: 13px;
    padding-right: 62px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      border-right: 1.5px solid #d6dde5;
      height: calc(100% - 142px);
      right: 72px;
      top: 63px;
    }

    &::v-deep {
      .x-axis-title {
        margin-left: 49px;
      }
    }
  }

  .building-table-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    .building-table {
      border-spacing: 0;
      border-collapse: collapse;

      .header-label {
        @extend .text-lato;
        color: var(--Gray-Gray-80, #28323b);
        font-weight: 700;
        line-height: 130%;
        position: relative;

        .header-info-icon {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        &:hover .header-info-icon {
          opacity: 1;
        }
      }

      .footer-label {
        @extend .text-lato;
        color: var(--Gray-Gray-80, #28323b);
        font-weight: 700;
        line-height: 130%;

        &.fit-width {
          width: fit-content;
        }
      }

      thead {
        th {
          border: 1px solid $border-color-light;
          background: $background-color-gray;

          &:first-child {
            width: 60%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:not(:last-child) {
            border-right: 1px solid $border-color-light;
          }
        }
      }

      tbody {
        border-right: 1px solid $border-color-light;
        border-left: 1px solid $border-color-light;

        tr,
        td {
          padding-top: 7px;
          padding-right: 16px;
          padding-bottom: 7px;
          padding-left: 16px;
        }

        tr {
          border-bottom: 1px solid $border-color-light;
        }

        td {
          &:first-child {
            width: 60%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:not(:last-child) {
            border-right: 1px solid $border-color-light;
          }
        }
      }

      tfoot {
        border-right: 1px solid $border-color-light;
        border-bottom: 1px solid $border-color-light;
        border-left: 1px solid $border-color-light;

        th {
          background: $gray-10;
          padding-top: 7px;
          padding-right: 16px;
          padding-bottom: 7px;
          padding-left: 16px;

          &:first-child {
            width: 60%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:not(:last-child) {
            border-right: 1px solid $border-color-light;
          }
        }
      }

      &.show-side-bar {
        thead {
          th {
            &:first-child {
              width: 30%;
            }

            &:nth-child(2),
            &:nth-child(3) {
              width: auto;
            }
          }
        }

        tbody {
          td {
            &:first-child {
              width: 30%;
            }

            &:nth-child(2),
            &:nth-child(3) {
              width: auto;
            }
          }
        }

        tfoot {
          th {
            &:first-child {
              width: 30%;
            }

            &:nth-child(2),
            &:nth-child(3) {
              width: auto;
            }
          }
        }
      }
    }
  }

  .building-size-chart-table-fade-enter-active,
  .building-size-chart-table-fade-leave-active {
    will-change: height, margin-bottom;
    transition: height 0.4s ease, margin-bottom 0.4s ease-in-out;
    overflow: hidden;
  }

  .building-size-chart-table-fade-enter-from,
  .building-size-chart-table-fade-leave-to {
    height: 0 !important;
    margin-bottom: 0 !important;
  }

  &.exporting-to-pdf, .exporting-to-pdf {
    #left-side, #middle-side, #right-side {
      border-bottom-width: 0 !important;
    }

    .hide-on-export, ::v-deep .hide-on-export {
      display: none !important;
    }
  }

  > div.--add-to-pdf.exporting-to-pdf {
    max-width: 1280px;
    width: 1280px;
    min-width: 1280px;
  }
}

:deep(.apexcharts-datalabel) {
  text-shadow: 0px 1px 1px #fff;
  -webkit-text-stroke: 0.5px #fff;
}

.x-axis-title-container {
  .info-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .x-axis-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--Gray-Gray-70, #34404a);
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    position: relative;

    &:hover .info-icon {
      opacity: 1;
    }
  }
}

.text-content {
  color: var(--Gray-Gray-60, #515e6a);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
</style>

<style lang="scss">
@mixin tooltip-inner-styles {
  background-color: #798490 !important;
  padding: 4px 8px !important;
  line-height: 1.3 !important;
  width: fit-content !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  max-width: none !important;
  white-space: nowrap !important;
}

@mixin tooltip-base($top: null) {
  color: #ffffff;
  transition-delay: 0.1s !important;
  @if $top != null {
    top: $top !important;
  }

  .tooltip-inner {
    @include tooltip-inner-styles;
  }
}

.custom-v-tooltip {
  @include tooltip-base(-1px);
}

.tooltip-second-to-last,
.tooltip-the-last {
  @include tooltip-base(58px);
}
</style>
