/* eslint-disable no-unused-vars */
import PolicyRequirementsSummaryBase from '@/services/pdf/PolicyRequirementsSummaryPdf/PolicyRequirementsSummaryBase.js'
import { scaleDimensions, getBaseUrlImageDimensions } from '@/util/Helpers'
import imageLoader from '@policystudio/policy-studio-ui-vue/dist/util/imageLoader.js'
import dayjs from 'dayjs'

export default class BasePdfPortraitService extends PolicyRequirementsSummaryBase {
  jsPDFOptions = {
    orientation: 'p',
    unit: 'px',
    format: 'a4',
    compressPdf: true
  }

  constructor(args) {
    super(args)
    this.init(args)
  }

  async addBackgroundCover() {
    const pdfCoverBackgroundBase64 = await imageLoader({ imageUrl: `/images/pdf_cover_portrait_centralized.png`, returnsBase64: true })
    const { width: imgWidth, height: imgHeight } = await getBaseUrlImageDimensions(pdfCoverBackgroundBase64)
    const { width, height } = await scaleDimensions({ width: imgWidth, height: imgHeight, maxWidth: this.pageWidth, maxHeight: this.pageHeight })
    await this.doc.addImage(pdfCoverBackgroundBase64, 0, 0, this.pageWidth, height)
  }

  async addFirstPage() {
    this.addPage()
    const { pageNumber } = this.doc.internal.getCurrentPageInfo()
    this.doc.movePage(pageNumber, 1)

    await this.addBackgroundCover()
    this.addDocumentInfo()
    await this.addDocumentGeneratedInfo()

    const titleLines = this.title.split('\n').length
    const subtitleLines = this.subtitle.split('\n').length

    const lineHeight = 55 + (titleLines + subtitleLines - 2)

    const xStartPosition = 29
    const availableHeight = this.pageHeight - this.margins.top - this.margins.bottom
    const yStartPosition = this.margins.top + availableHeight / 2 - lineHeight / 2 - 40

    this.doc.setDrawColor(this.colors.blue02)
    this.doc.setLineWidth(2.5)
    this.doc.line(xStartPosition, yStartPosition, xStartPosition, yStartPosition + lineHeight)

    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.blue02)
    this.doc.setFontSize(16)
    this.doc.text(this.headline, xStartPosition + 12, yStartPosition, { baseline: 'top' })

    this.doc.setFont('Lato-Regular')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.setFontSize(22)
    this.doc.text(this.title, xStartPosition + 12, yStartPosition + 16, { baseline: 'top' })

    this.doc.setFontSize(22)
    this.doc.setFont('Lato-Light')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.text(this.subtitle, xStartPosition + 12, yStartPosition + 36, { baseline: 'top' })

    this.addPageGrid()
  }

  async addLastPage() {
    if (!this.includeSourcePage) return
    await this.addPage()
    await this.addBackgroundCover()
    this.addSourceNotes()
    this.addDocumentInfo()
    await this.addDocumentGeneratedInfo()
    await this.addPageGrid()
  }

  addSourceNotes({ yStartPosition = 90, xStartPosition = this.margins.left, gap = 10, sourceHeight = 40 } = {}) {
    const sourcesLength = this.sources?.length ?? 1
    const sourcesHeight = sourcesLength * sourceHeight
    const sourceTitleHeight = 22
    let blockHeight = gap + sourcesHeight

    const sourceTextXStartPosition = xStartPosition + gap

    const pageSizeWidth = this.doc.internal.pageSize.getWidth()

    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.setFontSize(11)
    this.doc.text('Sources', sourceTextXStartPosition, yStartPosition + gap + 5, { baseline: 'middle' })
    blockHeight += 20

    if (this.sources) {
      const sourceTextYStartPosition = yStartPosition + gap + sourceTitleHeight + gap / 2
      let currentYPosition = sourceTextYStartPosition
      let additionalHeight = 0

      this.sources.forEach((link, index) => {
        const currentSourceY = currentYPosition

        this.doc.setFont('Lato-Regular')
        this.doc.setFontSize(10)
        this.doc.setTextColor(this.colors.gray02)
        this.doc.text(`${index + 1}`, sourceTextXStartPosition, currentSourceY)

        this.doc.setFont('Lato-Bold')
        this.doc.setFontSize(10)
        this.doc.setTextColor(this.colors.gray02)
        this.doc.text(`${link.title} (${dayjs(link.released_at).format('MMMM DD, YYYY')})`, sourceTextXStartPosition + 9, currentSourceY)

        let subtitleOffset = 0
        if (link.subtitle) {
          this.doc.setFont('Lato-Regular')
          this.doc.setFontSize(9)
          this.doc.setTextColor(this.colors.gray02)
          this.doc.text(link.subtitle, sourceTextXStartPosition + 9, currentSourceY + gap)
          subtitleOffset = gap
        }

        const urlYPosition = currentSourceY + subtitleOffset + gap

        this.doc.setFont('Lato-Regular')
        this.doc.setFontSize(9)
        this.doc.setTextColor(this.colors.blue60)

        const availableWidth = pageSizeWidth - (sourceTextXStartPosition + 9) - this.margins.right - gap
        let urlLinesCount = 1

        if (this.doc.getTextWidth(link.url) > availableWidth) {
          const splittedLines = this.doc.splitTextToSize(link.url, availableWidth)
          const lineHeight = 8
          urlLinesCount = splittedLines.length

          splittedLines.forEach((line, lineIndex) => {
            this.doc.text(line, sourceTextXStartPosition + 9, urlYPosition + 2 + lineIndex * lineHeight)
          })
        } else {
          this.doc.text(link.url, sourceTextXStartPosition + 9, urlYPosition + 2)
        }

        const urlExtraHeight = (urlLinesCount - 1) * 8 // lineHeight

        currentYPosition = currentSourceY + sourceHeight + urlExtraHeight
        additionalHeight += urlExtraHeight
      })

      blockHeight += additionalHeight
    }

    this.doc.setDrawColor(this.colors.blue02)
    this.doc.setLineWidth(1.5)
    this.doc.line(xStartPosition, yStartPosition + 4, xStartPosition, yStartPosition + blockHeight - 13)
  }

  get generatedInfoText() {
    return `Generated ${dayjs().format('MMMM DD, YYYY')} from the Cost-Effectiveness Explorer`
  }

  async addDocumentGeneratedInfo() {
    const logoExplorerBase64 = await imageLoader({ imageUrl: `/logo/logo-state-program-light-background.png`, returnsBase64: true })
    const { width, height } = await getBaseUrlImageDimensions(logoExplorerBase64)
    const { width: logoWidth, height: logoHeight } = await scaleDimensions({ width, height, maxWidth: this.pageWidth, maxHeight: this.margins.top * 0.7 })

    const logoXPosition = this.pageWidth - this.margins.right - logoWidth
    const logoYPosition = this.pageHeight - 92 - logoHeight / 2

    await this.doc.addImage(logoExplorerBase64, logoXPosition, logoYPosition, logoWidth, logoHeight, 'logoStateProgramLightBackground')

    const imageVerticalCenter = logoYPosition + logoHeight / 2
    const textLineHeight = 9
    const firstTextY = imageVerticalCenter - textLineHeight / 2
    const secondTextY = imageVerticalCenter + textLineHeight / 2

    this.doc.setFont('Lato-Bold')
    this.doc.setFontSize(10)
    this.doc.setTextColor(this.colors.gray80)
    this.doc.text(this.generatedInfoText, 30, firstTextY)

    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(9)
    this.doc.setTextColor(this.colors.blue02)
    this.doc.textWithLink(this.sourceUrl, 30, secondTextY, { url: this.sourceUrl })
  }

  addDocumentInfo() {
    let str = `LEGAL NOTICE: This tool was prepared by Pacific Gas and Electric Company and funded by the California utility customers under the auspices of the California Public Utilities Commission. Copyright ${new Date().getFullYear()}, Pacific Gas and Electric Company. All rights reserved, except that information from this tool may be used, copied, and distributed without modification. Neither PG&E nor any of its employees makes any warranty, express or implied; or assumes any legal liability or responsibility for the accuracy, completeness or usefulness of any data, information, method, product, policy or process disclosed in this tool; or represents that its use will not infringe any privately-owned rights including, but not limited to, patents, trademarks or copyrights.`
    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(8)
    this.doc.setTextColor(this.colors.gray02)
    const splitted = this.doc.splitTextToSize(str, this.pageWidth - this.margins.right - this.margins.left - 10)

    const fontSize = 4
    const lineHeight = 1 * fontSize
    const splittedHeight = splitted.length * lineHeight

    const yPos = this.pageHeight - this.margins.bottom - splittedHeight
    this.doc.text(splitted, 30, yPos, { align: 'left' })
  }

  async addPageHeader() {
    const logoExplorerBase64 = await imageLoader({ imageUrl: `/images/logo_explorer.png`, returnsBase64: true })
    const { width, height } = await getBaseUrlImageDimensions(logoExplorerBase64)
    const { width: logoWidth, height: logoHeight } = await scaleDimensions({ width, height, maxWidth: this.pageWidth, maxHeight: this.margins.top * 0.5 })

    const logoYPosition = (this.margins.top - logoHeight) / 2
    await this.doc.addImage(logoExplorerBase64, this.margins.left, logoYPosition + 3, logoWidth, logoHeight, 'logoExplorer')

    this.doc.setDrawColor('#DBE8ED')
    this.doc.setLineWidth(0.6)

    const headerXPosition = this.pageWidth - this.margins.right
    const captionsYPositionStart = 20

    const marginGap = 10
    const logoBorderLineXPosition = this.margins.left + logoWidth + marginGap
    const logoBorderYPositionStart = logoYPosition
    const logoBorderYPositionEnd = logoYPosition + logoHeight + 6
    this.doc.line(logoBorderLineXPosition, logoBorderYPositionStart, logoBorderLineXPosition, logoBorderYPositionEnd)

    const captionsXPosition = logoBorderLineXPosition + marginGap

    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.blue80)
    this.doc.setFontSize(10)
    this.doc.text(this.headline, captionsXPosition, captionsYPositionStart, { baseline: 'bottom', align: 'left' })

    this.doc.setFont('Lato-Regular')
    const headlineWidth = this.doc.getTextWidth(this.headline) + 5
    this.doc.text(' (Draft - Not for distribution)', captionsXPosition + headlineWidth, captionsYPositionStart, { baseline: 'bottom', align: 'left' })

    const policyName = this.pageHeaderSubtitle
    this.doc.text(policyName, captionsXPosition, captionsYPositionStart + 10, { baseline: 'bottom' })

    const jurisdictionName = this.pageHeaderTitle
    this.doc.text(jurisdictionName, captionsXPosition, captionsYPositionStart + 20, { baseline: 'bottom' })
  }

  addPageFooter() {
    const pageSize = this.doc.internal.pageSize
    const xStartPos = this.margins.left
    const yStartPos = pageSize.getHeight() - this.margins.bottom + 14

    const yPosFirstRow = yStartPos
    const yPosSecondRow = yPosFirstRow + 8

    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(9)
    this.doc.setTextColor(this.colors.gray80)

    this.doc.text(this.generatedInfoText, xStartPos, yPosFirstRow)

    this.doc.setTextColor(this.colors.blue02)
    this.doc.textWithLink(this.sourceUrl, xStartPos, yPosSecondRow, { url: this.sourceUrl })
  }

  async addPageCount(currentPage, totalPages) {
    const pageSize = this.doc.internal.pageSize
    const xStartPos = pageSize.getWidth() - this.margins.right
    const yStartPos = pageSize.getHeight() - this.margins.bottom + 14
    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(9)
    this.doc.setTextColor(this.colors.gray80)

    this.doc.text(`Page ${currentPage}/${totalPages}`, xStartPos, yStartPos, { align: 'right' })
  }
}
