
import { getFourQuartersFromToday, getNewBuildingsDefaultStartDate } from '@/util/DateFunctions'

export const basicAssumptionKeys = [
  'start_at',
  'termination_date',
  'active_policy_duration',
  'annual_penetration_rate',
  'applicability_rate',
  'all_electric_shares_without_policy',
  'all_electric_shares_with_policy',
]

export const advancedAssumptionKeys = [
  'delay_installation',
  'grid_max_renewables_level',
  'grid_max_renewables_year',
  'current_grid_renewable_level',
  'current_kwh_emissions_factor',
  'current_therms_emissions_factor',
  'likelihood_installations_first_third',
  'likelihood_installations_second_third',
  'likelihood_installations_final_third',
  'care_program_enrollment_rate',
  'flexible_contingent_percentage'
]

export const defaultExistingBuildingsImpactAssumptionValues = {
  // Input Basic Assumptions
  annual_penetration_rate : 5,
  applicability_rate : 50,
  active_policy_duration : 5,
  start_at : getFourQuartersFromToday(),
  years : 30,
  
  // Input Advanced Assumptions
  delay_installation : 1,
  likelihood_installations_first_third: 0,
  likelihood_installations_second_third: 33,
  likelihood_installations_final_third: 66,
  grid_max_renewables_year: 2045,
  grid_max_renewables_level: 100,
  current_grid_renewable_level: 43.88,
  current_kwh_emissions_factor: 0.00021415,
  current_therms_emissions_factor: 0.0054544,
  care_program_enrollment_rate: 12,
  is_auto_generated: true,
  is_flexible_path: true,
  flexible_contingent_percentage: 10
}

export const defaultNewBuildingsImpactAssumptionValues = {
  // Input Basic Assumptions
  annual_penetration_rate : 100,
  applicability_rate : 100,
  delay_installation : 1.5,
  start_at : getNewBuildingsDefaultStartDate(),
  years : 30,
  termination_date: '12/31/2025',

  // Input Advanced Assumptions
  likelihood_installations_first_third: 0,
  likelihood_installations_second_third: 33,
  likelihood_installations_final_third: 66,
  grid_max_renewables_year: 2045,
  grid_max_renewables_level: 100,
  current_grid_renewable_level: 43.88,
  current_kwh_emissions_factor: 0.00021415,
  current_therms_emissions_factor: 0.0054544,
  is_auto_generated: true,
  is_flexible_path: true,

  // New Buildings sh
  all_electric_shares_without_policy: 25,
  all_electric_shares_with_policy: 25,
}


