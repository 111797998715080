<template>
  <div class="psui-w-full block pb-7"> 
    <div class="card-create-policy psui-bg-blue-20 psui-pl-6 psui-pr-8 psui-py-8">
      <div 
        class="psui-grid psui-gap-6"
        :class="{
          'psui-grid-cols-1' : getterContentContainerWidth <= 1024,
          'grid-template-columns-policies' : getterContentContainerWidth > 1024,
        }"
      >
        <div class="psui-flex psui-flex-col psui-items-start psui-space-y-6">
          <img
            class="info"
            style="width: 240px; height: 180px"
            src="/images/measure-by-measure.png"
          >
          <p class="psui-font-bold psui-text-h4 text-deepsky-900 psui-mb-2.5">
            {{ 'Create a policy and forecast the impact of reach codes in your city' | policyTextRephrase }}
          </p>
        </div>
        <ExploreStudyTypeCard
          :title="COPY.EXISTING_BUILDING_POLICY_WIDGET.TITLE"
          :description="COPY.EXISTING_BUILDING_POLICY_WIDGET.DESCRIPTION"
          :tooltip-policy-type-title="COPY.EXISTING_BUILDING_POLICY_WIDGET.TOOLTIP_TITLE"
          :prototypes-available="getPrototypesAvailableByPolicyOptionsAndStudyType(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)"
          @click="goToPolicyOptionsExistingBuildingsTab"  
        />
        <ExploreStudyTypeCard
          :title="COPY.NEW_BUILDING_POLICY_WIDGET.TITLE"
          :description="COPY.NEW_BUILDING_POLICY_WIDGET.DESCRIPTION"        
          :tooltip-policy-type-title="COPY.NEW_BUILDING_POLICY_WIDGET.TOOLTIP_TITLE"
          :prototypes-available="getPrototypesAvailableByPolicyOptionsAndStudyType(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)"
          @click="goToPolicyOptionsNewBuildingsTab"
        >
          <template #actions>
            <div class="flex space-x-4">
              <PsButton
                data-test-id="summary-explorer-policy-options-button"
                label="How to get ready"
                size="small"
                @click="goToGetReady"
              />
              <PsButton
                data-test-id="summary-explorer-policy-options-button"
                label="Overview of 2025 Code"
                size="small"
                layout="ghost"
                @click="goToReviewChanges"
              />
            </div>
          </template>
          <template #prototypes>
            <div />
          </template>
          <template #content>
            <hr
              v-if="isUserLoggedIn"
              class="divider psui-w-full psui-mt-3 psui-mb-4 psui-border-blue-20"
            >
            <div
              v-if="isUserLoggedIn"
              class="flex items-center justify-between w-full"
            >
              <router-link
                class="cursor-pointer psui-text-blue-60 psui-font-bold psui-text-small hover:psui-text-blue-70 psui-transition"              
                :to="getNewBuildingsExploreStudyResultsRoute"
              >
                Prior study results
              </router-link>
              <PsTooltip class="widget-tooltip">
                <template #trigger>
                  <span class="cursor-pointer py-1 px-1.5 psui-text-gray-50 psui-bg-gray-10 rounded-sm psui-text-xsmall font-bold">Code cycle: 2022</span>
                </template>
                <template #content>
                  Careful, the 2022 Code Cycle ends on December 31, 2025
                </template>
              </PsTooltip> 
            </div>
            <hr class="divider psui-w-full psui-mt-3 psui-mb-4 psui-border-blue-20">
          
            <div class="flex items-center justify-between w-full">
              <span
                class="psui-text-blue-60 psui-font-bold psui-text-small hover:psui-text-blue-70 psui-transition cursor-pointer"
                @click="goToPolicyOptionsNewBuildingsTab"
              >
                Prior policy options
              </span>

              <PsTooltip class="widget-tooltip">
                <template #trigger>
                  <span class="cursor-pointer py-1 px-1.5 psui-text-gray-50 psui-bg-gray-10 rounded-sm psui-text-xsmall font-bold">Code cycle: 2022</span>
                </template>
                <template #content>
                  Careful, the 2022 Code Cycle ends on December 31, 2025
                </template>
              </PsTooltip> 
            </div>
          </template>
        </ExploreStudyTypeCard>
      </div>
    </div>
  </div>
</template>

<script>
import { GA_LABELS, GA_EVENT_CATEGORIES } from '@/mixins/GaEventsMixin'
import { mapGetters } from 'vuex'
import { COPY, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import ExploreStudyTypeCard from '@/modules/app/jurisdiction/summary/shared/ExploreStudyTypeCard.vue'

export default {
  name: 'PoliciesListUserHasNoPolicies',
  components:{
    ExploreStudyTypeCard
  },
  data: () => ({
    COPY,
    GA_LABELS,
    GA_EVENT_CATEGORIES,
    STUDY_TYPES_DB_SLUGS,
  }),
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['getterLoggedUser']
    },
    jurisdiction() {
      return this.$store.getters['lastJurisdictionVisited'] ?? false
    },
    getPrototypesAvailableByPolicyOptions() {
      const getStudyGroupIdsByStudyType = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroups']({study_type_id:2})
      .map(study_group => study_group.id)

      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
      .filter(policy_option => policy_option.algorithms.some(alg => alg.study_type_slug === "new-buildings" )  && policy_option.prototypes.every(prototype => getStudyGroupIdsByStudyType.includes(prototype.study.study_group_id)))
      .map(policy_option => policy_option.prototypes)
      .reduce((acc,curr) => acc.concat(curr),[])
      .reduce((acc,curr) => {
        if(acc.length === 0 || !acc.includes(curr)){
          acc.push(curr)
        }
        return acc
      },[])
    },
    ...mapGetters({getterContentContainerWidth: 'general/getterContentContainerWidth'}),
  },
  methods: {
    goToGetReady(){
      window.open(
        'https://intercom.help/explorer-local-energy-codes/en/articles/5789919-considering-a-new-construction-reach-code-for-your-city-or-county-in-california', 
         '_blank'
        )
    },
    goToReviewChanges() {
      window.open(
        'https://intercom.help/explorer-local-energy-codes/en/articles/10011645-what-to-expect-the-2025-code-cycle', 
         '_blank'
        )
    },
    getNewBuildingsExploreStudyResultsRoute() {
      const excludedId = this.getLatestStudyIdByStudyType(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)

      return {
        name: 'StudyResults',
        params : { jurisdiction_slug : this.jurisdiction.slug }, 
        query : { 
          exclude_study_ids: `${excludedId}`,
          only_study_type: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
        }
      }
    },
    getLatestStudyIdByStudyType(studyTypeSlug){
      const studyType =  this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({slug: studyTypeSlug})
      
      const getPriorStudies = this.$store.getters['globalEntities/Study/getterStudiesByStudyType']({ study_type_id: studyType.id })
      .filter(study => study.published_at)
      .sort((a,b) => new Date(b.published_at) - new Date(a.published_at)) 

      const latestStudy = getPriorStudies.shift()

      return latestStudy?.id
    },
    openDownloadImpactModal() {
      this.$refs.baseModal.open()
    },
    closeDownloadImpactModal() {
      this.$refs.baseModal.showModal = false
    },
    goToPolicyOptionsExistingBuildingsTab() {
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromSummary',
        value: [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS]
      })

      this.$router.push({ name: 'PolicyOptions' })
    },
    goToPolicyOptionsNewBuildingsTab() {
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromSummary',
        value: [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]
      })

      this.$router.push({ name: 'PolicyOptions' })
    },
    getPrototypesAvailableByPolicyOptionsAndStudyType(study_type_slug) {
      const getStudyTypeByStudyTypeSlug = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({slug:study_type_slug})
      const getStudyGroupIdsByStudyType = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroups']({study_type_id:getStudyTypeByStudyTypeSlug.id})
      .map(study_group => study_group.id)

      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
      .filter(policy_option => policy_option.algorithms.some(alg => alg.study_type_slug === study_type_slug )  && policy_option.prototypes.every(prototype => getStudyGroupIdsByStudyType.includes(prototype.study.study_group_id)))
      .map(policy_option => policy_option.prototypes)
      .reduce((acc,curr) => acc.concat(curr),[])
      .reduce((acc,curr) => {
        if(acc.length === 0 || !acc.includes(curr)){
          acc.push(curr)
        }
        return acc
      },[])
    },
  }
}
</script>

<style lang="scss" scoped>

  .card-create-policy {
    border-radius: 10px;

    .divider {
      transform: translateX(-10px);
      width: calc(100% + 20px);
    }
  }

  .grid-template-columns-policies {
    grid-template-columns: 0.7fr 1fr 1fr;
  }

  ::v-deep .modal-dialog {
    padding: 32px;
    width: 440px;
  }

  .font-size-title {
    font-size: 20px;
  }

  .font-size-content {
    font-size: 16px;
  }
</style>
