<template>
  <div class="psui-w-full">
    <resize-observer @notify="onResize" />

    <div class="column-selector-wrapper psui-w-full psui-flex psui-flex-col psui-justify-start">
      <div class="psui-flex psui-flex-wrap psui--mx-2">
        <slot 
          v-if="$slots.headerPrepend" 
          name="headerPrepend"
        />
       
        <div 
          class="psui-px-2 psui-mb-6"
          :class="{
            'psui-block psui-w-3/5' : drawerWidth <= 1115,
            'psui-hidden' : drawerWidth > 1115 || isOnFullscreen,
            'xxl:hidden psui-mb-6 psui-max-w-xl psui-w-full' : !isProjectionDrawer
          }"
        >
          <p 
            v-if="showTitle"
            class="psui-text-p psui-font-bold psui-text-gray-80 psui-mb-2"
          >	
            Impact Estimates	
          </p>

          <PsDropdown
            ref="measureDropdown"
            class="psui-w-full"
          >
            <template #dropdownTrigger>
              <div 
                data-test-id="policy-card-projection-dropdown-keys-list"
                class="psui-text-gray-60 psui-cursor-pointer psui-border psui-border-gray-30 psui-rounded-md psui-px-4 psui-py-2 hover:psui-border-blue-50 psui-transition psui-duration-300 psui-ease-in-out"
              >
                <div class="psui-flex psui-items-center psui-mt-px psui-mb-px opacity-100-all-childrens-on-hover">
                  <p class="psui-text-p psui-font-bold">
                    {{ getColumnTitle }}
                    <span class="psui-text-gray-50 psui-font-bold">{{ getColumnSelected.description }}</span>
                  </p>
                  <PsIcon
                    v-if="getColumnSelected.hasHelper"
                    icon="help_outline"
                    size="16"
                    class="psui-text-blue-60 psui-opacity-0 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1 psui-mr-auto"
                    display="flex"
                    @click.native.stop="$eventBus.$emit('openDescriptionModal', { type: getColumnSelected.hasHelper.type, id: getColumnSelected.hasHelper.id, slug: getColumnSelected.hasHelper.slug })"
                  />
                  <span class="psui-text-normal psui-mr-4">{{ formatStudyData(getColumnSelected.key, getStudyDataLevel[getColumnSelected.key], getStudyDataLevel) }}</span>
                  <PsIcon
                    icon="expand_more"
                    size="24"
                    display="flex"
                    class="dropdown-expand psui-text-gray-50 psui-transition psui-transform"
                  />
                </div>
              </div>
            </template>

            <template #items>
              <PsDropdownList 
                :items="getOrderedColumns"
                :selected="getColumnSelected"
                :study-data="study_data"
                :right-label-formatter="formatStudyData"
                left-label="title"
                key-value="key"
                layout="rich"
                @change="setColumnSelected"
                @openDescriptionModal="openDescriptionModal"
              />
            </template>
          </PsDropdown>
        </div>

        <div 
          ref="tabsWrapper"
          class="tabs-wrapper psui-px-2 psui-gap-2 psui-grid"
          :class="{
            'psui-hidden': drawerWidth <= 1115 && !isOnFullscreen,
            'psui-flex psui-w-full' : drawerWidth > 1115 || isOnFullscreen,
            'psui-w-full xxl:grid' : !isProjectionDrawer && !isOnFullscreen
          }"
          :style="{'grid-template-columns': canShowColumnsInOneRow ? `repeat(${getOrderedColumns.length}, minmax(0, 1fr))` : undefined }"
        >
          <button
            v-for="(column, index) in getOrderedColumns"
            :key="index"
            class="tab-button psui-font-sans psui-text-p psui-flex psui-flex-col psui-text-left psui-p-3 psui-pl-5 psui-border rounded-sm focus:outline-none hover:psui-bg-blue-20 hover:psui-border-blue-20 opacity-100-all-childrens-on-hover"
            :class="column.key == getColumnSelected.key ? 'is-selected psui-border-blue-20 psui-bg-blue-10' : 'psui-bg-transparent psui-border-white'"
            :data-test-id="`policy-impact-card-projection-resume-card-${column.key}`"
            @click="setColumnSelected(column)"
          >
            <h3 class="tab-title psui-text-small psui-font-bold psui-relative psui-pr-5">
              {{ getColumnTitleOrdered(column) }}

              <PsIcon
                v-if="column.hasHelper"
                icon="help_outline"
                size="16"
                class="psui-text-blue-60 psui-opacity-0 psui-transition psui-leading-none psui-cursor-pointer psui-absolute psui-top-0 psui-right-0 psui-mt-px"
                display="flex"
                @click.native.stop="$eventBus.$emit('openDescriptionModal', { type: column.hasHelper.type, id: column.hasHelper.id, slug: column.hasHelper.slug })"
              />
            </h3>
            <h4 class="tab-description psui-text-xsmall psui-font-normal psui-text-gray-50">
              {{ column.description }}
            </h4>
            <h3
              class="tab-total psui-text-gray-60"
              :data-test-id="`policy-impact-card-projection-resume-total-value-${column.key}`"
            >
              {{ formatStudyData(column.key, getStudyDataLevel[column.key], study_data) }}
            </h3>
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="getProjections"
      class="psui-w-full psui-pt-4"
    > 
      <ChartProjection 
        :projections-by-year="getProjections" 
        :column-selected="getColumnSelected" 
        :columns="columns"
        :assumption="assumption"
        :disable-active-period="false"
      />
    </div>
  </div>
</template>

<script>
import ChartProjection from '@/components/projections/ChartProjection.vue'
import {
  forecast_units,
  forecast_emissions_savings,
  forecast_kwh_savings,
  forecast_therms_savings,
  forecast_lifecycle_savings,
  forecast_initial_cost,
  city_wide_subsidy_needed,
  city_wide_five_year_payback,
  city_wide_subsidy_needed_care,
  city_wide_five_year_payback_care,
} from '@/modules/app/shared/default-app-columns'
import { find } from '@/util/Functions'
import { updateColumnCopiesAndHelpers } from '@/util/Functions.js'

export default {
  name: 'CardProjection',
  components: { ChartProjection },
  props: {
    study_data: {
      required: true
    },
    study_data_instance: {
      required: false
    },
    assumption: {
      required: true
    },
    columnSelectedKey: {
      default: false
    },
    firstSelectedColumnKey: {
      default: 'forecast_units_affected'
    },
    isProjectionDrawer: {
      default: false
    },
    isOnFullscreen: {
      default: false
    },
    showTitle: {
      default: false
    }
  },
  data:()=>({
    isHovering: false,
    measureSelected: null,
    drawerWidth: null,
    canShowColumnsInOneRow: false,
  }),
  computed: {
    getColumnSelected() {
      const columnSelected = find(this.columns, { key: this.columnSelectedKey })
      if (columnSelected && this.getStudyId) {
        return updateColumnCopiesAndHelpers(this.getStudyId, columnSelected)?.column
      } else if(columnSelected) {
        return columnSelected
      }
      return this.columns[1]
    },
    getOrderedColumns() {
      return this.columns.map((item) => item).sort((item) => (item.key == this.firstSelectedColumnKey) ? -1 : 1)
    },
    getProjections() {
      return this.study_data_instance
    },
    getStudyId() {
      return this.study_data?.study_id || null
    },
    getColumnTitle() {
      if(this.getStudyId){
        return updateColumnCopiesAndHelpers(this.getStudyId, this.getColumnSelected).columnTitle 
      } else {
        return this.getColumnSelected?.title
      }
    },
    getStudyDataLevel() {
      return this.study_data?.data || this.study_data
    },
    columns() {
      let columns = [
        forecast_initial_cost(),
        forecast_emissions_savings(),
        forecast_units(),
        forecast_lifecycle_savings(),
        forecast_kwh_savings(),
        forecast_therms_savings(),
      ]

      const dynamicColumns = [
        city_wide_subsidy_needed(),
        city_wide_five_year_payback(),
        city_wide_subsidy_needed_care(),
        city_wide_five_year_payback_care(),
      ]
      dynamicColumns.forEach((column) => {
        if (this.study_data[column.key] !== undefined) {
          columns.push(column)
        }
      })
      return columns
    }
  },
  methods: {
    openDescriptionModal({type,id,slug}) {
      this.$eventBus.$emit('openDescriptionModal', {type,id,slug})
    },
    setColumnSelected(column) {
      this.$emit('update:columnSelectedKey', column.key)
    },
    onResize({ width }) {
      try {
        this.canShowColumnsInOneRow = false
        if (this.$refs.tabsWrapper) {
          let btnsSize = 0
          Array.from(this.$refs.tabsWrapper.children).forEach((x) => {
            const allInternalSizes = Array.from(x.children).map((xs) => {
              return xs.clientWidth + 36
            })
            btnsSize += (Math.max(...allInternalSizes) || 0)
          })
          this.canShowColumnsInOneRow = Boolean((this.$refs.tabsWrapper.clientWidth - 70 - btnsSize) >= 0)
        }
        this.drawerWidth = width
      } catch(e) {
        console.error(e)
      }
    },
    getColumnTitleOrdered(column) {
      if(this.getStudyId){
        return updateColumnCopiesAndHelpers(this.getStudyId, column).columnTitle
      } else {
        return column?.title
      }
    },
  }
}
</script>

<style scoped lang="scss">
  ::v-deep {
    .psui-el-dropdown-menu {
      &-dialog-wrapper {
        width: 100%;
        max-width: 36rem;
        margin-top: 12px;
      }

      &.is-open {
        .dropdown-expand {
          transform: rotate(180deg);
        }
      }
    }
  }

  .tabs-wrapper {
    &:not(.psui-grid) {
      display: flex;
      flex-wrap: wrap;

      &.psui-hidden {
        display: none;
      }
    }

    &.psui-grid {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

  .tab {
    &-button {
      transition: 300ms ease-in-out all;
      color: #979797;
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        width: 4px; height: 14px;
        position: absolute;
        top: 14px; left: 12px;
        background-color: transparent;
        transition: 300ms ease-in-out all
      }

      &.is-selected,
      &:hover {
        &::before {
          background-color: #318FAC;
        }

        .tab-title {
          color: #318FAC;
        }
      }
    }
  
    &-title,
    &-description {
      color: #798490;
    }

    &-title {
      margin-bottom: 0.188rem;
    }

    &-description {
      margin-bottom: 0.438rem;
    }

    &-total {
      color: #515E6A;
    }
  }

  .dropdown-impact {
    &-item {
      padding: 11.5px 16px 11.5px 8px;

      &.is-selected {
        .psui-border-b {
          border-color: #318FAC;
        }
      }
    }
  }

</style>
