// do not use, please use globalEntities: STUDY_TYPES_DB_SLUGS
// TODO - See where we are using this and convert to new methods
export const studyTypes = {
  NEW_BUILDINGS : 'New Buildings',
  EXISTING_BUILDINGS : 'Existing Buildings'
}

// do not use, please use globalEntities: STUDY_TYPES_DB_SLUGS
// TODO - See where we are using this and convert to new methods
export const STUDY_TYPES = {
  EXISTING_BUILDINGS: 1,
  NEW_BUILDINGS : 2,
}

/* jurisdictions */
/* ----------------------------------------- */
export const JURISDICTION_TYPES = {
  AQMD: 'AQMD',
  AREA: 'Area',
  CCA: 'CCA',
  CITY: 'City',
  CLIMATE_COLLABORATIVE: 'Climate Collaborative',
  CLIMATE_ZONE: 'Climate Zone',
  COG_CAG: 'COG/CAG',
  COUNTY: 'County',
  ENTIRE_COUNTY: 'Entire County',
  REN: 'REN',
  STATE: 'State',
  UTILITY: 'Utility',
} 
/* ----------------------------------------- jurisdictions */

/* Forecast */
/* ----------------------------------------- */
  // Our API parse the strings >1 & <1 from the spreasheet to this number below
  export const GREATER_THEN_ONE_NUMBER = 9876543210  
/* ----------------------------------------- Forecast */

/* Policies */
/* ----------------------------------------- */
  export const POLICIES_COMPARISON_SHOW_ONLY_DIFFERENT_ASSUMPTION_COLUMNS_PATH = 'policies.comparison.showOnlyDifferentAssumptionColumns'
/* ----------------------------------------- Policies */

/* 	Comparisons  */
/* ----------------------------------------- */
  export const COMPARISON_TYPES = {
    SHARED: { value: 'SHARED', label: 'Shared with me', label_for_table: 'Shared' },
    OWN: { value: 'OWN', label:  'Created by me', label_for_table: 'Me' }
  }
/* ----------------------------------------- 	Comparisons */

export const ERRORS = {
  USER_NOT_LOGGED_IN: 'USER_NOT_LOGGED_IN',
  USER_NOT_LOGGED_IN_DELETE_FAIL: 'USER_NOT_LOGGED_IN_DELETE_FAIL',
  CUSTOM_BUILDING_STOCK_NOT_SAVED: 'CUSTOM_BUILDING_STOCK_NOT_SAVED',
  CUSTOM_BUILDING_ESTIMATES_NOT_SAVED: 'CUSTOM_BUILDING_ESTIMATES_NOT_SAVED',
  USER_IS_NOT_COMPARISON_AUTHOR: 'USER_IS_NOT_COMPARISON_AUTHOR',
}

/* ASSUMPTIONS */
/*----------------------------------------- */
export const ASSUMPTIONS_DRAWER_DIVISION = {
  BASIC:'Basic',
  ADVANCED:'Advanced'
}

 export const ASSUMPTIONS_DRAWER_TYPES = {
  EXISTING_RESULTS: 'existingBuildingsResultsImpactAssumptions',
  NEW_RESULTS: 'newBuildingsResultsImpactAssumptions'
}

export const ASSUMPTIONS_PATHS = {
  IMPACT_RESULTS : {
    slug: 'impact-results',
    component: 'AssumptionsImpacts'
  },
  RESIDENTIAL_BUILDINGS : {
    title: 'Residential Buildings',
    description: 'New and Existing Units',
    slug: 'residental-buildings',
    component: 'AssumptionsResidential',
    icon: 'home_work'
  },
  NON_RESIDENTIAL_BUILDINGS : {
    title: 'Nonresidential',
    description: 'New and Existing Buildings',
    slug: 'non-residental-buildings',
    component: 'AssumptionsNonResidential',
    icon: 'corporate_fare'
  }
}

export const ASSUMPTIONS_KEYS = {
  annual_penetration_rate:'annual_penetration_rate',
  applicability_rate:'applicability_rate',
  termination_date:'termination_date',
  active_policy_duration:'active_policy_duration',
  start_at:'start_at',
  delay_installation:'delay_installation',
  likelihood_installations_first_third:'likelihood_installations_first_third',
  likelihood_installations_second_third:'likelihood_installations_second_third',
  likelihood_installations_final_third:'likelihood_installations_final_third',
  grid_max_renewables_year:'grid_max_renewables_year',
  grid_max_renewables_level:'grid_max_renewables_level',
  current_grid_renewable_level:'current_grid_renewable_level',
  current_kwh_emissions_factor:'current_kwh_emissions_factor',
  current_therms_emissions_factor:'current_therms_emissions_factor',
  all_electric_shares_without_policy:'all_electric_shares_without_policy',
  all_electric_shares_with_policy:'all_electric_shares_with_policy',
  care_program_enrollment_rate:'care_program_enrollment_rate',
  flexible_contingent_percentage:'flexible_contingent_percentage'
}
/*----------------------------------------- */


/* DATABASE SLUGS */
/* ----------------------------------------- */
  export const TYPE_FUELS_DB_SLUGS = {
    MIXED_FUEL : 'mixed-fuel',
    ALL_ELECTRIC : 'all-electric',
    ELECTRIFICATION : 'electrification',
  }

  export const STUDY_TYPES_DB_SLUGS = {
    EXISTING_BUILDINGS : 'existing-buildings',
    NEW_BUILDINGS : 'new-buildings'
  }

  export const POLICY_OPTIONS_DB_SLUGS = {
    EFFICIENCY: 'efficiency',
    ELECTRIC_ONLY: 'electric-only',
    ELECTRIC_ONLY_PLUS_EFFICIENCY: 'electric-only-plus-efficiency',
    MAX_ELECTRIC_PREFERENCE: 'max-electric-preference',
    HIGH_PERFORMANCE: 'high-performance',
    GENERAL_COST_EFFECTIVE_RETROFIT_ON_BILL: 'general-cost-effective-retrofit-on-bill',
    GENERAL_COST_EFFECTIVE_RETROFIT_TDV_OR_ON_BILL: 'general-cost-effective-retrofit-tdv-or-on-bill',
    PRESCRIPTIVE_ON_BILL: 'prescriptive-on-bill',
    FLEXIBLE_ON_BILL: 'flexible-on-bill',
    DEFAULT_STATE: 'default-state',
    DEFAULT_STATE_EXISTING: 'default-state-existing',
  }

  export const BUILDING_TYPE_DB_SLUGS = {
    RESIDENTIAL: 'residental-buildings',
    NON_RESIDENTIAL: 'non-residental-buildings'
  }

  export const TYPE_PROTOTYPES_DB_SLUGS = {
    MULTIFAMILY : 'multifamily-units',
    SINGLE_FAMILY : 'single-family-homes',
    ADU: 'adu',
    HOSPITAL_AND_OTHER: 'hospitals-and-other-health-treatment',
    HOTELS_AND_MOTELS: 'hotels-and-motels',
    MANUFACTURING_PLANTS: 'manufacturing-plants-warehouses-labs',
    OFFICE_AND_BANK_BUILDINGS: 'office-and-bank-buildings',
    SCHOOL_LIBRARIES_AND_LABDS: 'schools-libraries-and-labs-nonmfg',
    STORES_AND_RESTAURANTS: 'stores-and-restaurants',
  }

  export const PROTOTYPES_DB_SLUGS = {
    MULTIFAMILY : 'multifamily',
    SINGLE_FAMILY : 'single-family',
    ADU: 'adu'
  }
/* ----------------------------------------- DATABASE SLUGS */


/* Z-Index */
/* ----------------------------------------- */  
  // Content <= 20
  // App Header == 25
  // Primary Drawer <= 30
  // Secondary Drawer (Drawer that goes in top of primary drawers) <= 40
  // Primary Modal <= 50
  // Secondary Modal <= 60

  export const DRAWER_HIERARCHY = {
    PRIMARY : 'PRIMARY',
    SECONDARY : 'SECONDARY',
  }

  export const MODAL_HIERARCHY = {
    PRIMARY : 'PRIMARY',
    SECONDARY : 'SECONDARY',
  }
/* ----------------------------------------- Z-Index */

  export const CREATE_POLICY_MODAL_DESCRIPTION = 'If you want to set different assumptions values for each building type, first create a policy and add your applicable building types to it. From there you can access your policy assumptions, and specify values by different building types.' 
/* General Copies */
/* ----------------------------------------- */
  export const COPY = {
    EXISTING_BUILDING_POLICY_WIDGET : {
      TITLE: 'Explore Existing Buildings',
      DESCRIPTION: 'Start a policy for existing single family and multifamily units based on the latest study data, using a prescriptive or flexible compliance path. We will guide you step-by-step.',
      TOOLTIP_TITLE: 'Existing building',      
    },
    NEW_BUILDING_POLICY_WIDGET : {
      TITLE: 'New Construction',
      DESCRIPTION: 'Learn how you can get ready for the 2025 Code Cycle. Studies for single-family, multifamily, and nonresidential buildings are expected to be available by mid-2025.',
      TOOLTIP_TITLE: 'New construction',
    },
  }
/* ----------------------------------------- General Copies */

export const PROTOTYPE_SLUGS = {
  SINGLE_FAMILY_HOMES: 'sf',
  ADU: 'adu',
  STORY_MULTIFAMILY_BUILDING: '3-story-multifamily-building',
  MEDIUM_OFFICE: 'medium-office',
  QUICK_SERVICE_RESTAURANT: 'quick-service-restaurant',
}

export const ROUTE_EXCEPTIONS = {
  APP_HOME: 'AppHome',
  POLICIES_LIST: 'PoliciesList',
  COMPARISON_LIST: 'ComparisonList',
  COMPARISON_SHOW: 'ComparisonShow',
  ADMIN_SIGN_IN: 'AdminSignIn',
}

export const POLICY_TYPE_PDF = {
  COST_EFFECTIVENESS_EVIDENCE: 'Cost-Effectiveness Evidence',
  REQUIREMENTS_SUMMARY: 'Requirements Summary',
  FLEXIBLE_COMPLIANCE_TABLES: 'Flexible Compliance Tables',
  PRESCRIPTIVE_COMPLIANCE_TABLES: 'Prescriptive Compliance Tables',
  BUILDING_ESTIMATES: 'Building Estimates',
  RESULTS: 'Results'
}

export const FEATURE_FLAGS = {
  COLLECTIONS: 'COLLECTIONS'
}
