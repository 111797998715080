/* eslint-disable no-unused-vars */
import { jsPDF } from 'jspdf'
import { registerLatoBold } from '@/styles/fonts/Lato-Bold-normal.js'
import { registerLatoRegular } from '@/styles/fonts/Lato-Regular-normal.js'
import { registerLatoLight } from '@/styles/fonts/Lato-Light-normal.js'
import { scaleDimensions, getBaseUrlImageDimensions } from '@/util/Helpers'
import imageLoader from '@policystudio/policy-studio-ui-vue/src/util/imageLoader.js'
import dayjs from 'dayjs'
import MainVuex from '@/store'
import { jurisdictionTitleTypeBasedOnJurisdictionType } from '@/util/Helpers.js'

export default class BasePdfService {
  colors = {
    blue02: '#4B8CA6',
    blue05: '#E4EEF2',
    blue10: '#ECF7FB',
    blue20: '#E0EFF6',
    blue50: '#64B5CE',
    blue60: '#318FAC',
    blue80: '#002A3A',
    gray01: '#151C22',
    gray02: '#383B44',
    gray03: '#76848A',
    gray04: '#64666D',
    gray10: '#F3F6F9',
    gray20: '#E6ECF2',
    gray30: '#D6DDE5',
    gray50: '#798490',
    gray70: '#34404A',
    gray80: '#28323B'
  }

  sources = []

  margins = {
    top: 45,
    right: 20,
    left: 20,
    bottom: 35
  }

  lastTablePage = null
  debug = false
  jsPDFOptions = {
    orientation: 'l',
    unit: 'px',
    // format: [ this.pageWidth, this.pageHeight ]
    format: 'a4',
    compressPdf: true
  }
  includeSourcePage = true
  type = ''

  constructor(args) {
    this.init(args)
  }

  get filename() {
    const getPolicyIdentification = this.policy ? `(${this.policy_id}-${this.policy.title})` : ''
    return this.slugify(`Cost-Effectiveness Explorer ${this.type} ${this.title.replace(/\s+/g, '-')} ${getPolicyIdentification}`)
  }

  async init(args) {
    this.doc = args?.doc ?? new jsPDF(this.jsPDFOptions)
    const lastJurisdictionVisited = await MainVuex.getters['lastJurisdictionVisited']

    await this.registerProperties({
      ...args,
      lastJurisdictionVisited: lastJurisdictionVisited
    })
    await this.registerCustomFonts()
  }

  registerSourceStudy(study_id) {
    const getStudy = MainVuex.getters['globalEntities/Study/getterGlobalStudy']({ id: study_id })
    let studySource = {
      id: getStudy.id,
      title: getStudy.title,
      url: getStudy.source_pdf,
      subtitle: getStudy.pdf_export_source_description,
      released_at: getStudy.released_at
    }

    if (!this.sources.find((source) => source.id === getStudy.id)) {
      this.sources.push(studySource)
    }
  }

  registerProperties({ title, subtitle, sourceUrl, headline, debug, lastJurisdictionVisited }) {
    if (debug) this.debug = debug

    const formatTitle = (title) => {
      if (title.length >= 48) {
        const words = title.split(' ')
        let currentLine = ''
        let lines = []
        for (const word of words) {
          if (currentLine.length + word.length + 1 <= 48) {
            currentLine += ' ' + word
          } else {
            lines.push(currentLine.trim())
            currentLine = word
          }
        }
        lines.push(currentLine.trim())
        return lines.join('\n')
      }
      return title
    }

    if (lastJurisdictionVisited) {
      this.title = jurisdictionTitleTypeBasedOnJurisdictionType(lastJurisdictionVisited, 'title_type', null, true)
      this.title = formatTitle(this.title)
      this.pageHeaderTitle = lastJurisdictionVisited.titles.title_type
    } else if (title) {
      this.title = formatTitle(title)
      this.pageHeaderTitle = title
    }

    if (subtitle) {
      const subtitleWithoutClimateZone = subtitle.replace(/Climate\s+Zone\s+/i, '')
      const sWC = subtitleWithoutClimateZone.split(' ')
      const climateZoneText = sWC.length > 1 ? 'Climate Zones' : 'Climate Zone'
      const newSubtitle = `${climateZoneText} ${sWC.join(' ')}`

      const words = newSubtitle.split(' ')
      let currentLine = ''
      let lines = []
      for (const word of words) {
        if (currentLine.length + word.length + 1 <= 62) {
          currentLine += ' ' + word
        } else {
          lines.push(currentLine.trim())
          currentLine = word
        }
      }
      lines.push(currentLine.trim())
      this.subtitle = lines.join('\n')
      this.pageHeaderSubtitle = lines.join(' ')
    }

    this.sourceUrl = sourceUrl ?? window.location.href
    this.headline = headline ?? 'Cost-Effectiveness Results Summary'

    this.pageWidth = this.doc.internal.pageSize.getWidth()
    this.pageHeight = this.doc.internal.pageSize.getHeight()

    this.totalPagesExp = '{total_pages_count_string}'
  }

  registerCustomFonts() {
    registerLatoBold(this.doc)
    registerLatoRegular(this.doc)
    registerLatoLight(this.doc)
    this.doc.setFont('Lato-Regular')
  }

  async addPage({ internal = false } = {}) {
    this.doc.addPage()
    if (internal) {
      await this.addPageHeader()
      await this.addPageFooter()
    }
  }

  async addImageAsPage({ image, internal }) {
    const { width: printableWidth, height: printableHeight } = this.getThePrintableArea()
    const { width, height } = scaleDimensions({ width: image.width, height: image.height, maxWidth: printableWidth, maxHeight: printableHeight })

    const imageXPosition = (this.pageWidth - this.getHorizontalMargins() - width) / 2
    await this.doc.addImage(image, imageXPosition, this.margins.top, width, height)

    await this.addPageGrid()
    await this.addContentAreas()
  }

  async addImagesAsPages({ images }) {
    for (let index = 0; index < images.length; index++) {
      const image = images[index]
      await this.addBase64ImageAsPage({ image })
      if (index !== images.length - 1) {
        await this.addPage()
      }
    }
  }

  async addBase64ImageAsPage({ image, internal, alignX = true, alignY = true }) {
    const { width: printableWidth, height: printableHeight } = this.getThePrintableArea()
    const { width: imgWidth, height: imgHeight } = await getBaseUrlImageDimensions(image)

    const { width, height } = scaleDimensions({ width: imgWidth, height: imgHeight, maxWidth: printableWidth, maxHeight: printableHeight })

    let imageXPosition = this.margins.left
    let imageYPosition = this.margins.top
    if (alignX) {
      imageXPosition = (printableWidth - width) / 2 + this.margins.left
    }
    if (alignY) {
      imageYPosition = (printableHeight - height) / 2 + this.margins.top
    }

    await this.doc.addImage(image, imageXPosition, imageYPosition, width, height)
    await this.addPageGrid()
    await this.addContentAreas()
  }

  async addPageGrid({ column = 20, row = 20 } = {}) {
    if (this.debug == false) return

    const maxWidth = this.pageWidth

    this.doc.setFontSize(10)
    this.doc.setDrawColor('gray')
    this.doc.setLineWidth(0.3)

    let xPosition = 0
    while (xPosition <= maxWidth) {
      xPosition += column
      this.doc.line(xPosition, 0, xPosition, this.pageHeight)
      this.doc.text(`${xPosition}`, xPosition + 1, 3, { baseline: 'top' })
    }

    let yPosition = 0
    while (yPosition <= maxWidth) {
      yPosition += row
      this.doc.line(0, yPosition, this.pageWidth, yPosition)
      this.doc.text(`${yPosition}`, 3, yPosition + 1, { baseline: 'top' })
    }
  }

  async addBackgroundCover() {
    const pdfCoverBackgroundBase64 = await imageLoader({ imageUrl: `/images/pdf_cover_blank_updated.png`, returnsBase64: true })
    const { width: imgWidth, height: imgHeight } = await getBaseUrlImageDimensions(pdfCoverBackgroundBase64)
    const { width, height } = await scaleDimensions({ width: imgWidth, height: imgHeight, maxWidth: this.pageWidth, maxHeight: this.pageHeight })
    await this.doc.addImage(pdfCoverBackgroundBase64, 0, 0, this.pageWidth, height)
  }

  async addFirstPage() {
    this.addPage()
    const { pageNumber } = this.doc.internal.getCurrentPageInfo()
    this.doc.movePage(pageNumber, 1)
    await this.addBackgroundCover()

    this.addDocumentInfo()
    this.addDocumentGeneratedInfo()

    const xStartPosition = 29
    const yStartPosition = 180

    const titleLines = this.title.split('\n').length
    const subtitleLines = this.subtitle.split('\n').length

    const lineHeight = 90 + (titleLines + subtitleLines - 2) * 30

    this.doc.setDrawColor(this.colors.blue02)
    this.doc.setLineWidth(2.5)
    this.doc.line(xStartPosition, yStartPosition, xStartPosition, yStartPosition + lineHeight)
    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.blue02)
    this.doc.setFontSize(21)
    this.doc.text(this.headline, xStartPosition + 12, yStartPosition, { baseline: 'top' })

    this.doc.setFont('Lato-Regular')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.setFontSize(36)
    this.doc.text(this.title, xStartPosition + 12, yStartPosition + 28, { baseline: 'top' })

    this.doc.setFontSize(32)
    this.doc.setFont('Lato-Light')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.text(this.subtitle, xStartPosition + 12, yStartPosition + 66, { baseline: 'top' })
    this.addPageGrid()
  }

  async addLastPage() {
    if (!this.includeSourcePage) return
    await this.addPage()
    await this.addBackgroundCover()
    await this.addSourceNotes()
    this.addDocumentInfo()
    this.addDocumentGeneratedInfo()
    await this.addPageGrid()
  }

  addSourceNotes({ yStartPosition = 90, xStartPosition = 42, gap = 10, sourceHeight = 40 } = {}) {
    const sourcesLength = this.sources?.length ?? 1
    const sourcesHeight = sourcesLength * sourceHeight
    const sourceTitleHeight = 22
    let blockHeight = gap + sourcesHeight

    const sourceTextXStartPosition = xStartPosition + gap + 5

    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.setFontSize(17)
    this.doc.text('Sources', sourceTextXStartPosition, yStartPosition + gap + 5, { baseline: 'middle' })
    blockHeight += 20

    if (this.sources) {
      const sourceTextYStartPosition = yStartPosition + gap + sourceTitleHeight + gap
      this.sources.forEach((link, index) => {
        this.doc.setFont('Lato-Regular')
        this.doc.setFontSize(10)
        this.doc.setTextColor(this.colors.gray02)
        this.doc.text(`${index + 1}`, sourceTextXStartPosition, sourceTextYStartPosition + sourceHeight * index)

        this.doc.setFont('Lato-Bold')
        this.doc.setFontSize(10)
        this.doc.setTextColor(this.colors.gray02)
        this.doc.text(`${link.title} (${dayjs(link.released_at).format('MMMM DD, YYYY')})`, sourceTextXStartPosition + 9, sourceTextYStartPosition + sourceHeight * index)

        if (link.subtitle) {
          this.doc.setFont('Lato-Regular')
          this.doc.setFontSize(9)
          this.doc.setTextColor(this.colors.gray02)
          this.doc.text(link.subtitle, sourceTextXStartPosition + 9, sourceTextYStartPosition + gap + sourceHeight * index)
        }

        const urlYPosition = link.subtitle ? sourceTextYStartPosition + gap + sourceHeight * index + gap : sourceTextYStartPosition + sourceHeight * index + gap

        this.doc.setFont('Lato-Regular')
        this.doc.setFontSize(9)
        this.doc.setTextColor(this.colors.blue60)
        this.doc.text(link.url, sourceTextXStartPosition + 9, urlYPosition)
      })
    }

    // Draw the Blue Vertical line
    this.doc.setDrawColor(this.colors.blue02)
    this.doc.setLineWidth(1.5)
    this.doc.line(xStartPosition, yStartPosition + 4, xStartPosition, yStartPosition + blockHeight - 4)
  }

  get generatedInfoText() {
    return `Generated ${dayjs().format('MMMM DD, YYYY')} by the Cost-Effectiveness Explorer`
  }

  addDocumentGeneratedInfo() {
    this.doc.setFont('Lato-Bold')
    this.doc.setFontSize(10)
    this.doc.setTextColor(this.colors.gray02)
    this.doc.text(this.generatedInfoText, 30, this.pageHeight - 102)

    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(10)
    this.doc.setTextColor(this.colors.blue02)
    this.doc.textWithLink(this.sourceUrl, 30, this.pageHeight - 90, { url: this.sourceUrl })
  }

  addDocumentInfo() {
    let str = `LEGAL NOTICE: This tool was prepared by Pacific Gas and Electric Company and funded by the California utility customers under the auspices of the California Public Utilities Commission. Copyright ${new Date().getFullYear()}, Pacific Gas and Electric Company. All rights reserved, except that information from this tool may be used, copied, and distributed without modification. Neither PG&E nor any of its employees makes any warranty, express or implied; or assumes any legal liability or responsibility for the accuracy, completeness or usefulness of any data, information, method, product, policy or process disclosed in this tool; or represents that its use will not infringe any privately-owned rights including, but not limited to, patents, trademarks or copyrights.`
    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(8)
    this.doc.setTextColor(this.colors.gray02)
    const splitted = this.doc.splitTextToSize(str, this.pageWidth - 200)
    this.doc.text(splitted, 30, this.pageHeight - 50, { align: 'left' })
  }

  async addPageHeader() {
    const logoExplorerBase64 = await imageLoader({ imageUrl: `/images/logo_explorer.png`, returnsBase64: true })
    const { width, height } = await getBaseUrlImageDimensions(logoExplorerBase64)
    const { width: logoWidth, height: logoHeight } = await scaleDimensions({ width, height, maxWidth: this.pageWidth, maxHeight: this.margins.top * 0.5 })

    const logoYPosition = (this.margins.top - logoHeight) / 2
    await this.doc.addImage(logoExplorerBase64, this.margins.left, logoYPosition, logoWidth, logoHeight, 'logoExplorer')

    this.doc.setDrawColor('#DBE8ED')
    this.doc.setLineWidth(0.6)

    const marginGap = 10
    const logoBorderLineXPosition = this.margins.left + logoWidth + marginGap
    const logoBorderYPositionStart = logoYPosition
    const logoBorderYPositionEnd = logoYPosition + logoHeight
    this.doc.line(logoBorderLineXPosition, logoBorderYPositionStart, logoBorderLineXPosition, logoBorderYPositionEnd)

    const captionsXPosition = logoBorderLineXPosition + marginGap
    const captionsYPositionStart = 20

    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.gray03)
    this.doc.setFontSize(10)
    this.doc.text(this.headline, captionsXPosition, captionsYPositionStart, { baseline: 'bottom' })

    this.doc.setFont('Lato-Regular')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.setFontSize(12)
    let pageHeaderTitleAndSubtitle
    if (this.pageHeaderSubtitle) {
      pageHeaderTitleAndSubtitle = `${this.pageHeaderTitle} - ${this.pageHeaderSubtitle}`
    } else {
      pageHeaderTitleAndSubtitle = `${this.pageHeaderTitle}`
    }
    if (pageHeaderTitleAndSubtitle.length >= 126) {
      const words = pageHeaderTitleAndSubtitle.split(' ')
      let currentLine = ''
      let lines = []
      for (const word of words) {
        if ((currentLine + ' ' + word).length <= 126) {
          currentLine += ' ' + word
        } else {
          lines.push(currentLine.trim())
          currentLine = word
        }
      }
      lines.push(currentLine.trim())

      if (lines.length > 2) {
        lines[1] = lines[1] + '...'
      }

      pageHeaderTitleAndSubtitle = lines.slice(0, 2).join('\n')
    }

    this.doc.text(pageHeaderTitleAndSubtitle, captionsXPosition, captionsYPositionStart + 12, { baseline: 'bottom' })
    this.doc.setDrawColor(this.colors.blue05)
    this.doc.setLineWidth(0.5)
    this.doc.line(this.margins.left, this.margins.top, this.pageWidth - this.margins.right, this.margins.top)
  }

  addPageFooter() {
    this.doc.setDrawColor(this.colors.blue05)
    this.doc.setLineWidth(0.5)

    const pageSize = this.doc.internal.pageSize
    const xStartPos = this.margins.left
    const xPosLine = pageSize.getWidth() - this.margins.right
    const yPosLine = pageSize.getHeight() - this.margins.bottom - 5
    this.doc.line(xStartPos, yPosLine, xPosLine, yPosLine)
    const yPosFirstRow = pageSize.getHeight() - this.margins.bottom + 10
    const xPosRightText = pageSize.getWidth() - this.margins.right
    const xPosLeftText = this.margins.right

    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.blue80)
    this.doc.setFontSize(10)

    this.doc.text(this.type, xPosRightText, yPosFirstRow, { align: 'right' })

    this.doc.text(this.generatedInfoText, xPosLeftText, yPosFirstRow)

    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(9)
    this.doc.setTextColor(this.colors.blue02)
    this.doc.textWithLink(this.sourceUrl, xPosLeftText, pageSize.getHeight() - this.margins.bottom + 20, { url: this.sourceUrl })
  }

  async addPageCount(currentPage, totalPages) {
    const pageSize = this.doc.internal.pageSize
    const xPos = pageSize.getWidth() - this.margins.right
    const yPos = pageSize.getHeight() - this.margins.bottom + 20
    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(9)
    this.doc.setTextColor(this.colors.gray04)

    this.doc.text(`Page ${currentPage} of ${totalPages}`, xPos, yPos, { align: 'right' })
  }

  async addInternalPagesPattern() {
    const pages = this.doc.internal.getNumberOfPages()

    let currentContentPage = 1
    for (let currentDocumentPage = 1; currentDocumentPage <= pages; currentDocumentPage++) {
      if (!this.checkShouldAddInterPagePattern(currentDocumentPage)) continue
      this.doc.setPage(currentDocumentPage)
      await this.addPageCount(currentContentPage, this.getNumberOfContentPages())
      await this.addPageHeader()
      await this.addPageFooter()
      currentContentPage++
    }
  }

  checkShouldAddInterPagePattern(currentDocumentPage) {
    if (currentDocumentPage === 1) return false
    if (this.sources.length && currentDocumentPage === this.doc.internal.getNumberOfPages()) return false
    return true
  }

  getNumberOfContentPages() {
    const pages = this.doc.internal.getNumberOfPages()
    let removePages = 1
    if (this.sources.length) removePages++
    return pages - removePages
  }

  async preExport() {
    await this.addFirstPage()
    if (this.policyType) await this.addSecondPage()
    if (this.addInfoPage !== undefined) await this.addInfoPage()
    if (this.sources.length) await this.addLastPage()
    await this.addInternalPagesPattern()
  }

  async export() {
    await this.preExport()
    if (this.debug) {
      this.doc.output('dataurlnewwindow')
    } else {
      this.doc.save(this.filename)
    }
  }

  getThePrintableArea() {
    const pageWidth = this.doc.internal.pageSize.getWidth()
    const pageHeight = this.doc.internal.pageSize.getHeight()

    return {
      width: pageWidth - this.getHorizontalMargins(),
      height: pageHeight - this.getVerticalMargins()
    }
  }

  getHorizontalMargins() {
    return this.margins.left + this.margins.right
  }

  getVerticalMargins() {
    return this.margins.top + this.margins.bottom
  }

  addContentAreas() {
    if (this.debug == false) return

    const pageWidth = this.doc.internal.pageSize.getWidth()
    const pageHeight = this.doc.internal.pageSize.getHeight()

    const { width: printableWidth, height: printableHeight } = this.getThePrintableArea()
    this.doc.setLineWidth(1)

    // Printable Area
    this.doc.setDrawColor(255, 0, 0)
    this.doc.rect(this.margins.left, this.margins.top, printableWidth, printableHeight)

    // Header Area
    this.doc.setDrawColor(0, 255, 0)
    this.doc.rect(this.margins.left, 0, printableWidth, this.margins.top)

    // FooterArea
    this.doc.setDrawColor(0, 0, 255)
    this.doc.rect(this.margins.left, pageHeight - this.margins.bottom, printableWidth, this.margins.bottom)
  }

  onTableBreak(data) {
    if (data.pageCount > 1) {
      this.addContinuedAtTableTitle(data)
    }
  }

  addContinuedAtTableTitle(data) {
    const sufix = '  (Continued)'
    const currentTitle = data.table.head[1].cells[0].text[0]
    const modifiedTitle = data.table.head[1].cells[0].text + sufix

    if (currentTitle.indexOf(sufix) === -1) {
      data.table.head[1].cells[0].text = [modifiedTitle]
    }
  }

  slugify(text) {
    return text
      .toString() // Cast to string
      .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
      .trim() // Remove whitespace from both sides of a string
      .replace(/-+/g, '-') // Replace multiple - with single -
  }
}
