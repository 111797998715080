import PolicyRequirementsSummaryBase from '@/services/pdf/PolicyRequirementsSummaryPdf/PolicyRequirementsSummaryBase.js'
import Store from '@/store'
import { POLICY_TYPE_PDF } from '@/util/Enums.js'

export default class PolicyRequirementsSummaryExisting extends PolicyRequirementsSummaryBase {
  fontSizeTableDefault = 9
  cellPaddingTableDefault = { top: 4, right: 5, left: 6, bottom: 4 }
  type = POLICY_TYPE_PDF.REQUIREMENTS_SUMMARY
  headerTitle = POLICY_TYPE_PDF.REQUIREMENTS_SUMMARY
  headerSubtitle = POLICY_TYPE_PDF.PRESCRIPTIVE_COMPLIANCE_TABLES

  constructor(args) {
    super(args)
    return this.initPdf()
  }

  addTableHeaders() {
    this.tablesHeaders.push('Measures')
    this.getTypeVintages().forEach((type_vintage) => {
      this.tablesHeaders.push(type_vintage.title)
    })
  }

  getTypeVintages() {
    return Store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']().filter((type_vintage) => {
      return type_vintage.slug != 'post-2005'
    })
  }

  getTableBody(custom_combinations) {
    const measuresIds = custom_combinations.reduce((acc, cb) => {
      cb.measures.forEach((measure) => {
        if (!acc.includes(measure.id)) acc.push(measure.id)
      }, [])
      return acc
    }, [])

    const measures = Store.getters['globalEntities/Measure/getterGlobalMeasures']({ id: measuresIds })

    const rows = []

    for (const measure of measures) {
      const row = []
      row.push(measure.title)

      for (const type_vintage of this.getTypeVintages()) {
        let hasCbInThisMeasureAndTypeVintage = false
        custom_combinations.forEach((cb) => {
          const hasMeasure = cb.measures.findInArray({ id: measure.id })
          if (cb.vintage.type_vintage_id == type_vintage.id && hasMeasure) {
            hasCbInThisMeasureAndTypeVintage = true
          }
        })

        if (!hasCbInThisMeasureAndTypeVintage) {
          row.push('--')
        } else {
          row.push('Mandatory')
        }
      }
      rows.push(row)
    }
    return rows
  }
}
