var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full flex flex-col overflow-y-auto psui-bg-gray-10",attrs:{"id":"building-estimates-index"}},[_c('BuildingEstimatesHeader',{ref:"header",staticClass:"psui-sticky psui-top-0 psui-bg-white psui-shadow-elevation-5 psui-z-20 psui-mb-4",on:{"trigger-download":function($event){return _vm.download($event)}}}),_c('div',{staticClass:"psui-w-full psui-flex psui-flex-col psui-px-4 main-content pb-20"},[(_vm.getBuildingTypeSelected.slug == _vm.BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL)?_c('BuildingSizeChart',{ref:"bpsChart",attrs:{"get-building-type-selected":_vm.getBuildingTypeSelected}}):_vm._e(),_c('div',{class:{
        'psui-w-full psui-grid psui-grid-cols-5 psui-gap-6': _vm.getBuildingTypeSelected.slug === _vm.path.RESIDENTIAL_BUILDINGS.slug,
        'psui-w-full': _vm.getBuildingTypeSelected.slug === _vm.path.NON_RESIDENTIAL_BUILDINGS.slug
      }},[(_vm.getBuildingTypeSelected.slug === _vm.path.RESIDENTIAL_BUILDINGS.slug)?_c('div',{staticClass:"--add-to-pdf",class:{
          'psui-col-span-full': _vm.getterContentContainerWidth < 1000,
          'psui-col-span-2': _vm.getterContentContainerWidth >= 1000
        },attrs:{"attr-export-steps":"1","attr-export-width":`${_vm.html2canvasOptions.windowWidth / 4}px`,"attr-export-study-type":_vm.STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS}},[_c('ExistingUnitsDonutsChart',{attrs:{"get-type-prototypes-with-units":_vm.getTypePrototypesWithUnits,"override-colors":_vm.donutsColor}})],1):_vm._e(),_c('div',{staticClass:"--add-to-pdf",class:{
          'psui-col-span-full': _vm.getterContentContainerWidth < 1000,
          'psui-col-span-3': _vm.getterContentContainerWidth >= 1000
        },attrs:{"attr-export-steps":"2","attr-export-study-type":_vm.STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS}},[_c('FutureUnitsForecastChart',{attrs:{"get-type-prototypes-with-units":_vm.getTypePrototypesWithUnits,"get-climate-zone-selected":_vm.getClimateZoneSelected,"get-building-type-selected":_vm.getBuildingTypeSelected,"override-colors":_vm.getBuildingTypeSelected.slug == _vm.BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL ? _vm.alternativeColor : false}})],1)]),_c('div',{staticClass:"psui-w-full psui-mt-6"},[_c('TypePrototypeCardList',{ref:"prototypeCards",attrs:{"get-type-prototypes-with-units":_vm.getTypePrototypesWithUnits,"get-building-stock-total-units":_vm.getBuildingStockTotalUnits,"get-climate-zone-selected":_vm.getClimateZoneSelected,"get-building-type-selected":_vm.getBuildingTypeSelected,"palette-color-index":_vm.getBuildingTypeSelected.slug == _vm.BUILDING_TYPE_DB_SLUGS.RESIDENTIAL ? _vm.alternativeColor : _vm.combinationColors[0]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }