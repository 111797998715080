export const UtilityNameByClimateZoneSuffixMapper = {
    PGE: 'PG&E',
    SCE: 'SCE',
    SDGE: 'SDG&E',
    SMUD: 'SMUD',
    CPAU: 'CPAU',
    LADPW: 'LADWP',
    RIV: 'RPU',
    GWP: 'GWP',
    TDPUD: 'TDPUD',
    HEALD: 'HU',
    MCE: 'MCE',
    ALA: 'AMP',
    BUR: 'BWP',
    LIB: 'LIB',
}