<template>
  <div class="psui-w-full psui-h-full psui-min-h-screen psui-flex psui-flex-col psui-overflow-y-auto">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JurisdictionApiService from '@/services/api/JurisdictionApiService'

export default {
  name: 'JurisdictionIndex',
  computed: {
    routeHasJurisdictionSlug() {
      return this.$route?.params?.jurisdiction_slug ?? false
    },
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited'])
  },
  watch: {
    '$route.jurisdiction_slug' : {
      async handler() {
        if (this.lastJurisdictionVisited?.slug && !this.routeHasJurisdictionSlug) {
          this.pushToParams('jurisdiction_slug', this.lastJurisdictionVisited.slug)
        }
        if(this.$route.name === 'StudyResults') return this.getJurisdictionBySlug()
      },
      immediate: true
    }
  },
  mounted() {
    this.getJurisdictionBySlug()
  },
  methods: {

    getJurisdictionBySlug() {
      const jurisdiction_slug = this.$route.params.jurisdiction_slug || this.lastJurisdictionVisited?.slug
      if (!jurisdiction_slug) return this.$eventBus.$emit('openModalJurisdictionSelection', { skipRedirect: true })
      if (jurisdiction_slug && jurisdiction_slug != this.lastJurisdictionVisited?.slug) {
        JurisdictionApiService.getBySlug(jurisdiction_slug, 'JurisdictionIndex.getJurisdictionBySlug')
          .then(({ jurisdiction }) => {
            this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromJurisdictionIndex'})
          })
      }
    }

  }
}
</script>
