const canInit = Boolean(window.location.origin.includes('https://explorer.localenergycodes.com') || window.location.origin.endsWith('-production.vercel.app') || window.location.hostname === 'localhost')

const initAcessiBe = () => {
    if(!canInit || window.acsb?.initialized === true) {
        return
    }

    const installAndInitAcessiBe = () => {
        var s    = document.createElement('script')
        var h    = document.querySelector('head') || document.body
        s.src    = 'https://acsbapp.com/apps/app/dist/js/app.js'
        s.async  = true
        s.onload = function(){
            if (window.acsbJS) {
                console.log('Starting AcessiBe Service')
                window.acsbJS.init()
            }
        }
        h.appendChild(s)
    }

    installAndInitAcessiBe()
}

export default async (Store) => {
    Store.subscribe((mutation) => {
        if(mutation.type === 'general/setDeviceInfo') {
            initAcessiBe()
        }
    })
}
