<template>
  <div class="psui-w-full psui-h-full psui-flex psui-flex-col psui-overflow-y-auto psui-bg-gray-10">
    <div class="home-first psui-w-full psui-pt-10 md:psui-pt-20 psui-px-6 md:psui-px-10">
      <div class="home-first-section psui-mb-8 md:psui-mb-12">
        <h1 class="psui-text-h4 md:psui-text-h2 xl:psui-text-h1 psui-font-bold psui-text-blue-80">
          Welcome! The Cost-Effectiveness Explorer is a free resource to help California local governments and stakeholders develop energy policies for buildings. 
        </h1>
      </div>

      <HomeMainBenefitsListGroup />
    </div>

    <HomeAvailableResources />

    <div class="psui-flex psui-flex-col psui-items-center psui-psui-bg-gray-10 psui-px-6 md:psui-px-10 psui-pt-12">
      <p class="psui-text-h2 psui-text-blue-80 psui-font-bold psui-mb-2 psui-text-center">
        See what some of our users have to say
      </p>

      <template v-if="!policiesCreated && !jurisdictionsByPolicies">
        <vue-skeleton-loader
          class="psui-mb-8"
          :width="500"
          :height="18"
        />
      </template>
      <p
        v-else 
        class="psui-text-small md:psui-text-h6 psui-text-gray-60 psui-mb-8 psui-text-center"
      >
        To date, <span>{{ policiesCreated }}</span> virtual policies have been created for <span>{{ jurisdictionsByPolicies }}</span> California jurisdictions
      </p>

      <HomeTestimonialsList />

      <HomeNewsList
        v-if="getArticles" 
        :articles="getArticles"
        :is-loading="isLoadingArticles"
      />
    </div>

    <HomeCreateAccountHero v-if="!getterLoggedUser" />

    <HomeFooter />
  </div>  
</template>

<script>
import { quickGuideOptions } from '@/util/Drawers'
import HomeMainBenefitsListGroup from '@/modules/app/home/HomeMainBenefitsListGroup.vue'
import HomeAvailableResources from './HomeAvailableResources.vue'
import HomeTestimonialsList from '@/modules/app/home/HomeTestimonialsList.vue'
import HomeNewsList from '@/modules/app/home/HomeNewsList.vue'
import HomeCreateAccountHero from '@/modules/app/home/HomeCreateAccountHero.vue'
import HomeFooter from '@/modules/app/home/HomeFooter.vue'
import ArticlesApiService from '@/services/api/ArticlesApiService.js'
import { mapGetters } from "vuex"
import { HOME_AVAILABLE_RESOURCES } from "@/modules/app/home/HomeContents.js"
import PolicyApiService from '@/services/api/PolicyApiService'
import { isFeatureFlagEnabled } from '@/util/Functions.js'

export default {
  name: 'AppHome',
  components:{
    HomeMainBenefitsListGroup,
    HomeTestimonialsList,
    HomeNewsList,
    HomeCreateAccountHero,
    HomeFooter, 
    HomeAvailableResources
  },
  data: () => ({
    jurisdictionsByPolicies: null,
    policiesCreated: null,
    jurisdiction: null,
    articles: [],
    HOME_AVAILABLE_RESOURCES,
    isLoadingArticles: false,
  }),
  computed:{
    getArticles() {
      let articles = this.articles ?? []

      if (!this.getterLoggedUserIsAdmin) {
        articles = articles.filter((a) => a.state === 'published')
      }
      return articles
    },
    getLastJurisdictionVisited(){
      return this.$store.getters['lastJurisdictionVisited'] 
    },
    ...mapGetters(['getterLoggedUser', 'getterLoggedUserIsAdmin']),
  },
  async mounted() {
    this.isLoadingArticles = true
    ArticlesApiService.getArticles(4)
      .then((articles) => {
        this.articles = articles
        this.isLoadingArticles = false
      })
    this.getJurisdictionsAndPoliciesData()
  },
  methods: {
    onJurisdictionSelected(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromHome'})
    },
    openQuickGuide() {      
      this.$eventBus.$emit('openDrawerContent', quickGuideOptions )
    },
    async getJurisdictionsAndPoliciesData() {
      await PolicyApiService.getJurisdictionsAndPoliciesCreatedFromNotTeamMembers()
        .then((data) => {
          if (data.policies_count.length) this.policiesCreated = data.policies_count[0]?.count_total
          if (data.jurisdictions_count.length) this.jurisdictionsByPolicies = data.jurisdictions_count[0]?.count_total
        })
    },
  }
}
</script>

<style scoped lang="scss">
  .home-first {
    position: relative;
    padding-bottom: 65.5px;

    @media (min-width: 992px) {
      padding-bottom: 85.5px;
    }
    
    &:before {
      content: '';
      position: absolute;
      top: 0; left: 0;
      background-image: url('/images/home_page/home-hero-bg.svg');
      background-position: left bottom;
      background-repeat: repeat-x;
      width: 100%; height: 100%;
      z-index: 1;
    }

    * {
      position: relative;
      z-index: 5;
    }
  }
</style>
