<template>
  <div
    id="policy-option-index"
    class="w-full flex flex-col px-6 pb-6 overflow-y-auto"
  >
    <div
      v-if="getPolicyOptionsTableBodyColumns.length > 0 || !getStudyTypes.length"
      id="policy-option-index-wrapper"
      ref="overflowContainer"
      class="overflow-x-auto pb-1"
    >
      <div
        class="text-[14px] font-normal pb-5 sticky left-0 text-[#515E6A] psui-bg-gray-20"
        v-html="getPolicyOptionsTabledHeaderColumns[0].title"
      />
      <div class="policy-option-index-wrapper-table-wrapper overflow-visible">
        <table class="min-w-[1024px] w-full table-fixed">
          <PolicyOptionsContentTableHeader 
            :columns="getPolicyOptionsTabledHeaderColumns" 
            :is-overflow-active="isOverflowActive"
            :is-scrolling="isScrolling"
          />

          <PolicyOptionsContentTableBody 
            :columns="getPolicyOptionsTableBodyColumns" 
            :is-loading="isLoadingStudyData || !getStudyTypes.length"
            :is-overflow-active="isOverflowActive"
            :is-scrolling="isScrolling"
          />
        </table>
      </div>
    </div>
    <div 
      v-if="getPolicyOptionsTableBodyColumns.length > 0 || !getStudyTypes.length"
      class="flex items-center psui-text-small pt-2 sticky left-0"
    >
      <span class="psui-text-gray-60">
        You are seeing {{ filterPolicyOptionsByStudyType.length }}/{{ getTotalPolicyOptionsLength }} policy options.
      </span>
      <button
        v-if="getPolicyOptionTypeUserPreference.length == 1"
        class="psui-text-blue-60 font-bold ml-2"
        @click="removePrototypeFilter()"
      >
        Remove Filters to see all
      </button>
    </div>
    <div v-else>
      <PolicyOptionContentEmpty />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PolicyOptionsContentTableHeader from '@/modules/app/policy/options/options-table/PolicyOptionsContentTableHeader.vue'
import PolicyOptionsContentTableBody from './options-table/PolicyOptionsContentTableBody.vue'
import policyOptionColumns from '@/modules/app/policy/options/options-table/policyOptionsColumns.js'
import { sortByPolicyOptionRecommended } from "@/util/Functions.js"
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { getExistingBuildingsImpact, getNewBuildingsImpact } from '@/business-logic/services/policy-options/impacts'
import { parallel, try as tryit } from 'radash'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import PolicyOptionContentEmpty from '@/modules/app/policy/options/PolicyOptionContentEmpty.vue'


export default {
  name: "PolicyOptionsIndex",
  components: {
    PolicyOptionsContentTableHeader,
    PolicyOptionsContentTableBody,
    PolicyOptionContentEmpty
  },
  data:() => ({
    study_data: null,
    isLoadingStudyData: false,
    isOverflowActive: false,
    isScrolling: false,
    oldScrollLeft: 0,
    scrollTimeout: null,
    timer: null
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getPolicyOptionTypeUserPreference(){
      return this.$store.getters['getterUserPrefferenceByPath']('policies.policyOptions.filterByStudyType')
    },
    getPolicyOptionsTabledHeaderColumns() {
      return policyOptionColumns({
        title:"Here are some example policies designed by the Reach Codes Team for existing and new buildings. <br>Select one as a starting point. Regardless of your choice, you will be able to review/edit your policy requirements in the coming steps.",
        slug:'title'
      })
    },
    getPolicyOptionsTableBodyColumns() {
      if (this.getPolicyOptionTypeUserPreference.length === 0) {
        return []
      }
      return this.getStudyTypes
      .filter((studyType) => {
          return this.getPolicyOptionTypeUserPreference.includes(studyType.slug)
        })
        .map((studyType) => {
          return {
            ...studyType,
            data: this.getPolicyOptionsByStudyTypeSlug(studyType.slug).map((policyOption) => {
              return {
                ...policyOption,
                study_data: this.generateStudyData(policyOption),
                data: policyOptionColumns({
                  ...policyOption,
                }),
              }
            }),
          }
        })
    },
    getStudyTypes() {
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    getPolicyOptions() {
      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
    },    
    filterPolicyOptionsByStudyType() {
      let filteredOptions = []
      this.getPolicyOptionsTableBodyColumns.forEach(column => {
        filteredOptions = filteredOptions.concat(column.data)
      })
      return filteredOptions
    },
    getTotalPolicyOptionsLength(){
      return this.getPolicyOptionsByBuildingTypeSlug(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS).length + this.getPolicyOptionsByBuildingTypeSlug(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS).length
    },
    getAvailablePolicyOptionsItems(){
      return this.getPolicyOptionsTableBodyColumns.map( item => item.data).flat(Infinity)
    },
  },
  watch: {
    getPolicyOptions: {
      immediate: true,
      handler() {
        this.init()
      }
    },
    lastJurisdictionVisited: {
      immediate: true,
      handler() {
        this.init()
        this.startPolicyOptionGAEventWatcher()
      }
    },
    getPolicyOptionsTableBodyColumns: {
      immediate: true,
      handler() {
        this.checkOverflow()
        this.handleScroll()
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      this.checkOverflow()
    })
  },
  async mounted() {
    const element = document.getElementById('policy-option-index-wrapper')
    element.addEventListener('scroll', this.onScrollHandler)

    const intervalID = setInterval(() => {
      const policyOptionIndexScrollContainer = document.getElementById('policy-option-index')?.getElementsByTagName('div')?.[0]
      const hasLoadedElements = Boolean(policyOptionIndexScrollContainer?.getElementsByClassName('policy-option-row-item')?.length)
      if(policyOptionIndexScrollContainer && hasLoadedElements) {
        policyOptionIndexScrollContainer.addEventListener('scroll', this.startPolicyOptionGAEventWatcher)
        window.addEventListener('resize', this.startPolicyOptionGAEventWatcher)
        this.setPolicyOptionPageGAEvents()
        clearInterval(intervalID)
      }
    }, 100)

    await this.init()

    if(!this.lastJurisdictionVisited) this.$eventBus.$emit('openModalJurisdictionSelection', { skipRedirect: true })

    this.$nextTick(() => {
      this.checkOverflow()
      window.addEventListener('resize', this.checkOverflow)
      const interval = setInterval(() => {
        if (this.$refs?.overflowContainer) {
          this.$refs.overflowContainer.addEventListener('scroll', this.handleScroll)
          clearInterval(interval)
        }
      }, 500)
    })
  },
  beforeDestroy(){
    const element = document.getElementById('policy-option-index-wrapper')
    element?.removeEventListener('scroll', this.onScrollHandler)

    window.removeEventListener('resize', this.startPolicyOptionGAEventWatcher)
    document.getElementById('policy-option-index')?.removeEventListener('scroll', this.startPolicyOptionGAEventWatcher)

    window.removeEventListener('resize', this.checkOverflow)
    this.$refs?.overflowContainer?.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    onScrollHandler(ev){
      ev.target.toggleAttribute('scroll', true)
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        ev.target.toggleAttribute('scroll')
      }, 500)
    },
    checkOverflow() {
      this.isOverflowActive = this.$refs.overflowContainer?.clientWidth != null ?
          this.$refs.overflowContainer?.scrollWidth > this.$refs.overflowContainer?.clientWidth : false
    },
    handleScroll() {
      const overflowContainer = this.$refs.overflowContainer
      if (!overflowContainer) {
        return
      }
      const currentScrollLeft = overflowContainer.scrollLeft

      if (currentScrollLeft !== this.oldScrollLeft) {
        this.isScrolling = true
        clearTimeout(this.scrollTimeout)
        this.scrollTimeout = setTimeout(() => {
          if (overflowContainer.scrollLeft === 0) {
            this.isScrolling = false
          }
        }, 100)
      }

      this.oldScrollLeft = currentScrollLeft
    },
    startPolicyOptionGAEventWatcher() {
      let ticking = false
      if(!ticking) {
          window.requestAnimationFrame(() => {
            this.setPolicyOptionPageGAEvents()
            ticking = false
          })
          ticking = true
        }
    },
    setPolicyOptionPageGAEvents() {
      this.getAvailablePolicyOptionsItems.forEach(policyOption => {
        const key = `${policyOption.slug}-${this.lastJurisdictionVisited.slug}`
        
        const isInSessionStorage = sessionStorage.getItem(key)
        if(this.isPolicyOptionInviewPort(policyOption) && !isInSessionStorage) {
          sessionStorage.setItem(key, true)
          this.gaEvent(GA_LABELS.POLICY_OPTIONS_VIEW, { jurisdiction_slug: this.lastJurisdictionVisited.slug, policy_option_slug: policyOption.slug })
        }
      })
    },
    isPolicyOptionInviewPort(policyOption){
      const htmlElement = document.querySelector(`[policy_option_item="${policyOption.slug}"]`)
      const scrollElem = document.getElementById('policy-option-index')?.getElementsByTagName('div')?.[0]
      if(!htmlElement || !scrollElem) return false

      const rect = htmlElement.getBoundingClientRect()
      const { bottom: viewLimit } = scrollElem.getBoundingClientRect()

      return Boolean(rect.top >= 0 && rect.left >= 0 && rect.bottom <= (viewLimit || window.innerHeight || document.documentElement.clientHeight))
    },
    async init() {
      if (!this.lastJurisdictionVisited) return
      const policyOptions = (this.getPolicyOptions?.filter((pO) => pO.algorithms?.length && !pO.algorithms.every((a) => a.policy_option_slug.includes('default-state')) && pO?.prototypes?.length) || []).map((policyOption) => {
        return policyOption.algorithms.map((algorithm) => {
          return { algorithm, prototypes: policyOption.prototypes, policyOptionSlug: policyOption.slug }
        })
      }).flat(1)
      if (!policyOptions.length) {
        return
      }

      this.isLoadingStudyData = true

      const [, data] = await tryit(parallel)(3, policyOptions, async (policyOption) => {
        const computeFnc = (policyOption.algorithm.study_type_slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) ?  getExistingBuildingsImpact : getNewBuildingsImpact
        return {
          impacts: await computeFnc(policyOption.algorithm, policyOption.prototypes, this.lastJurisdictionVisited),
          algorithm: policyOption.algorithm,
          policyOptionSlug: policyOption.policyOptionSlug
        }
      })
      this.study_data = data
      this.isLoadingStudyData = false
    },
    getPolicyOptionsByStudyTypeSlug(studyTypeSlug) {
      return this.getPolicyOptionsByBuildingTypeSlug(studyTypeSlug).sort(sortByPolicyOptionRecommended)
    },
    getPolicyOptionsByBuildingTypeSlug(studyTypeSlug) {
      return (
        this.getPolicyOptions?.filter((policyOption) => {
          return policyOption?.algorithms?.some(
            (algorithm) => algorithm?.study_type_slug === studyTypeSlug
          )
        }) || []
      )
    },
    generateStudyData(policyOption) {
      return this.isLoadingStudyData ? {} : this.study_data?.find((i) => i?.policyOptionSlug && i?.policyOptionSlug === policyOption.slug)?.impacts || {}
    },
    removePrototypeFilter() {
      const studyTypesSlugs = this.getStudyTypes.map(st => st.slug)

      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromPolicyOptions',
        value: studyTypesSlugs
      })

    },
  }
}
</script>

<style>

  #policy-option-index-wrapper {
    overflow: overlay;
    transition: .5s background-color;
    margin: 0;
    background-color: rgb(230,236,242);

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: inherit;
      border-radius: 4px;
      background-clip: content-box;
      border: 1px solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      &:hover {
        transition: 0s;
        background-color: #00465F;
      }
    }
  }

  
  #policy-option-index-wrapper[scroll] {
    transition: 0s;
    background-color: transparent;
    &::-webkit-scrollbar-thumb {
      background-color: #00465F;
    }
  }


</style>