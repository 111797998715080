<template>
  <div
    v-if="isVisible"
    class="new-feature-badge hide-on-export"
    :class="{[layout]: true, 'psui-shadow-elevation-5': layout === 'new-badge', 'has-tooltip': !!tooltip}"
    @mouseleave="toggleTooltip($event, false)"
    @mouseover="toggleTooltip($event, true)"
  />
</template>

<script>

import dayjs from 'dayjs'

export default {
  name: 'AppNewFeatureBadge',
  props: {
    feature: {
      type: String,
      default: null,
      validator: function (value) {
        return ['bps', null].includes(value) 
      }
    },
    layout: {
      type: String,
      default: 'circle',
      validator: function (value) {
        return ['circle', 'new-badge'].includes(value)
      }
    },
    tooltip: {
      type: String,
      required: false,
    },
    tooltipPosition: {
      type: String,
      default: 'bottom',
      validator: function (value) {
        return ['bottom', 'right'].includes(value)
      }
    },
    tooltipSumLeft: {
      type: Number,
      default: 0,
    },
    tooltipSumTop: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isVisible() {
      if (!this.feature) return true

      const badStorageKey = `feature-badge-${this.feature}`
      let featureBadgeValue = window.localStorage.getItem(badStorageKey) || null
      if (featureBadgeValue) {
        try {
          featureBadgeValue = JSON.parse(featureBadgeValue)
        } catch(e) {
          featureBadgeValue = null
        }
      }
      let canShow = false
      let saveBadgeValue
      if (this.feature === 'bps') {
        const today = dayjs().startOf('day')
        const maxDate = dayjs('05/01/2025', 'MM/DD/YYYY').startOf('day')
        if (!featureBadgeValue?.day || (featureBadgeValue?.day && +featureBadgeValue?.day !== today.valueOf())) {
          if (!saveBadgeValue) {
            saveBadgeValue = {}
          }
          saveBadgeValue.day = today.valueOf()
          saveBadgeValue.count = featureBadgeValue?.count && !isNaN(featureBadgeValue?.count) ? +featureBadgeValue?.count + 1 : 1
        }
        const maxDiffDaysToShow = 10
        canShow = today.isBefore(maxDate) && (!featureBadgeValue?.count || isNaN(featureBadgeValue?.count) || featureBadgeValue?.count <= maxDiffDaysToShow)
      }

      if (saveBadgeValue) {
        window.localStorage.setItem(badStorageKey, JSON.stringify(saveBadgeValue))
      }

      return canShow
    }
  },
  methods: {
    toggleTooltip(event, show) {
      if (!this.tooltip) return

      if (!show) {
        this.$eventBus.$emit('closeConfirmPopover', { preventWhenHovering: true })
        return
      }

      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: event.target,
        text: this.tooltip,
        textAsHtml: true,
        side: this.tooltipPosition,
        buttons: [],
        disableFooter: true,
        sumTop: this.tooltipSumTop,
        sumLeft:  this.tooltipSumLeft,
        maxWidth: 180,
        layout: 'green-alert'
      })
    },
  }
}
</script>
<style lang="scss" scoped>
  .new-feature-badge {
    position: absolute;
    right: -5px;
    top: 10px;

    &.new-badge {
      background: #25A081;
      background: linear-gradient(90deg, #25A081 0%, #5DB883 100%);
      padding: 0 5px 2px 5px;
      width: 33px;
      height: 18px;
      border-radius: 3px;

      &:before {
        content: 'new';
        color: white;
        font-size: 12px;
        line-height: 130%;
        font-weight: bold;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.circle {
      position: absolute;
      top: 5px;
      right: -15px;
      width: 20px;
      height: 20px;
      background-color: rgba(93, 184, 131, 0.4);
      border-radius: 50%;
      animation: blinker 2s linear infinite;

      @keyframes blinker {
        50% {
          background-color: rgba(93, 184, 131, 0.1);
        }
      }

      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: #5DB883;
        border-radius: 50%;
        display: block;
        margin: 6px auto;
      }
    }

    &.has-tooltip {
      cursor: pointer;
    }
  }
</style>